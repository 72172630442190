import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import React, { useEffect, useState } from 'react';
import { getListing } from '../../containers/ListingPage/ListingPage.duck';
// import css from '../../containers/ListingPage/ListingPage.module.css'
import ListingCard from '../ListingCard/ListingCard';
import { setActiveListing } from '../../containers/SearchPage/SearchPage.duck';
import { SEARCH_PAGE } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './Recommendation.module.css';
import { handleWeavaiteDatabase } from '../../util/api';
import ContentLoader from 'react-content-loader';
import useGetScreenWidth from '../../hooks/useGetScreenWidth';

const { UUID } = sdkTypes;

const panelMediumWidth = 50;
const panelLargeWidth = 62.5;
const cardRenderSizes = [
  '(max-width: 767px) 100vw',
  `(max-width: 1023px) ${panelMediumWidth}vw`,
  `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
  `${panelLargeWidth / 3}vw`,
].join(', ');

export function cosinesim(A, B) {
  var dotproduct = 0;
  var mA = 0;
  var mB = 0;

  for (var i = 0; i < A?.length; i++) {
    dotproduct += A[i] * B[i];
    mA += A[i] * A[i];
    mB += B[i] * B[i];
  }

  mA = Math.sqrt(mA);
  mB = Math.sqrt(mB);
  var similarity = dotproduct / (mA * mB);
  return similarity;
}

const Recommendation = (props) => {
  const { currentListing } = props;
  const currentListingId = currentListing?.id && currentListing?.id.uuid;
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const {connectedUsers = {}} = state.user;
  const [recommendedListings, setRecommendedListings] = useState([]);
  const [loading, setLoading] = useState(false);
  const screenWidth = useGetScreenWidth()

  async function getRecommendedItems() {
    setLoading(true)
    try {
      const { response, error } = await handleWeavaiteDatabase({
        actionType: 'similarObject',
        expertId: currentListingId
      });
      const ids = response?.length ? response : [];

      if (ids?.length) {
        const currentPageResultIds = ids?.map(id => new UUID(id))

        dispatch(getListing(ids)).then(() => {
          const listings = getListingsById(state, currentPageResultIds)
          setRecommendedListings(listings)
          setLoading(false)
        })
      }

    } catch (error) {
      setLoading(false)
      return;
    }
  }

  useEffect(() => {
    if (currentListing?.id) getRecommendedItems()
  }, [currentListing?.id])

  return (
    <div className={css.similarProfiles}>
      <h2 className={css.languageHeading}>
        <FormattedMessage id="Recommendation.title" />
      </h2>
      {loading ? (
        <div className={css.loaderWrapper}>
          <ContentLoader
              speed={2}
              width={400}
              height={100}
              viewBox="0 0 400 70"
              backgroundColor="#ddd"
              foregroundColor="#ecebeb"
              {...props}
          >
              <rect x="90" y="8" rx="3" ry="3" width="300" height="10" />
              <rect x="90" y="26" rx="3" ry="3" width="300" height="10" />
              <circle cx="30" cy="30" r="30" />
          </ContentLoader>
        </div>
      ) : recommendedListings?.length !== 0 ? (
        <div className={css.similarProfileList}>
          {recommendedListings?.map(l => {
            const title = l?.attributes.title;
            const authorTitle = l?.author.attributes.profile.displayName;
            const abbreviatedName = l?.author.attributes.profile.abbreviatedName;
            const profileImage = l.author?.profileImage?.attributes.variants['square-small2x'];
            const isUserOnline = connectedUsers && Object.keys(connectedUsers)?.includes(l.author.id.uuid);

            return(
              <div className={css.similarList}  key={l.id.uuid}>
                <div className={css.similarIcon}>
                  {profileImage?.url ? (
                    <img src={profileImage?.url} />
                  ) : (
                    <span className={css.profileIcon}>{abbreviatedName}</span>
                  )}
                  {/* <span className={css.status} style={{background: isUserOnline ? '#2ECC71' : 'red'}}></span> */}
                </div>
                <div className={css.similarRight}>
                  <div className={css.headingName}>{authorTitle}</div>
                  <div className={css.customerExp}>{title}</div>
                </div>
                <ListingCard
                  className={css.listingCard}
                  infoClassName={css.cardInfo}
                  key={l.id.uuid}
                  listing={l}
                  renderSizes={cardRenderSizes}
                  setActiveListing={setActiveListing}
                  currentPage={SEARCH_PAGE}
                  screenWidth={screenWidth}
                />
              </div>
            )
          })}
        </div>
      ) : (
        <p className={css.warning}><FormattedMessage id="Recommendation.warning" /></p>
      )}
    </div>
  )
}

export default injectIntl(Recommendation);