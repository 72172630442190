import React, { useEffect } from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
// These relative imports need to point to correct directories
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { Form, FieldTextInput, PrimaryButton } from '../../components';

// as a template.
import css from './OpenAIPerfectQuestionAppForm.module.css';

export const OpenAIPerfectQuestionAppForm = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          pristine,
          updated,
          formId,
          updateError,
          values,
          form,
          openaiRequest,
        } = formRenderProps;
        const submitDisabled = !values.questionInput || !values.questionObjective || !values.targetAudience;
        const handleCopyClick = val => {
          navigator.clipboard.writeText(val);
          alert('Copied to clipboard!');
        };

        const textAreas = [
          {
            id: 1,
            name: 'questionOne',
            placeholder: 'Enter text for Text Area 1',
            content: 'Text Area 1 content',
          },
          {
            id: 2,
            name: 'questionTwo',
            placeholder: 'Enter text for Text Area 2',
            content: 'Text Area 2 content',
          },
          {
            id: 3,
            name: 'questionThree',
            placeholder: 'Enter text for Text Area 3',
            content: 'Text Area 3 content',
          },
        ];

        // Input label
        const yourInputLabel = intl.formatMessage({
          id: 'OpenAIPerfectQuestionAppForm.yourInputFieldLabel',
        });
        const yourInputPlaceholder = intl.formatMessage({
          id: 'OpenAIPerfectQuestionAppForm.yourInputFieldPlaceholder',
        });

        // objectiveOfQuestionInputLabel
        const objectiveOfQuestionInputLabel = intl.formatMessage({
          id: 'OpenAIPerfectQuestionAppForm.objectiveOfQuestionInputLabel',
        });
        const objectiveOfQuestionInputPlaceholder = intl.formatMessage({
          id: 'OpenAIPerfectQuestionAppForm.objectiveOfQuestionInputPlaceholder',
        });

        // targetAudienceInputLabel
        const targetAudienceInputLabel = intl.formatMessage({
          id: 'OpenAIPerfectQuestionAppForm.targetAudienceInputLabel',
        });
        const targetAudienceInputPlaceholder = intl.formatMessage({
          id: 'OpenAIPerfectQuestionAppForm.targetAudienceInputPlaceholder',
        });

        // Output
        const outputHeading = intl.formatMessage({
          id: 'OpenAIPerfectQuestionAppForm.outputHeading',
        });
        useEffect(() => {
          // Use form.change to set questionContent from API response to the corresponding name
          if (values?.questions && Array.isArray(values.questions)) {
            values.questions.forEach((question, index) => {
              const textArea = textAreas[index];
              if (textArea) {
                form.change(textArea.name, question.questionContent);
              }
            });
          }
        }, [values?.questions ]); 
      
        return (
          <Form onSubmit={handleSubmit}>
            <div className={css.briefGeneratorForm}>
              <div className={css.container}>
                <div className={css.briefGenerator}>
                  <span className={css.generatorTitle}>
                    <FormattedMessage id="OpenAIPerfectQuestionAppForm.heading" />
                  </span>
                </div>
                <div className={css.textareaBox}>
                  <label>{yourInputLabel}</label>
                  <FieldTextInput
                    className={css.inputBox}
                    type="textarea"
                    id={formId ? `${formId}.questionInput` : 'questionInput'}
                    name="questionInput"
                    autoComplete="given-name"
                    placeholder={yourInputPlaceholder}
                  // validate={firmRequired}
                  />
                </div>
                <div className={css.textareaBox}>
                  <label>{objectiveOfQuestionInputLabel}</label>
                  <FieldTextInput
                    className={css.inputBox}
                    type="textarea"
                    id={formId ? `${formId}.questionObjective` : 'questionObjective'}
                    name="questionObjective"
                    placeholder={objectiveOfQuestionInputPlaceholder}
                  />
                </div>
                <div className={css.textareaBox}>
                  <label>{targetAudienceInputLabel}</label>
                  <FieldTextInput
                    className={css.inputBox}
                    type="textarea"
                    id={formId ? `${formId}.targetAudience` : 'targetAudience'}
                    name="targetAudience"
                    placeholder={targetAudienceInputPlaceholder}
                  />
                </div>
                <div className={css.actionButtons}>
                  <PrimaryButton
                    type="submit"
                    className={css.submitButton}
                    disabled={submitDisabled}
                    inProgress={openaiRequest}
                  >
                    <FormattedMessage id="OpenAIContractForm.submitButtonText" />
                  </PrimaryButton>
                </div>
                <div>
                  <h3>{outputHeading}</h3>
                  <div className={css.textareaBox}>
                    {textAreas.map((textArea, index) => (
                      <div key={textArea.id} className={css.outputField}>
                        <FieldTextInput
                          id={formId ? `${formId}.${textArea.name}` : textArea.name}
                          type="textarea"
                          name={textArea.name}
                          value={values[textArea.name] || ''}
                        />
                        <PrimaryButton
                          type="button"
                          className={css.copyButton}
                          onClick={() => handleCopyClick(values[textArea.name])}
                          disabled={!values.questions?.length}
                        >
                          <FormattedMessage id="OpenAIPerfectQuestionAppForm.copyButtonText" />
                        </PrimaryButton>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

OpenAIPerfectQuestionAppForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  //   updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(OpenAIPerfectQuestionAppForm);
