import moment from 'moment';
import {
  APPLIED_PRIVATE_JOBS,
  APPLIED_PUBLIC_JOBS,
  AVERAGE_RATING,
  COMMISSIONED_PROJECTS,
  COMPLETED_PROJECTS,
  COMPLETED_RECRUITMENTS,
  CSM_INVITEE,
  CUSTOMER_INVITEE,
  DELETED_USER,
  FREE_PLAN,
  FREE_PLANS,
  LISTING_TYPE_PRIVATE,
  LISTING_TYPE_PUBLIC,
  LOGIN,
  LOGINS,
  MONTH,
  OFFLINE,
  ONGOING_RECRUITMENTS,
  ONLINE,
  PRIVATELY_SHARED_JOB,
  PRIVATELY_SHARED_JOB_APPLICATIONS,
  PRIVATELY_SHARED_PROJECT,
  PRIVATE_BRIEF_COUNT,
  PRIVATE_JOBS,
  PRIVATE_PROJECTS,
  PRIVATE_PROPOSAL,
  PROFESSIONAL_INDVIDUAL_PLAN,
  PROFESSIONAL_MONTHLY,
  PROFESSIONAL_YEARLY,
  PROJECT_COMMISSIONED,
  PROJECT_COMPLETED,
  PROJECT_UNDERWAY,
  PROVIDER_INVITEE,
  PUBLIC_BRIEF_COUNT,
  PUBLIC_JOBS,
  PUBLIC_POSTED_JOB,
  PUBLIC_POSTED_JOB_APPLICATIONS,
  PUBLIC_POSTED_PROJECT,
  PUBLIC_PROJECTS,
  PUBLIC_PROPOSAL,
  RECRUITMENTS_DONE,
  RECRUITMENTS_UNDERWAY,
  SMALL_TEAMS_MONTHLY,
  SMALL_TEAMS_YEARLY,
  SMALL_TEAM_PLAN,
  THIRD_PARTY_EXPERT,
  TRANSACTION_BRIEF_PROCESS,
  TRANSACTION_FINAL_PAYMENT_PROCESS,
  TRANSACTION_PROPOSAL_PROCESS,
  UNDERWAY_PROJECTS,
  USD,
  USER_ROLE_CLIENT,
  USER_ROLE_CSM,
  USER_ROLE_PARTNER,
} from './types';
import {
  TRANSITION_CONFIRM_PAYMENT_AFTER_PROPOSAL_ACCEPTED,
  TRANSITION_REQUEST_PAYMENT_AFTER_PROPOSAL_ACCEPTED,
  TRANSITION_REQUEST_PREPAYMENT_USING_STRIPE,
} from './transaction';
import { LAST_THREE_MONTHS, LAST_YEAR } from '../components/FirmDashboardPanel/UserStatsModals';
import { getUserRole } from './userRole';

const currentDate = new Date();
const currentMonth = currentDate.getMonth() + 1; // Adding 1 to get the month in 1-12 format
const currentYear = currentDate.getFullYear();
const lastQuarter = Math.floor((currentMonth + 2) / 3); // Adjusting quarter calculation
const lastYear = currentYear - 1;

// const currentDateTime = new Date().getTime();
const last30dayTime = moment().subtract('30', 'days').valueOf();
const last90dayTime = moment().subtract('90', 'days').valueOf();
const last180dayTime = moment().subtract('180', 'days').valueOf();
const last60dayTime = moment().subtract('60', 'days').valueOf();
const lastYearTime = moment().subtract('1', 'year').startOf('month').valueOf();
const lastDay = new Date(currentYear, currentMonth, 0).getDate();

const months = [...Array(12).keys()].map((curr, index) => {
  const month = index===0 ? 'This month' : moment().subtract(index, 'month').format('MMM')
  const year = moment().subtract(index, 'month').format('YYYY')
  const monthIndex = moment().subtract(index, 'month').format('M') - 1
  return { month, year, monthIndex }
}).reverse();

const last30Days = Array.from({ length: lastDay }, (_, i) => i).map(it => {
  const day = (it===0) ? 'Today' : moment().subtract(it, 'days').format('D');
  const month = moment().subtract(it, 'days').format('MMM');
  const startTime = moment().subtract(it, 'days').startOf('date').valueOf();
  const endTime = moment().subtract(it, 'days').endOf('date').valueOf();
  return { day, startTime, endTime, month }
}).reverse();

function calculateAverageRating(ratings) {
  let total = 0;
  for (let i = 0; i < ratings.length; i++) {
    total += ratings[i];
  }

  return total / ratings.filter(rating => rating !== 0).length;
}

function calculateGrowthPercentage(previousCount, currentCount) {
  if (previousCount === 0) {
    return 0
  } else {
    return ((currentCount - previousCount) / previousCount).toFixed(2) * 100;
  }
}

function getUnixTimestamp(year, month) {
  const day = 1;
  let hour = 0, minute = 0, second = 0;

  const startDate = new Date(year, month, day, hour, minute, second);
  const lastDay = new Date(year, month, 0).getDate();
  const endDate = new Date(year, month, lastDay, 23, 59, 59);

  const startTimestamp = Math.floor(startDate.getTime());
  const endTimestamp = Math.floor(endDate.getTime());

  return { startTimestamp, endTimestamp };
}

const lastYearStartTimestamp = lastYearTime;
const lastYearEndTimestamp = moment().subtract(1, 'month').endOf('month').valueOf();
const prevYearStartTimestamp = moment().subtract(24, 'month').startOf('month').valueOf();
const prevYearEndTimestamp = moment().subtract(13, 'month').endOf('month').valueOf();
const { startTimestamp: lastThreeMonthStart } = getUnixTimestamp(currentYear, currentMonth - 4);
const { endTimestamp: lastThreeMonthEnd } = getUnixTimestamp(currentYear, currentMonth - 2);
const { startTimestamp: prevThreeMonthStart } = getUnixTimestamp(currentYear, currentMonth - 7);
const { endTimestamp: prevThreeMonthEnd } = getUnixTimestamp(currentYear, currentMonth - 5);
const currentMonthStart = moment().subtract(30, 'days').format('x').valueOf();
const currentMonthEnd = moment().subtract(1, 'days').format('x').valueOf();
const prevMonthStart = moment().subtract(60, 'days').format('x').valueOf();
const prevMonthEnd = moment().subtract(31, 'days').format('x').valueOf();

export const getAuthorDisplayName = author => {
  const displayName =
    author &&
    author.attributes &&
    author.attributes.profile &&
    author.attributes.profile.displayName;
  return displayName || '';
};

export const getTransitionTitle = (tx, stateData) => {
  return Array.isArray(stateData) && stateData.find(item => item?.txId === tx);
};
export const getUserDetails = user => {
  const fullName = user?.attributes?.profile?.firstName
    ? user?.attributes?.profile?.firstName + ' ' + user?.attributes?.profile?.lastName
    : user?.attributes?.profile?.publicData?.fullName;
  let profileImage = null;
  if (!!user?.id && user?.profileImage?.attributes?.variants) {
    if (user?.profileImage?.attributes?.variants?.default) {
      profileImage = user?.profileImage?.attributes?.variants?.default?.url;
    } else {
      profileImage = user?.profileImage?.attributes?.variants['square-small2x']?.url;
    }
  } else {
    profileImage = user?.attributes?.profile?.publicData?.picture;
  }
  const email =
    !!user?.id && user?.attributes?.email
      ? user?.attributes?.email
      : user?.attributes?.profile?.publicData?.email;
  const id = user?.id && user?.id?.uuid;
  const firmId = user?.attributes.profile.publicData?.firmId
    || user?.attributes.profile.publicData?.linkedToFirms?.[0]?.firmId;

  const firmName = user?.attributes?.profile?.publicData?.firmListing?.firmTitle
    ?? user?.attributes?.profile?.publicData?.linkedToFirms?.[0]?.firmName;
  const firmListing = user?.attributes?.profile?.publicData?.firmListing;
  const linkedToFirms = user?.attributes?.profile?.publicData?.linkedToFirms || [];
  const firmAuthorRole = user?.attributes?.profile?.publicData?.firmAuthorRole;
  const firstName = user?.attributes?.profile?.firstName || fullName?.split(" ")[0];
  const briefCountDetails = user?.id && user?.attributes?.profile?.publicData?.briefCountDetails;

  return {
    fullName: fullName ?? DELETED_USER,
    profileImage,
    email,
    id,
    firmName,
    firmId,
    firmListing,
    firmAuthorRole,
    linkedToFirms,
    firstName,
    briefCountDetails
  };
};

export const filteredTransactions = tx => {
  // const chainedTransactionWithBriefProcess = tx?.filter(
  //   item =>
  //     item?.attributes?.protectedData?.chainedTransactionId &&
  //     item?.attributes?.processName === TRANSACTION_BRIEF_PROCESS
  // );

  // const chainedTransactionWithoutBriefProcess = tx?.filter(
  //   item =>
  //     !item?.attributes?.protectedData?.chainedTransactionId &&
  //     item?.attributes?.processName === TRANSACTION_BRIEF_PROCESS
  // );

  // const chainedTransactionWithoutPropsalProcess = tx?.filter(
  //   item =>
  //     !item?.attributes?.protectedData?.chainedTransactionId &&
  //     item?.attributes?.processName === TRANSACTION_PROPOSAL_PROCESS
  // );
  const briefTransactionWithChainTransaction = tx?.filter(item => {
    if (item?.attributes?.processName !== TRANSACTION_FINAL_PAYMENT_PROCESS) {
      return !item?.attributes?.protectedData?.chainedTransaction && item
    }
  })

  return briefTransactionWithChainTransaction.sort(
    (a, b) =>
      moment(b.attributes.lastTransitionedAt).unix() -
      moment(a.attributes?.lastTransitionedAt).unix()
  );
};

export const getListingType = listing =>
  !!listing?.length && listing?.map(l => l?.attributes?.publicData?.type);

export const freePlan = user =>
  !!user?.id && !!user?.attributes?.profile?.publicData?.freePlanData?.isFreePlanUsed;


export const professionIndividualPlan = user => {
  const profile = user?.attributes?.profile;
  const subscriptionPlan = profile?.protectedData?.subscriptionPlan || [];
  const offlinePlanName = profile?.protectedData?.offlineSubscriptionObject?.planName;

  const validPlans = [PROFESSIONAL_MONTHLY, PROFESSIONAL_YEARLY];

  return (
    !!user?.id &&
    (subscriptionPlan?.length > 0 && subscriptionPlan.some(p => validPlans.includes(p?.plan?.nickname)) ||
      validPlans.includes(offlinePlanName))
  );
};

export const smallTeamPlan = user => {
  const profile = user?.attributes?.profile;
  const subscriptionPlan = profile?.protectedData?.subscriptionPlan || [];
  const offlinePlanName = profile?.protectedData?.offlineSubscriptionObject?.planName;
  const isOfflineSubscriptionPaid = profile?.protectedData?.offlineSubscriptionObject?.isOfflineSubscriptionPaid;
  const validPlans = [SMALL_TEAMS_MONTHLY, SMALL_TEAMS_YEARLY];

  return (
    !!user?.id &&
    (subscriptionPlan?.length > 0 && subscriptionPlan.some(p => validPlans.includes(p?.plan?.nickname)) ||
      (validPlans.includes(offlinePlanName) && isOfflineSubscriptionPaid))
  );
};

export const userSubscriptions = user =>
  (!!user?.id && (user?.attributes?.profile?.protectedData?.subscriptionPlan ?? null));

export const sameMonthListings = listings => {
  const currentMonth = moment().month() + 1;
  const sameMonthListings =
    listings?.length &&
    listings?.filter(
      l =>
        l?.attributes?.publicData?.type === LISTING_TYPE_PRIVATE ||
        (l?.attributes?.publicData?.type === LISTING_TYPE_PUBLIC &&
          moment(l?.attributes?.createdAt).month() + 1 === currentMonth)
    );
  return sameMonthListings;
};

export const sameMonthTransactions = tx => {
  const currentMonth = moment().month();
  const sameMonthTransaction =
    !!tx?.length && tx?.filter(t => moment(t?.transactionCreatedAt).month() === currentMonth);
  return sameMonthTransaction;
};

export const userPlanName = user =>
  !!user?.id &&
  user?.attributes?.profile?.protectedData?.subscriptionPlan?.map(p => p?.plan?.nickname);

export const briefCounts = listingType =>
  !!listingType?.length &&
  listingType.reduce((acc, curr) => ((acc[curr] = acc[curr] + 1 || 0), acc), {});

export const getFirmId = user => !!user?.id && (user?.attributes?.profile?.publicData?.firmId || user?.attributes?.profile?.protectedData?.firmListing?.firmId);

export const getFirmData = listing => !!listing?.id && listing?.attributes?.metadata?.firmData || [];

export const isUserLinkedToFirm = user =>
  !!user?.id && !!user?.attributes?.profile?.publicData?.linkedToFirms?.length;

export const getStripeCustomerId = user =>
  !!user?.id && user?.attributes?.profile?.protectedData?.stripeCustomerId;

export const hasOwnFirm = user => !!user?.id && user?.attributes?.profile?.publicData?.firmId;

export const isEmailVerified = user =>
  !!user?.id &&
  (!!user?.attributes?.emailVerified || user?.attributes?.profile?.publicData?.emailVerified);

export const verifiedAuthorEmailListings = listings =>
  !!listings?.length &&
  listings.filter(l => l?.author?.attributes?.profile?.publicData?.emailVerified);

export const fetchBriefCountDetails = user => {
  return !!user?.id && user?.attributes?.profile?.publicData?.briefCountAsPerSubscriptionPlan;
};

export const briefCountForSubscriptionPlan = (user, data, isPrivateBrief = false) => {
  const getPlanData = fetchBriefCountDetails(user) || {};

  const currying = (planData, type, plan) => {
    return planData[plan][type];
  };
  const isProfessionalPlanUsed = !!user?.id && !!professionIndividualPlan(user);
  const isSmallTeamPlanUsed = !!user?.id && !!smallTeamPlan(user);
  const isFreePlanUsed = !!user?.id && !!freePlan(user);
  Object.assign(getPlanData, { ...data });

  if (data?.freePlan) {
    let month = currying(getPlanData, MONTH, FREE_PLANS);
    let count = isPrivateBrief
      ? currying(getPlanData, PRIVATE_BRIEF_COUNT, FREE_PLANS)
      : currying(getPlanData, PUBLIC_BRIEF_COUNT, FREE_PLANS);
    const dataMonth = currying(data, MONTH, FREE_PLANS);
    const filteredPlan = (!!getPlanData && month === dataMonth) || month !== dataMonth;
    if (filteredPlan) {
      count < 1 ? (count += 1) : count;
      month = dataMonth;
    }

    Object.assign(
      getPlanData[FREE_PLANS],
      isPrivateBrief ? { privateBriefCount: count, month } : { publicBriefCount: count, month }
    );
    return getPlanData;
  }

  if (data?.professionIndividualPlan) {
    let month = currying(getPlanData, MONTH, PROFESSIONAL_INDVIDUAL_PLAN);
    let count = isPrivateBrief
      ? currying(getPlanData, PRIVATE_BRIEF_COUNT, PROFESSIONAL_INDVIDUAL_PLAN)
      : currying(getPlanData, PUBLIC_BRIEF_COUNT, PROFESSIONAL_INDVIDUAL_PLAN);
    const dataMonth = currying(data, MONTH, PROFESSIONAL_INDVIDUAL_PLAN);
    const filteredPlan = (!!getPlanData && month === dataMonth) || month !== dataMonth;
    if (filteredPlan) {
      count < 3 ? (count += 1) : count;
      month = dataMonth;
    }

    Object.assign(
      getPlanData[PROFESSIONAL_INDVIDUAL_PLAN],
      isPrivateBrief ? { privateBriefCount: count, month } : { publicBriefCount: count, month }
    );
    return getPlanData;
  }

  if (data?.smallTeamPlan) {
    let month = currying(getPlanData, MONTH, SMALL_TEAM_PLAN);
    let count = currying(getPlanData, PRIVATE_BRIEF_COUNT, SMALL_TEAM_PLAN);
    const dataMonth = currying(data, MONTH, SMALL_TEAM_PLAN);

    const filteredPlan = (!!getPlanData && month === dataMonth) || month !== dataMonth;
    if (filteredPlan) {
      count < 3 ? (count += 1) : count;
      month = dataMonth;
    }

    Object.assign(getPlanData[SMALL_TEAM_PLAN], { privateBriefCount: count, month });
    return getPlanData;
  }
};

export const getPaymentTerms = tx => tx?.id && tx?.attributes?.metadata?.proposal?.paymentTerms;

export const checkIfTransactionIncludesTransition = tx => {
  // Define constants for the transitions
  const TRANSITIONS = [
    TRANSITION_REQUEST_PREPAYMENT_USING_STRIPE,
    TRANSITION_REQUEST_PAYMENT_AFTER_PROPOSAL_ACCEPTED,
    TRANSITION_CONFIRM_PAYMENT_AFTER_PROPOSAL_ACCEPTED,
  ];
  const transitions = tx?.attributes?.transitions ?? [];
  return transitions.find(t => TRANSITIONS.includes(t?.transition)) ?? null;
};

export const payinTotal = tx => {
  const amount = tx?.attributes?.payinTotal?.amount;
  const currency = tx?.attributes?.payinTotal?.currency;

  if (amount != null && currency != null) {
    return `${(amount / 100)} ${currency}`;
  }

  return null;
};
export const finalPayment = tx => {
  const totalAmount = Number((tx?.attributes?.metadata?.proposal?.paymentFee?.amount) / 100).toFixed(2) ?? null;
  const currency = tx?.attributes?.metadata?.proposal?.paymentFee?.currency ?? null;
  const payoutTotal = Number((tx?.attributes?.payoutTotal?.amount) / 100).toFixed(2) ?? null;
  return `${(totalAmount - payoutTotal)} ${currency}`;
};

export const getPaymentMode = tx => {
  const { metadata } = tx?.attributes || {};
  const isPaymentOffline = metadata?.isPaymentOffline;
  const isPaymentOnline = metadata?.isPaymentOnline;
  return isPaymentOffline ? OFFLINE : isPaymentOnline ? ONLINE : null;
};

export const getPreferredInsightGigPartner = user => user?.attributes?.profile?.protectedData?.preferredInsightGigPartner ?? [];

export const checkExpertAvailability = (listing) => listing?.id && listing?.attributes?.publicData?.unAvailability?.isUnavailable;

export const checkExpertUnAvailabilityFrom = (listings) =>
  listings?.filter(l => moment(l?.attributes?.publicData?.unAvailability?.from)?.unix() >= moment()?.unix())
  ?? listings;

export const checkExpertUnAvailabilityDates = (listing) =>
  `${listing?.attributes?.publicData?.unAvailability?.from ?? ''} - ${listing?.attributes?.publicData?.unAvailability?.to ?? ''}`;


export const firstLetterCapital = text => text?.replace(/(^\w|\.\s\w)/g, char => char.toUpperCase());

export const isTransactionRead = tx => tx?.attributes?.metadata?.isTransactionRead ?? false;

export const getCountry = query => {
  const country =
    query &&
    query
      .replace(/\s/g, '')
      .split(',')
      .slice(-1)[0]
      .toLowerCase();
  return country;
};
export const addHttpsToLink = (firmWebsite) => {
  if (!firmWebsite?.startsWith("http://") && !firmWebsite?.startsWith("https://")) {
    firmWebsite = "https://" + firmWebsite;
  }
  return firmWebsite ?? null;
}
export const getFirstChar = (str) => {
  return str.split(" ").map(word => word.charAt(0)).join("").toUpperCase();
}

export const checkIsBrief = listing => listing?.id && listing?.attributes?.publicData?.listingType === USER_ROLE_CLIENT;

export const getInStripeAccountId = user => user?.attributes?.profile?.privateData?.['sca-in-id'] ?? null;

export const getInStripeAccountData = user => user?.attributes?.profile?.protectedData?.stripeConnectIndiaDataObj ?? null;

export const getTxnProcessName = tx => tx?.attributes?.processName;

export const getPaymentIntent = user => user?.attributes?.profile?.privateData?.paymentIntent ?? null;

export const getUserDisplayName = name => {
  if (!name) return DELETED_USER
  const firstName = name?.split(' ')[0];
  const lastName = name?.split(' ')[1];
  const firstChar = lastName.charAt(0);
  return `${firstName} ${firstChar.toUpperCase()}`
}


export const openAIImageDetails = listing => {
  const imageURL = (listing?.id && (listing?.images[0]?.attributes?.variants?.['landscape-crop2x']?.url ?? listing?.images[0]?.attributes?.variants?.default?.url));
  const imageText = listing?.id && listing?.attributes?.publicData?.openAIBackgroundImageDetails?.imageText;
  return {
    imageText,
    imageURL
  }
};

export const hasGoogleAccessToken = user => !!user?.attributes?.profile?.protectedData?.googleMeetTokens?.access_token;

export function getTotalCount(userDetails, category, appRoute) {
  return userDetails.reduce((total, user) => {
    const categoryItem = user[category] || [];
    return total + categoryItem.filter(itm => !!appRoute ? itm.name === appRoute : itm).length;
  }, 0);
}

export function getCustomDaysCount(userDetails, category, days, condition) {
  const previousTime = days === 30 ? Number(last30dayTime) : days === 90 ? Number(last90dayTime) : days === 60 ? Number(last60dayTime) : Number(lastYearTime);
  const currentDateTime = Date.now();

  return userDetails.reduce((total, user) => {
    const categoryItem = user[category];
    return total + categoryItem?.filter(itm => itm.date < currentDateTime && itm.date > previousTime && ((condition === "recruitmentUnderway") ? (itm.isCompleted === false) : true) && ((condition === "recruitmentDone") ? (itm.isCompleted === true) : true)).length;
  }, 0);
}

export function lastQuarterCount(userDetails, category, status, days){
  const previousTime = Number(last180dayTime)
  const currentDateTime = Number(last90dayTime)
  const isStatus = status===true || status===false
  if(days===30){
    const previous60days = Number(last60dayTime)
    const previous30days = Number(last30dayTime)
    return userDetails.reduce((total, user) => {
      const categoryItem = user[category];
      return total + categoryItem?.filter(itm => itm.date < previous30days && itm.date > previous60days && (isStatus ? itm.isCompleted===status : true)).length;
    }, 0);
  }
  return userDetails.reduce((total, user) => {
    const categoryItem = user[category];
    return total + categoryItem?.filter(itm => itm.date < currentDateTime && itm.date > previousTime && (isStatus ? itm.isCompleted===status : true)).length;
  }, 0);
}

export function getCustomDaysCountCompleteStatus(userDetails, category, days, status) {
  const previousTime = days === 30 ? Number(last30dayTime) : days === 90 ? Number(last90dayTime) : Number(lastYearTime);
  const currentDateTime = Date.now();

  return userDetails.reduce((total, user) => {
    const categoryItem = user[category];
    return total + categoryItem.filter(itm => itm.date < currentDateTime && itm.date > previousTime && itm.isCompleted===status).length;
  }, 0);
}

export const firmDashboardUserLoginDetails = (newFirmUserDetails) => {
  // Login details

  const last30DaysLoginCount = getCustomDaysCount(newFirmUserDetails, LOGIN, 30);
  const previousQuarterLoginCount = lastQuarterCount(newFirmUserDetails,LOGIN, 30);
  const totalUsers = newFirmUserDetails.length;
  const percentageLast30Days = ((last30DaysLoginCount-previousQuarterLoginCount) / previousQuarterLoginCount) * 100 || 0;

  const currentQuarterNewUsers = newFirmUserDetails.filter((item) => {
    return item.joiningDate > Number(last90dayTime)
  }).length;

  const previousQuarterNewUsers = newFirmUserDetails.filter((item) => {
    return item.joiningDate > Number(last180dayTime) && item.joiningDate < Number(last90dayTime)
  }).length;

  const percentageNewUserLastQuarter = ((currentQuarterNewUsers - previousQuarterNewUsers) / previousQuarterNewUsers) * 100;


  return {
    percentageLast30Days,
    percentageNewUserLastQuarter,
    totalUsers,
    last30DaysLoginCount,
  };
};

export const calculateFirmDashboardUserRating = (newFirmUserDetails) => {
  const totalReviews = newFirmUserDetails.reduce((reviews, user) => {
    return (reviews + user?.averageRating?.length)
  }, 0);
  
  const last90DaysRatingCount = getCustomDaysCount(newFirmUserDetails, AVERAGE_RATING, 90);
  const lastQuarterRatingCount = lastQuarterCount(newFirmUserDetails, AVERAGE_RATING);
  const lastQuarterPercentage = ((last90DaysRatingCount-lastQuarterRatingCount)/lastQuarterRatingCount)*100

  const ratings = newFirmUserDetails.map((user) => {
    const totalRating = user?.averageRating?.reduce((acc, curr) => acc + curr?.rating, 0);
    const totalReviews = user?.averageRating?.length;
    const averageRating = totalReviews === 0 ? 0 : totalRating / totalReviews;

    return averageRating
  });
  const averageRating = totalReviews === 0 ? 0 : calculateAverageRating(ratings);

  return {
    lastQuarterPercentage,
    averageRating
  };
}

export const calculatePublicPostedProjects = (newFirmUserDetails) => {
  const publiclyPostedProjects = getTotalCount(newFirmUserDetails, PUBLIC_POSTED_PROJECT);
  const last90DaysProjectsCount = getCustomDaysCount(newFirmUserDetails, PUBLIC_POSTED_PROJECT, 90);
  const lastQuarterProjectsCount = lastQuarterCount(newFirmUserDetails, PUBLIC_POSTED_PROJECT);
  const last90DaysPercentage = ((last90DaysProjectsCount-lastQuarterProjectsCount) / lastQuarterProjectsCount) * 100 || 0;

  return {
    last90DaysPercentage,
    publiclyPostedProjects
  };
};

export const calculatePublicPostedJobs = (newFirmUserDetails) => {
  const publiclyPostedJobs = getTotalCount(newFirmUserDetails, PUBLIC_POSTED_JOB);
  const currentQuarterProjectsCount = getCustomDaysCount(newFirmUserDetails, PUBLIC_POSTED_JOB, 90);
  const lastQuarterProjectsCount = lastQuarterCount(newFirmUserDetails, PUBLIC_POSTED_JOB);
  const last90DaysPercentage = ((currentQuarterProjectsCount-lastQuarterProjectsCount) / lastQuarterProjectsCount) * 100 || 0;

  return {
    last90DaysPercentage,
    publiclyPostedJobs
  };
};

export const calculatePublicAppliedJobs = (newFirmUserDetails) => {
  const publiclyAppliedJobs = getTotalCount(newFirmUserDetails, PUBLIC_POSTED_JOB_APPLICATIONS);
  const currentQuarterProjectsCount = getCustomDaysCount(newFirmUserDetails, PUBLIC_POSTED_JOB_APPLICATIONS, 90);
  const lastQuarterProjectsCount = lastQuarterCount(newFirmUserDetails, PUBLIC_POSTED_JOB_APPLICATIONS);
  const last90DaysPercentage = ((currentQuarterProjectsCount-lastQuarterProjectsCount) / lastQuarterProjectsCount) * 100 || 0;

  return {
    last90DaysPercentage,
    publiclyAppliedJobs
  };
};

export const calculatePrivatelySharedJobs = (newFirmUserDetails) => {
  const privatelySharedJobs = getTotalCount(newFirmUserDetails, PRIVATELY_SHARED_JOB);
  const currentQuarterProjectsCount = getCustomDaysCount(newFirmUserDetails, PRIVATELY_SHARED_JOB, 90);
  const lastQuarterProjectsCount = lastQuarterCount(newFirmUserDetails, PRIVATELY_SHARED_JOB);
  const last90DaysPercentage = ((currentQuarterProjectsCount-lastQuarterProjectsCount) / lastQuarterProjectsCount) * 100 || 0;

  return {
    last90DaysPercentage,
    privatelySharedJobs
  };
};

export const calculatePrivatelyAppliedJobs = (newFirmUserDetails) => {
  const privatelyAppliedJobs = getTotalCount(newFirmUserDetails, PRIVATELY_SHARED_JOB_APPLICATIONS);
  const currentQuarterProjectsCount = getCustomDaysCount(newFirmUserDetails, PRIVATELY_SHARED_JOB_APPLICATIONS, 90);
  const lastQuarterProjectsCount = lastQuarterCount(newFirmUserDetails, PRIVATELY_SHARED_JOB_APPLICATIONS);
  const last90DaysPercentage = ((currentQuarterProjectsCount-lastQuarterProjectsCount) / lastQuarterProjectsCount) * 100 || 0;

  return {
    last90DaysPercentage,
    privatelyAppliedJobs
  };
};

export const calculateongoingRecruitments = (newFirmUserDetails) => {
  const last90DaysRecruitmentCount = getCustomDaysCountCompleteStatus(newFirmUserDetails, RECRUITMENTS_UNDERWAY, 90, false);
  const lastQuarterProjectsCount = lastQuarterCount(newFirmUserDetails, RECRUITMENTS_UNDERWAY, false);
  const last90DaysPercentage = ((last90DaysRecruitmentCount - lastQuarterProjectsCount) / lastQuarterProjectsCount) * 100;
  return {
    last90DaysRecruitmentCount,
    last90DaysPercentage
  };
};

export const calculateCompletedRecruitments = (newFirmUserDetails) => {
  const last90DaysCompletedRecruitmentCount = getCustomDaysCountCompleteStatus(newFirmUserDetails, RECRUITMENTS_UNDERWAY, 90, true);
  const lastQuarterProjectsCount = lastQuarterCount(newFirmUserDetails, RECRUITMENTS_UNDERWAY, true);
  const last90DaysPercentage = ((last90DaysCompletedRecruitmentCount-lastQuarterProjectsCount) / lastQuarterProjectsCount) * 100 || 0;

  return {
    last90DaysCompletedRecruitmentCount,
    last90DaysPercentage
  };
};

export const calculatePrivateSharedProjects = (newFirmUserDetails) => {
  const privatelySharedProjects = getTotalCount(newFirmUserDetails, PRIVATELY_SHARED_PROJECT);
  const last90DaysProjectsCount = getCustomDaysCount(newFirmUserDetails, PRIVATELY_SHARED_PROJECT, 90);
  const lastQuarterProjectsCount = lastQuarterCount(newFirmUserDetails, PRIVATELY_SHARED_PROJECT);
  const last90DaysPercentage = ((last90DaysProjectsCount-lastQuarterProjectsCount) / lastQuarterProjectsCount) * 100 || 0;

  return {
    last90DaysPercentage,
    privatelySharedProjects
  };
};

export const calculatePrivateProposalCounts = (newFirmUserDetails) => {
  const totalPrivateProposals = getTotalCount(newFirmUserDetails, PRIVATE_PROPOSAL);
  const last90DaysPrivateProposals = getCustomDaysCount(newFirmUserDetails, PRIVATE_PROPOSAL, 90);
  const lastQuarterProjectsCount = lastQuarterCount(newFirmUserDetails, PRIVATE_PROPOSAL);
  const last90DaysPercentage = ((last90DaysPrivateProposals-lastQuarterProjectsCount) / lastQuarterProjectsCount) * 100 || 0;

  return {
    last90DaysPercentage,
    totalPrivateProposals
  };
};

export const calculatePublicProposalCounts = (newFirmUserDetails) => {
  const totalPublicProposals = getTotalCount(newFirmUserDetails, PUBLIC_PROPOSAL);
  const last90DaysPublicProposal = getCustomDaysCount(newFirmUserDetails, PUBLIC_PROPOSAL, 90);
  const lastQuarterProjectsCount = lastQuarterCount(newFirmUserDetails, PUBLIC_PROPOSAL);
  const last90DaysPercentage = ((last90DaysPublicProposal-lastQuarterProjectsCount) / lastQuarterProjectsCount) * 100 || 0;

  return {
    last90DaysPercentage,
    totalPublicProposals,
  };
};

export const calculateProjectUnderwayCounts = (newFirmUserDetails) => {
  const totalUnderwayProjects = getTotalCount(newFirmUserDetails, PROJECT_UNDERWAY);
  const last90DaysUnderwayProjects = getCustomDaysCount(newFirmUserDetails, PROJECT_UNDERWAY, 90);
  const lastQuarterProjectsCount = lastQuarterCount(newFirmUserDetails, PROJECT_UNDERWAY);
  const last90DaysPercentage = ((last90DaysUnderwayProjects-lastQuarterProjectsCount) / lastQuarterProjectsCount) * 100 || 0;

  return {
    last90DaysPercentage,
    totalUnderwayProjects
  };
};

export const calculateProjectCommisionedCounts = (newFirmUserDetails) => {
  const totalCommissionedProjects = getTotalCount(newFirmUserDetails, PROJECT_COMMISSIONED);
  const last90DaysCommissionedProjects = getCustomDaysCount(newFirmUserDetails, PROJECT_COMMISSIONED, 90);
  const lastQuarterProjectsCount = lastQuarterCount(newFirmUserDetails, PROJECT_COMMISSIONED);
  const last90DaysPercentage = ((last90DaysCommissionedProjects-lastQuarterProjectsCount) / lastQuarterProjectsCount) * 100 || 0;

  return {
    last90DaysPercentage,
    totalCommissionedProjects
  };
};

export const calculateProjectCompletedCounts = (newFirmUserDetails) => {
  const totalCompletedProjects = getTotalCount(newFirmUserDetails, PROJECT_COMPLETED);
  const last90DaysCompletedProjects = getCustomDaysCount(newFirmUserDetails, PROJECT_COMPLETED, 90);
  const lastQuarterProjectsCount = lastQuarterCount(newFirmUserDetails, PROJECT_COMPLETED);
  const last90DaysPercentage = ((last90DaysCompletedProjects-lastQuarterProjectsCount) / lastQuarterProjectsCount) * 100 || 0;

  return {
    last90DaysPercentage,
    totalCompletedProjects
  };
};

export const getTotalWorkYears = (Experience = []) => {
  const totalMonths = getTotalWorkMonths(Experience);
  return Math.floor(totalMonths / 12)
}

export const getTotalWorkMonths = (Experience = []) => {
  const dates = Experience.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

  function calculateDuration(startDate, endDate) {
    let months = Math.floor(moment(endDate).diff(moment(startDate), 'months', true));
    const date = moment(endDate).format('DD');
    if (date > 15) months = months + 1;
    return Math.floor(months);
  }

  let totalDuration = 0;
  let latestEndDate = new Date('January 1, 1970'); // Initialize with a date in the past

  for (const dateRange of dates) {
    const startDate = new Date(dateRange.startDate);
    const endDate = !dateRange?.endDate ? new Date() : new Date(dateRange.endDate);

    // Check for overlapping periods
    if (startDate > latestEndDate) {
      totalDuration += calculateDuration(startDate, endDate);
      latestEndDate = endDate;
    } else if (endDate > latestEndDate) {
      totalDuration += calculateDuration(latestEndDate, endDate);
      latestEndDate = endDate;
    }
  }
  return totalDuration
}

export const calculateTotalWorkExperience = (experience) => {
  const totalMonths = getTotalWorkMonths(experience);
  const yearsCount = totalMonths / 12;

  if (Number.isInteger(yearsCount)) {
    return yearsCount === 1 ? `${yearsCount} year` : `${yearsCount} years`
  } else if (yearsCount < 1) {
    return `${totalMonths % 12} months`
  } else {
    return `${Math.floor(yearsCount)} ${Math.floor(yearsCount) === 1 ? 'year' : 'years'}, ${totalMonths % 12} ${totalMonths % 12 === 1 ? 'month' : 'months'}`
  }
}

export const isExpertAvailableForNext30Days = (startDate, endDate) => {
  const date = new Date();
  const currentDate = date.toISOString().substring(0, 10)
  return moment(currentDate).isBetween(startDate, endDate)
}

export const getAffiliatedFirmListingIds = listing => !!listing?.id && (listing?.attributes?.metadata?.affiliatedListingIds ?? []);

export const truncateOptionText = (optionText) => {
  const words = optionText.split(' ');
  const maxLength = 10; // Maximum number of words to display

  if (words.length > maxLength) {
    const truncatedText = words.slice(0, maxLength).join(' ');
    return truncatedText + '...';
  }

  return optionText;
}

export const checkDiscourseUser = (user) => user?.id && user?.attributes?.profile?.privateData?.discourseUserId

export const getUserChartData = (userDetails, tab) => {
  if (!userDetails?.length) return
  const lastYearCount = userDetails?.filter(u => u.joiningDate < lastYearEndTimestamp && u.joiningDate > lastYearStartTimestamp).length;
  const prevYearCount = userDetails?.filter(u => u.joiningDate < prevYearEndTimestamp && u.joiningDate > prevYearStartTimestamp).length;

  const lastYearData = months.map(({ month, year, monthIndex }) => {
    const { startTimestamp, endTimestamp } = getUnixTimestamp(year, monthIndex);
    return {
      month: month==="This month" ? month :`${month}-${year.slice(2, 4)}`,
      count: userDetails?.filter(u => u.joiningDate < endTimestamp && u.joiningDate > startTimestamp).length
    }
  });
  const lastThreeMonthCount = userDetails?.filter(u => u.joiningDate < lastThreeMonthEnd && u.joiningDate > lastThreeMonthStart).length;
  const prevThreeMonthCount = userDetails?.filter(u => u.joiningDate < prevThreeMonthEnd && u.joiningDate > prevThreeMonthStart).length;
  const lastThreeMonthsData = lastYearData.slice(-3);

  const lastYearGrowthPercentage = calculateGrowthPercentage(prevYearCount, lastYearCount);
  const lastThreeMonthGrowthPercentage = calculateGrowthPercentage(prevThreeMonthCount, lastThreeMonthCount);

  return tab === LAST_YEAR ? {
    count: lastYearCount,
    data: lastYearData,
    percentage: lastYearGrowthPercentage,
  } : {
    count: lastThreeMonthCount,
    data: lastThreeMonthsData,
    percentage: lastThreeMonthGrowthPercentage
  }
}

const calculateCount = (array, category, endTime, startTime) => {
  const count = array?.reduce((acc, curr) => {
    return acc + curr[category]?.filter(l => l.date < endTime && l.date > startTime).length
  }, 0);
  return count
}

const recruitmentCount = (array, category, endTime, startTime, condition)=>{
  const count = array?.reduce((acc, curr) => {
    return acc + curr[category]?.filter(l => l.date < endTime && l.date > startTime && l.isCompleted===condition).length
  }, 0);
  return count
}

export const getChartData = (userDetails, tab, isClient, modalType) => {
  if (!userDetails?.length) return
  const getCategory = () => {
    switch (modalType) {
      case LOGINS:
        return 'login'
      case PUBLIC_PROJECTS:
        return isClient ? 'publiclyPostedProject' : 'publicProposal'
      case PRIVATE_PROJECTS:
        return isClient ? 'privatelySharedProject' : 'privateProposal'
      case COMMISSIONED_PROJECTS:
        return 'projectCommissioned'
      case COMPLETED_PROJECTS:
        return 'projectCompleted'
      case UNDERWAY_PROJECTS:
        return 'projectUnderway'
      case PUBLIC_JOBS:
        return 'publiclyPostedJobs'
      case PRIVATE_JOBS:  
        return 'privatelySharedJobs'
      case ONGOING_RECRUITMENTS:
        return 'recruitmentsUnderway'
      case COMPLETED_RECRUITMENTS:
        return 'recruitmentsUnderway'
      case APPLIED_PUBLIC_JOBS:
        return 'publiclyPostedJobApplications'
      case APPLIED_PRIVATE_JOBS:
        return 'privatelySharedJobApplications'
    }
  }
  const category = getCategory();

  const total = userDetails?.reduce((total, user) => total + user?.[category]?.length, 0);

  // Get current date at 12:00 AM
  const todayStart = moment().startOf('day').valueOf();

  // Get tomorrow date at 12:00 AM
  const tomorrowStart = moment().add(1, 'day').startOf('day').valueOf();

  const todayCount = calculateCount(userDetails, category, tomorrowStart, todayStart)
  const thisMonthCount = calculateCount(userDetails, category, tomorrowStart, lastThreeMonthEnd)

  const lastYearCount = calculateCount(userDetails, category, lastYearEndTimestamp, lastYearStartTimestamp);
  const prevYearCount = calculateCount(userDetails, category, prevYearEndTimestamp, prevYearStartTimestamp);
  const lastYearData = months.map(({ month, year, monthIndex }) => {
    const { startTimestamp, endTimestamp } = getUnixTimestamp(year, monthIndex);
    return {
      xAxis: month==="This month" ? month :`${month}-${year.slice(2, 4)}`,
      count: userDetails?.reduce((acc, curr) => {
        return acc + curr[category]?.filter(l => l.date < endTimestamp && l.date > startTimestamp)?.length
      }, 0)
    }
  });

  const lastThreeMonthCount = calculateCount(userDetails, category, lastThreeMonthEnd, lastThreeMonthStart);
  const prevThreeMonthCount = calculateCount(userDetails, category, prevThreeMonthEnd, prevThreeMonthStart);
  const lastThreeMonthsData = lastYearData.slice(-3)

  const last30DaysTotal = (modalType === ONGOING_RECRUITMENTS || modalType === COMPLETED_RECRUITMENTS) ? 
  recruitmentCount(userDetails, category, currentMonthEnd, currentMonthStart, modalType === ONGOING_RECRUITMENTS ? false : true) : 
  calculateCount(userDetails, category, currentMonthEnd, currentMonthStart);

  const prev30DaysTotal = calculateCount(userDetails, category, prevMonthEnd, prevMonthStart);
  const last30DaysData = last30Days.map(({ day, startTime, endTime, month }) => {
    if(modalType === ONGOING_RECRUITMENTS || modalType === COMPLETED_RECRUITMENTS){
      return {
        xAxis: day,
        label: month,
        count: userDetails?.reduce((acc, curr) => {
          return acc + curr[category]?.filter(l => l.date < endTime && l.date > startTime && l.isCompleted===((modalType === ONGOING_RECRUITMENTS) ? false : true))?.length
        }, 0)
      }
    }else{
      return {
        xAxis: day,
        label: month,
        count: userDetails?.reduce((acc, curr) => {
          return acc + curr[category]?.filter(l => l.date < endTime && l.date > startTime)?.length
        }, 0)
      }
    }
  })

  const lastYearGrowthPercentage = calculateGrowthPercentage(prevYearCount, lastYearCount);
  const lastThreeMonthGrowthPercentage = calculateGrowthPercentage(prevThreeMonthCount, lastThreeMonthCount);
  const currentMonthGrowthPercentage = calculateGrowthPercentage(prev30DaysTotal, last30DaysTotal);

  return tab === LAST_YEAR ?
    {
      count: lastYearCount,
      data: lastYearData,
      percentage: lastYearGrowthPercentage
    } : tab === LAST_THREE_MONTHS ? {
      count: lastThreeMonthCount,
      data: lastThreeMonthsData,
      percentage: lastThreeMonthGrowthPercentage
    } : {
      count: last30DaysTotal,
      data: last30DaysData,
      percentage: currentMonthGrowthPercentage
    }
}

const getAverageRating = (users, endTime, startTime) => {
  const ratings = users.map((user) => {
    return user?.averageRating?.filter(r => r.date < endTime && r.date > startTime).map(r => r.rating);
  })?.flat();
  const averageRating = ratings?.length ? calculateAverageRating(ratings) : 0;
  return averageRating
}

const getDailyAvergeRating = (users, startTime) => {
  const ratings = users.map((user) => {
    return user?.averageRating?.filter(r => r.date < (startTime + 86400000) && r.date > startTime).map(r => r.rating);
  })?.flat();
  const averageRating = ratings?.length ? calculateAverageRating(ratings) : 0;
  return averageRating
}

export const getAverageRatingChartData = (userDetails, tab) => {
  if (!userDetails?.length) return
  let lastYearRating = 0, lastMonthRating = 0;
  const lastYearAverageRating = getAverageRating(userDetails, lastYearEndTimestamp, lastYearStartTimestamp);
  const prevAverageYearRating = getAverageRating(userDetails, prevYearEndTimestamp, prevYearStartTimestamp);

  const lastYearData = months.map(({ month, year, monthIndex }) => {
    const { startTimestamp, endTimestamp } = getUnixTimestamp(year, monthIndex);
    const averageRating = getAverageRating(userDetails, endTimestamp, startTimestamp);
    if (averageRating !== 0) lastYearRating = averageRating
    return { month: month==="This month" ? month :`${month}-${year.slice(2, 4)}`, rating: lastYearRating }
  });


  const lastThreeMonthAverageRating = getAverageRating(userDetails, lastThreeMonthEnd, lastThreeMonthStart);
  const prevThreeMonthAverageRating = getAverageRating(userDetails, prevThreeMonthEnd, prevThreeMonthStart);
  const lastThreeMonthsData = lastYearData.slice(-3);

  const lastMonthAverageRating = getAverageRating(userDetails, currentMonthEnd, currentMonthStart);
  const prevMonthAverageRating = getAverageRating(userDetails, prevMonthEnd, prevMonthStart);

  const lastMonthsData = last30Days.map(({ day, startTime, month }) => {
    const averageRating = getDailyAvergeRating(userDetails, startTime);
    return { month: day, label: month, rating: averageRating }
  });

  const lastYearGrowthPercentage = calculateGrowthPercentage(prevAverageYearRating, lastYearAverageRating);
  const lastThreeMonthGrowthPercentage = calculateGrowthPercentage(prevThreeMonthAverageRating, lastThreeMonthAverageRating);
  const lastMonthGrowthPercentage = calculateGrowthPercentage(prevMonthAverageRating, lastMonthAverageRating);

  return tab === LAST_YEAR ?
    {
      rating: lastYearAverageRating,
      data: lastYearData,
      percentage: lastYearGrowthPercentage
    } : tab === LAST_THREE_MONTHS ? {
      rating: lastThreeMonthAverageRating,
      data: lastThreeMonthsData,
      percentage: lastThreeMonthGrowthPercentage
    } : {
      rating: lastMonthAverageRating,
      data: lastMonthsData,
      percentage: lastMonthGrowthPercentage
    }
}

export const offlineSubscriptionObject = (user) => user?.attributes?.profile?.protectedData?.offlineSubscriptionObject || {};

export const sharedBriefIds = (user) => user?.attributes?.profile?.protectedData?.briefIds || [];

export const checkUserRole = (userRoleParams) => {
  const { userEmail, currentUser, currentFirmAuthor, firmData } = userRoleParams
  if (getUserRole(currentUser) === USER_ROLE_CLIENT) {
    return CUSTOMER_INVITEE;
  }
  else if (getUserRole(currentUser) === USER_ROLE_CSM) {
    return CUSTOMER_INVITEE;
  }
  else if (getUserRole(currentUser) === USER_ROLE_PARTNER) {
    const collaboratorsEmails = !!firmData?.length && firmData.map(e => e.email);
    const expertInviteeRole =
      !!collaboratorsEmails?.length &&
        (collaboratorsEmails.includes(userEmail) || currentFirmAuthor?.attributes?.email === userEmail)
        ? PROVIDER_INVITEE
        : THIRD_PARTY_EXPERT;
    return expertInviteeRole;
  }
};


export const freePlanObject = {
  startDate: moment().unix(),
  endDate: moment()
    .add(60, 'days')
    .unix(),
  planType: FREE_PLAN,
  isFreePlanUsed: true,
};

export const getChainedTransactionId = (currentTransaction) => currentTransaction?.id && currentTransaction?.attributes?.protectedData?.chainedTransactionId;


export const formatAmount = (amount, currencyToggle) => {
  const currencySymbol = currencyToggle === USD ? '$' : '₹';
  const formattedAmount = (amount / 100).toLocaleString('en-US', {
    maximumFractionDigits: 0
  });
  return currencySymbol + formattedAmount;

};

export const getCsmBannerTime = (user) => {
  // Get the CSM banner time object, or return null if it does not exist.
  const csmBannerTimeObject = user?.attributes?.profile?.publicData?.csmBannerTimeObject;

  if (!csmBannerTimeObject) {
    return null;
  }

  // Return the CSM banner time object.
  return csmBannerTimeObject;
};

export function generateNotificationContent(milestoneType, providerName) {
  switch (milestoneType) {
    case 'addMilestone':
      return `${providerName} has added a milestone`;
    case 'editMilestone':
      return `${providerName} has edited a milestone`;
    case 'deleteMilestone':
      return `${providerName} has deleted a milestone`;
    case 'completeMilestone':
      return `${providerName} has completed a milestone`;
    case 'incompleteMilestone':
      return `${providerName} has marked a milestone incomplete`;
    default:
      return `${providerName} has performed an unspecified milestone action`;
  }
}

export function getDataInChunks(text, chunkSize=1024) {
  if(!text) return []
  const tokens = text.match(/.{1,5}/g); // Assuming each token is 4 characters
  const chunks = [];

  for (let i = 0; i < tokens.length; i += chunkSize) {
    const chunk = tokens.slice(i, i + chunkSize).join('');
    chunks.push(chunk);
  }
  return chunks;
}

export function extractLabels(items) {
  return items?.map(item => item.label) || [];
}

export function extractUSP(items) {
  return items?.find(item => item.isUSP) || {};
}


export function formatServiceInsightsForForm(services, usp = {}) {
  // Handle undefined or non-array input
  if (!Array.isArray(services) || services.length === 0) {
    return []; // Return an empty array to avoid errors downstream
  }

  // Map services to the desired format, handling potential errors
  return services.map((service) => {
    // Check if each item in the array is a string
    if (typeof service !== 'string') {
      return { key: '', label: '' }; // Return empty values for invalid items
    }

    // Process valid string items
    try {
      const isUSP = usp.label === service;
      return {
        key: service.toLowerCase(), // Ensure lowercase keys without using new String()
        label: service,
        isUSP, // Include the isUSP property in the result
      };
    } catch (error) {
      return { key: '', label: '' }; // Return empty values for invalid items
    }
  });
}


export function getRefinedLabel(el, domainExpertise, industrySector, descriptionCategoriesOptions, optionaldescriptionCategoriesOptions) {
  const temp =
    domainExpertise && domainExpertise.find((e) => e && e.key === el) ||
    industrySector && industrySector.find((e) => e && e.key === el);

  if (temp && temp.key === "others") {
    if (descriptionCategoriesOptions) {
      return `${descriptionCategoriesOptions} (Others)`;
    } else if (optionaldescriptionCategoriesOptions) {
      return `${optionaldescriptionCategoriesOptions} (Others)`;
    } else {
      return temp.label; // Use original label if no specific options
    }
  } else {
    return el?.label ?? el; // Use el.label if available, otherwise the value of el
  }
}


export function isValidWebsiteUrl(url) {
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(url);

};

export  function ensureHttpsPrefix(url) {
  return url?.startsWith('https://') ? url : `https://${url}`;
}

export  function getCurrentAuthor(params) {
  const {currentTransaction,currentUser} = params;
  const currentCustomer = currentTransaction?.customer;
  const currentProvider = currentTransaction?.provider;

  // Prioritize checking for currentCustomer to potentially avoid unnecessary calls to getUserDetails
  if (currentCustomer && currentUser?.id?.uuid === getUserDetails(currentCustomer)?.id) {
    return currentCustomer;
  } else if (currentProvider && currentUser?.id?.uuid === getUserDetails(currentProvider)?.id) {
    return currentProvider;
  } else {
    return null;
  }
}


export function getCollaboratorMetadata(params = {}) {
  const { currentTransaction = {} } = params;
  const collaborationMetaData =
    currentTransaction.id && (currentTransaction.metadata?.collaborationMetaData ?? []);

  return collaborationMetaData.length > 0
    ? collaborationMetaData.map((user) => ({
        name: getUserDisplayName(user.fullName),
        fullName: user.fullName,
        profileImage: user.profileImage,
        id: user.collaboratorID,
        email: user.email,
        role: user.invitedByUserRole,
      }))
    : []; // Explicitly return an empty array if no collaborators
}

export function getUserFirmInfo(params) {
  const {userData=[],email} = params||{};
  let firmTitle, firmId, role;
  userData?.length && userData.map(user => {
    if (user?.attributes?.email === email) {
      firmId = getUserDetails(user)?.firmId;
      firmTitle = getUserDetails(user)?.firmName;
      role = getUserRole(user);
    }
  })
  return { firmTitle, firmId, role };
}

export function checkIfUserSubscribed(user) {
  const isOfflineSubscriptionPaid = user?.attributes?.profile?.protectedData?.offlineSubscriptionObject?.isOfflineSubscriptionPaid;

  return (isOfflineSubscriptionPaid && isOfflineSubscriptionPaid !== "false" && isOfflineSubscriptionPaid !== false)
}