import { Field, Form as FinalForm } from 'react-final-form';
import { Form, PrimaryButton, SecondaryButton, IconCard } from '../../components';
import css from './OpenAIQueryReportForm.module.css'
import { FormattedMessage } from 'react-intl';
import FileView from '../../components/FileView/FileView';
import { useEffect, useState } from 'react';

const ACCEPT_FILE = 'application/pdf, .doc, .docx, .ppt, .pptx';
const SINGLE_FILE_SIZE_LIMIT = 25000000;
const TOTAL_FILE_SIZE_LIMIT = 150000000;

const OpenAIQueryReportForm = (props) => {

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          pristine,
          values,
          form,
          openaiRequest,
          isError,
        } = formRenderProps;
        
        const {files = []} = values || {};
        const [error, setError] = useState('');
        const submitDisabled = !files?.length;

        useEffect(() => {
          if (!!isError) form.reset()
        }, [isError])

        function handleRemoveFile(selectedFile) {
          const filteredFiles = files?.length && files.filter(file => file.name !== selectedFile.name)
          form.change('files', filteredFiles)
        }

        function handleError(message) {
          setError(message)
          setTimeout(() => setError(false), 3000)
        }

        return (
          <Form className={css.queryReportForm} onSubmit={handleSubmit}>
            <div className={css.container}>
              <div className={css.briefGenerator}>
                <span className={css.generatorTitle}>
                  <FormattedMessage id={"OpenAIQueryReportForm.heading"} />
                </span>
              </div>
              <div className={css.attachment}>
                <h3><FormattedMessage id={"OpenAIQueryReportForm.subHeading"} /></h3>
                <p><FormattedMessage id='OpenAIQueryReportForm.subHeadingNote' /></p>

                {files?.length > 0 ? files?.map(file => (
                  <span key={file.name}>
                    <FileView file={file}>
                      <span className={css.close} onClick={() => handleRemoveFile(file)}>
                        <IconCard brand='cross' />
                      </span>
                    </FileView>
                  </span>
                )) : (
                  <Field
                    id="file"
                    name="file"
                    accept={ACCEPT_FILE}
                    type="file"
                  >
                    {fieldprops => {
                      const { accept, input, disabled: fieldDisabled } = fieldprops;
                      const { name, type } = input;
                      const onChange =async e => {
                        const files = Object.values(e.target.files);
                        const totalFileSize = files.reduce((acc, curr) => acc + curr.size, 0)

                        if (files?.length > 10) {
                          handleError('You can upload maximum 10 files!')
                          return
                        }
                        if (!!files.find(file => file.size > SINGLE_FILE_SIZE_LIMIT)) {
                          handleError('File size can not be greater than 50mb!')
                          return
                        }
                        if (totalFileSize > TOTAL_FILE_SIZE_LIMIT) {
                          handleError('Total size can not be greater than 150mb!')
                          return
                        }
                        form.change('files', files)        
                        form.change('gigAppInputFiles',files)
                      };

                      const inputProps = { accept, id: name, name, onChange, type };
                      return (
                        <>
                          {fieldDisabled ? null : (
                            <input {...inputProps} className={css.addImageInput} multiple={true} />
                          )}
                          <label type='button' htmlFor={name} className={css.attachmentLabel}>
                            <span className={css.dragDrop}><FormattedMessage id="ZohoSign.dragAndDropUpload" /></span>
                            <span className={css.docType}>
                              <FormattedMessage id="JobListing.documentAllowList" />
                            </span>
                          </label>
                        </>
                      );
                    }}
                  </Field>
                )}
              </div>
              {!!error && (
                <p className={css.error}>{error}</p>
              )}
              <div className={css.actionButtons}>
                <SecondaryButton
                  type='button'
                  className={css.resetButton}
                  onClick={() => form.reset()}
                  disabled={openaiRequest}
                >
                  <FormattedMessage id="OpenAIContractForm.resetButtonText" />
                </SecondaryButton>
                <PrimaryButton
                  type='submit'
                  className={css.submitButton}
                  disabled={submitDisabled}
                  inProgress={openaiRequest}
                >
                  <FormattedMessage id="OpenAIContractForm.submitButtonText" />
                </PrimaryButton>
              </div>
            </div>
          </Form>
        )
      }}
    />
  )
}

export default OpenAIQueryReportForm