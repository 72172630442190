import React, { useEffect, useRef, useState } from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { Form, FieldTextInput, PrimaryButton, SecondaryButton, FieldDateInput, FieldSelect, IconSpinner, IconCard, Modal } from '../../components';
import css from './OpenAIProposalForm.module.css';
import moment from 'moment';
import { handleFileUpload } from '../../util/api';
import { currencyFeeTemp } from '../../marketplace-custom-config';
import FileView from '../../components/FileView/FileView';
import classNames from 'classnames';

const ACCEPT_FILE = '.doc,.docx,application/msword,application/pdf';
const FILE_SIZE_LIMIT = 25*1000*1000;

export const paymentTermsFields = [
    { key: '0.00', label: 'Full payment on project completion' },
    { key: '0.25', label: '25% due immediately' },
    { key: '0.50', label: '50% due immediately' },
    { key: '0.60', label: '60% due immediately' },
    { key: '0.75', label: '75% due immediately' },
    { key: '1.00', label: '100% due immediately' },
]

export const OpenAIProposalForm = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          pristine,
          updated,
          formId,
          updateError,
          values,
          form,
          errors,
          openaiRequest,
          initialText,
          proposalSucess,
          handleRedirectToGigAppsPage,
          manageDisableScrolling,
          setProposalSuccess
        } = formRenderProps;

        const [file, setFile] = useState();
        const [briefDocumentFile, setBriefDocumentFile] = useState();
        const [credentialFile,setCredentialFile] = useState();
        const [outputFormatFile,setOutputFormatFile] = useState();
        const [isLoading, setIsLoading] = useState([]);
        const [toggle,setToggle] = useState(false);
        const [selectedBriefFields,setSelectedBriefFields] = useState([]);
        const [fileSizeError,setFileSizeError] = useState([]);
        const viewerRef = useRef(null);

        const submitDisabled = (!selectedBriefFields.includes('content') && !values.content) || (!selectedBriefFields.includes('fee') && !values.fee) || (!selectedBriefFields.includes('fee') && !values?.feeCurrency) || (toggle && !values.brief);
        const isDayBlocked = day => values && values.startDate && moment(values.startDate.date).isSameOrAfter(moment(day));

        const projectDetailsPlaceholder = intl.formatMessage({ id: "OpenAIProposalForm.projectDetailsPlaceholder"});
        const feeLabel = intl.formatMessage({ id: "OpenAIProposalForm.feeLabel"});
        const paymentTermsLabel = intl.formatMessage({ id: "OpenAIProposalForm.paymentTermsLabel"});

        // const briefFieldNames = ["Objective", "Timeline", "Budget", "Payment Terms"];
        const briefFieldNames = [
          {label:"Objective",value:"content"},
          {label:"Timeline",value:"timeline"},
          {label:"Budget",value:"fee"},
          {label:"Payment Terms",value:"paymentTerms"}
        ]

        useEffect(() => {
          if(initialText){
            form.reset();
          }
        }, [initialText])

        async function handleAttachment(file,field){
          try{
            setIsLoading([...isLoading,field])
            const response = await handleFileUpload('get-parsed-document', {file})

            if(response?.data){
              if(field==="brief"){
                setBriefDocumentFile(file)
              }else if(field==="credentials"){
                setCredentialFile(file)
              }else{
                setOutputFormatFile(file)
              }
              form.change(field,response.data)
              setIsLoading(isLoading.filter(e=>e!==field))
              Object.assign(values, {attachmentText: response?.data})
            }
          }catch(error){
            setIsLoading(isLoading.filter(e=>e!==field))
          }
        };

        const handleToggle = ()=>{
          setToggle(!toggle);
          setSelectedBriefFields([]);
          values.hiddenFields = []
        };

        const handleSelectedBriefFields = (e)=>{
          if((values.hiddenFields).includes(e)){
            setSelectedBriefFields(selectedBriefFields.filter(item=>item!==e));
            values.hiddenFields = values.hiddenFields.filter(item=>item!==e)
          }else{
            setSelectedBriefFields([...selectedBriefFields,e]);
            delete values[e]
            if(e==="fee"){
              delete values.feeCurrency
            }else if(e==="timeline"){
              delete values.startDate
              delete values.endDate
            }
            values.hiddenFields = (values.hiddenFields && values.hiddenFields.length>0) ? [...values.hiddenFields,e] : [e]
          }
        };

        const handleSelectAllBriefs = ()=>{
          if(values.hiddenFields.length===4){
            setSelectedBriefFields([]);
            values.hiddenFields = []
          }else{
            briefFieldNames.map(item=>{
              delete values[item.value]
              delete values.feeCurrency
              delete values.startDate
              delete values.endDate
            })
            values.hiddenFields = briefFieldNames.map(item=>item.value)
            setSelectedBriefFields(briefFieldNames.map(item=>item.value));
          }
        };

        const handleFileSizeError=(name)=>{
          setFileSizeError([...fileSizeError,name])
          setTimeout(()=>{
            setFileSizeError(fileSizeError.filter(e=>e!==name))
          },3000)
        }

        const checkFileSizeError =(name)=>{
          if(fileSizeError.includes(name)){
            return <p className={css.fileSizeError}>Please select a file less than {FILE_SIZE_LIMIT/1000000}MB size.</p>
          }
        }

        const attachmentField = (fieldName,fileState,setFileState,label,span)=>{
          return (
            <div className={css.attachment}>
              <label><FormattedMessage id={`OpenAIContractForm.${label}`} />{span && <span className={classNames(css.tooltip,css[span])}>?</span>}</label>
              {fileState?
                <FileView file={fileState}>
                  <span className={css.close} onClick={() => {
                    setFileState(null)
                    if(fieldName==="brief"){
                      setSelectedBriefFields([])
                      form.change("hiddenFields",[])
                    }
                    form.change(fieldName,"")
                  }}>
                    <IconCard brand='cross'/>
                  </span>
                </FileView>
              :
              <>
                <Field
                  id={fieldName}
                  name={fieldName}
                  accept={ACCEPT_FILE}
                  type="file"
                >
                  {fieldprops => {
                    const { accept, input, disabled: fieldDisabled } = fieldprops;
                    const { name, type } = input;
                    const onChange = e => {
                      const file = e.target.files[0];
                      if(file && file.name && file.size > FILE_SIZE_LIMIT){
                        handleFileSizeError(fieldName)
                        return
                      }
                      handleAttachment(file,fieldName)
                      form.change('gigAppInputFiles',[...(values.gigAppInputFiles || []), file])
                    };
                  
                    const inputProps = { accept, id: name, name, onChange, type };
                    return (
                      <>
                        {fieldDisabled ? null : (
                          <input {...inputProps} className={css.addImageInput} />
                        )}
                        <label type='button' htmlFor={name} className={css.attachmentLabel}>
                          {(isLoading.includes(fieldName)) ? <IconSpinner strokeColor='#0B96F5' /> : <IconCard brand="upload" />}
                          <span className={css.dragDrop}><FormattedMessage id="ZohoSign.dragAndDropUpload" /></span>
                          <span className={css.docType}>
                            <FormattedMessage id="ZohoSign.pdfDoc" />
                          </span>
                        </label>
                      </>
                    );
                  }}
                </Field>
              {checkFileSizeError(fieldName)}
              </>}
            </div>
          )
        }

        return (
          <>
          <Form className={css.proposalGeneratorForm} onSubmit={handleSubmit} >
            <div className={css.container}>
              <div className={css.briefGenerator}>
                <span className={css.generatorTitle}>
                  <FormattedMessage id="OpenAIProposalForm.heading" />
                </span>
              </div>
              <div className={css.documentSwitcher}>
                <label>
                  <FormattedMessage id="OpenAIProposalForm.documentSwitcherText" />
                </label>
                <div className={classNames(css.switcherWrapper, toggle && css.toggled)} onClick={handleToggle}>
                  <div className={css.ball}></div>
                </div>
              </div>
              {toggle ? 
              <div className={css.briefAttachmentDiv}>
                {attachmentField("brief",briefDocumentFile,setBriefDocumentFile,"briefAttachmentText")}
                {briefDocumentFile &&
                <div>
                    <label><FormattedMessage id="OpenAIContractForm.availableFieldsText" /></label>
                    <div className={css.briefCheckboxContainer}>
                      <div className={css.briefCheckbox}>
                        <input className={css.checkbox} checked={values.hiddenFields.length===briefFieldNames.length} onChange={handleSelectAllBriefs} type="checkbox"/>
                        <label><FormattedMessage id="Select all" /></label>
                      </div>
                      {briefFieldNames.map((item,index)=>(
                        <div className={css.briefCheckbox} key={index}>
                          <input 
                            id={item.value}
                            type="checkbox" 
                            onChange={()=>handleSelectedBriefFields(item.value)} 
                            checked={values.hiddenFields && values.hiddenFields.includes(item.value)}
                          />
                          <label htmlFor={item.value}><FormattedMessage id={item.label} /></label>
                        </div>
                      ))}
                    </div>
                </div>}
              </div>
              :
              <></>
              }
              <div className={css.attachmentsContainer}>
                {attachmentField("credentials",credentialFile,setCredentialFile,"supportingAttachmentText","credentialContent")}
                {attachmentField("documentFormat",outputFormatFile,setOutputFormatFile,"outputFormatText","outputContent")}
              </div>
              {/* {(!values.hiddenFields || (values.hiddenFields && !values.hiddenFields.includes("content"))) && <div className={css.textareaBox}> */}
              {(!selectedBriefFields.includes("content")) && <div className={css.textareaBox}>
                <label><FormattedMessage id="OpenAIProposalForm.contentLabel" /></label>
                <FieldTextInput
                  className={css.inputBox}
                  type="textarea"
                  id='content'
                  name="content"
                  cols="100"
                />
              </div>}
              <div className={css.textareaBox}>
                <label><FormattedMessage id="OpenAIProposalForm.projectDetailsLabel" /></label>
                <FieldTextInput
                  className={css.inputBox}
                  type="textarea"
                  id='projectDetails'
                  name="projectDetails"
                  placeholder={projectDetailsPlaceholder}
                />
              </div>
              {/* <div className={css.attachment}>
                <label><FormattedMessage id="OpenAIContractForm.attachmentButtonText" /></label>
                {file ? 
                  <FileView file={file}>
                    <span className={css.close} onClick={() => setFile(null)}>
                      <IconCard brand='cross'/>
                    </span>
                  </FileView>
                : (
                  <Field
                    id="file"
                    name="file"
                    accept={ACCEPT_FILE}
                    type="file"
                  >
                    {fieldprops => {
                      const { accept, input, disabled: fieldDisabled } = fieldprops;
                      const { name, type } = input;
                      const onChange = e => {
                        const file = e.target.files[0];
                        if (file && file.name && file.size < FILE_SIZE_LIMIT) handleAttachment(file,'file')
                      };

                      const inputProps = { accept, id: name, name, onChange, type };
                      return (
                        <>
                          {fieldDisabled ? null : (
                            <input {...inputProps} className={css.addImageInput} />
                          )}
                          <label type='button' htmlFor={name} className={css.attachmentLabel}>
                            {(isLoading==="file") ? <IconSpinner strokeColor='#0B96F5' /> : <IconCard brand="upload" />}
                            <span className={css.dragDrop}><FormattedMessage id="ZohoSign.dragAndDropUpload" /></span>
                            <span className={css.docType}>
                              <FormattedMessage id="ZohoSign.pdfDoc" />
                            </span>
                          </label>
                        </>
                      );
                    }}
                  </Field>
                )}
              </div> */}
              <div className={css.rowWrapper}>
                {(!selectedBriefFields.includes("fee"))&&<div className={css.currencyWrapper}>
                  <FieldSelect
                    id='feeCurrency'
                    name='feeCurrency'
                    className={css.select}
                    defaultValue={currencyFeeTemp[0].key}
                    label={feeLabel}
                  >
                    <option disabled></option>
                    {currencyFeeTemp.map(({ sym, key }) => (
                      <option key={key} value={key}>{sym}</option>
                    ))}
                  </FieldSelect>
                  <FieldTextInput
                    className={css.budgetInputBox}
                    type="number"
                    name="fee"
                  />
                </div>}
                {(!selectedBriefFields.includes("paymentTerms")) &&
                  <FieldSelect
                  id='paymentTerms'
                  name='paymentTerms'
                  className={css.fieldSelector} 
                  label={paymentTermsLabel}
                  >
                    <option>{'Select option'}</option>
                    {paymentTermsFields.map(({key, label}) => (
                      <option key={key} value={key}>{label}</option>
                      ))}
                  </FieldSelect>
                }
              </div>
              {(!selectedBriefFields.includes("timeline")) &&
                <div className={css.rowWrapper}>
                  <FieldDateInput
                    id="startDate"
                    name="startDate"
                    className={css.fieldDateInput}
                    label="Proposed Start Date • optional"
                    placeholderText='Select Date'
                  />
                  <FieldDateInput
                    id="endDate"
                    name="endDate"
                    className={css.fieldDateInput}
                    label="Proposed End Date • optional"
                    placeholderText='Select Date'
                    isDayBlocked={isDayBlocked}
                    //   validate={required(startDateRequiredMessage)}
                  />
                </div>}
              <div className={css.actionButtons}>
                <SecondaryButton 
                  type='button'
                  className={css.resetButton} 
                  onClick={()=>{
                    form.reset()
                    setBriefDocumentFile(null)
                    setCredentialFile(null)
                    setOutputFormatFile(null)
                  }}
                  disabled={openaiRequest}
                >
                  <FormattedMessage id="OpenAIContractForm.resetButtonText" />
                </SecondaryButton>
                <PrimaryButton
                  type='submit'
                  className={css.submitButton} 
                  disabled={submitDisabled}
                  inProgress={openaiRequest}
                >
                  <FormattedMessage id="OpenAIContractForm.submitButtonText" />
                </PrimaryButton>
              </div>
            </div>
          </Form>
          <div ref={viewerRef} className={css.toastuiContainer} id="viewer"></div>
          </>
        );
      }}
    />
  )
}


OpenAIProposalForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  //   updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(OpenAIProposalForm);