import { useEffect, useState } from "react";
import { GIG_APP_COMPLETED, GIG_APP_USAGE, GIG_APP_VIEWS } from "../util/types";
import moment from 'moment';

const last30dayTime = moment().subtract('30', 'days').valueOf();
const last90dayTime = moment().subtract('90', 'days').valueOf();
const last180dayTime = moment().subtract('180', 'days').valueOf();
const lastYearTime = moment().subtract('1', 'year').startOf('month').valueOf();

export default function useFetchGigAppData(userDetails) {
  const [gigAppData, setGigAppData] = useState({})

  useEffect(() => {
    const response = calculateGigappUsageCount(userDetails, '');
    if(Object.values(response).length) setGigAppData(response)
  }, [userDetails?.length])

  function getTotalCount(userDetails, category, appRoute) {
    return userDetails.reduce((total, user) => {
      const categoryItem = user[category] || [];
      return total + categoryItem.filter(itm => !!appRoute ? itm.name === appRoute : itm).length;
    }, 0);
  }

  function getCustomDaysCount(userDetails, category, days, appRoute) {
    const previousTime = days === 30 ? Number(last30dayTime) : days === 90 ? Number(last90dayTime) : Number(lastYearTime);
    const currentDateTime = Date.now();
  
    return userDetails.reduce((total, user) => {
      const categoryItem = user[category];
      const filteredItems = categoryItem.filter(itm => {
        const isInDateRange = itm.date < currentDateTime && itm.date > previousTime;
        if (appRoute) {
          return itm.name === appRoute && isInDateRange;
        }
        return isInDateRange;
      });
      
      return total + filteredItems.length;
    }, 0);
  }

  function getLastQuarterDaysCount(userDetails, category, appRoute) {
    const previousTime = Number(last180dayTime)
    const currentDateTime = Number(last90dayTime)

    return userDetails.reduce((total, user) => {
      const categoryItem = user[category];
      const filteredItems = categoryItem.filter(itm => {
        const isInDateRange = itm.date < currentDateTime && itm.date > previousTime;
        if (appRoute) {
          return itm.name === appRoute && isInDateRange;
        }
        return isInDateRange;
      });
      
      return total + filteredItems.length;
    }, 0);
  }
  
  const calculateGigappUsageCount = (data = [], appRoute) => {
    const gigAppUsageCount = getTotalCount(data, GIG_APP_USAGE, appRoute);
    const gigAppLastQuarterUsage = getLastQuarterDaysCount(data, GIG_APP_USAGE, appRoute);
    const gigAppViewsCount = getTotalCount(data, GIG_APP_VIEWS, appRoute);
    const gigAppLastQuarterViews = getLastQuarterDaysCount(data, GIG_APP_VIEWS, appRoute);
    const gigAppCompletedCount = getTotalCount(data, GIG_APP_COMPLETED, appRoute);
    const gigAppUsageLast90DaysCount = getCustomDaysCount(data, GIG_APP_USAGE, 90, appRoute);
    const gigAppViewsLast90DaysCount = getCustomDaysCount(data, GIG_APP_VIEWS, 90, appRoute);
    const gigAppCompletedLast90DaysCount = getCustomDaysCount(data, GIG_APP_COMPLETED, 90, appRoute);
    const gigAppCompletedLastQuarter = getLastQuarterDaysCount(data, GIG_APP_COMPLETED, appRoute);
    const gigAppUsageLast90DaysPercetange = ((gigAppUsageLast90DaysCount - gigAppLastQuarterUsage)  / gigAppLastQuarterUsage) * 100 || 0;
    const gigAppViewsLast90DaysPercetange = ((gigAppViewsLast90DaysCount - gigAppLastQuarterViews) / gigAppLastQuarterViews) * 100 || 0;  
    const gigAppCompletedLast90DaysPercetange = ((gigAppCompletedLast90DaysCount-gigAppCompletedLastQuarter) / gigAppCompletedLastQuarter) * 100 || 0; 
    
    return {
      gigAppUsageCount,
      gigAppViewsCount,
      gigAppCompletedCount,
      gigAppUsageLast90DaysPercetange,
      gigAppViewsLast90DaysPercetange,
      gigAppCompletedLast90DaysPercetange
    }
  };
  
  return [gigAppData, setGigAppData, calculateGigappUsageCount]
}