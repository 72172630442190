import React, { useState } from 'react';
import css from '../GigAppTransactionPanel.module.css';
import Button from '../../Button/Button';
import { FormattedMessage } from 'react-intl';
import Modal from '../../Modal/Modal';
import { AI_COMBINED_TOPIC_SENTIMENT, AI_QUERY_REPORT, AI_REPORT_GENERATOR, AI_TRANSCRIPTION, AI_ANALYZE_RAW_QUANTITATIVE_DATA, AI_ANALYZE_RAW_QUANTITATIVE_CHAT, AI_CONTENT_ANALYSIS } from '../../../util/types';
import { OpenAIQueryReportForm, OpenAIReportIdeaGeneratorForm, OpenAITextPreProcessingForm, OpenAITranscriptionForm } from '../../../forms';
import { handleAnalyzeRawDataTransaction, handleQueryReportTransaction, handleReportIdeaTransaction, handleSentimentAnalysisTransaction, handleTranscriptionTransaction } from './helperFunction';
import moment from 'moment';
import { GIG_APPS_TRANSACTION_PAGE } from '../../../util/types';
import NamedLink from '../../NamedLink/NamedLink';
import { GIG_APPS_TRANSACTION_PAGE_BOX_TAB } from '../../../util/types';
import { useDispatch } from 'react-redux';
import SessionInProgressModal from './SessionInProgressModal';
import { getUserDetails } from '../../../util/destructorHelpers';
import LinesLoader from '../../LinesLoader/LinesLoader';
import FileView from '../../FileView/FileView';
import { manageDisableScrolling } from '../../../ducks/UI.duck';
import OpenAIAnalyzeRawQuantitativeDataForm from '../../../forms/OpenAIAnalyzeRawQuatitativeDataForm/OpenAIAnalyzeRawQuantitativeDataForm';
import OpenAIContentAnalysisForm from '../../../forms/OpenAIContentAnalysisForm/OpenAIContentAnalysisForm';
import { updateOpenAIData } from '../../../util/api';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routeConfiguration';

const GigAppHomePageSection = props => {
  const {
    currentTransaction,
    intl,
    customer,
    activeTab,
    onManageDisableScrolling,
    metadata,
    gigAppInputData,
    history,
    gigAppInputDataInProgress,
    currentUserEmail,
    currentUser,
    appName,
  } = props;

  const [gigAppModalState, setGigAppModalState] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openaiRequest, setOpenaiRequest] = useState(false);
  const [analyzeFileError, setAnalyzeFileError] = useState(false);
  const [appData, setAppData] = useState({step: 0})
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const currentUserFirstName = getUserDetails(currentUser)?.firstName;
  const currentUserName = getUserDetails(currentUser).fullName;
  const { boxFolder = {} } = metadata || {};
  const transactionId = currentTransaction?.id?.uuid;
  const { listing = {} } = currentTransaction || {};
  const { title, description } = listing?.attributes || {};
  const { appRoute } = listing?.attributes?.publicData || {};
  const isQueryOutputApp = [AI_REPORT_GENERATOR, AI_QUERY_REPORT, AI_ANALYZE_RAW_QUANTITATIVE_DATA].includes(appRoute); 

  function handleError(message) {
    setError(message);
    setOpenaiRequest(false);
    setTimeout(() => setError(false), 3000);
  }

  const { data, files, downloadURL } = gigAppModalState || {};

  const handleTransactionApps = () => {
    const defaultProps = {
      transactionId,
      boxFolder,
      gigAppInputData,
      setOpenaiRequest,
      handleError,
      setGigAppModalState,
      dispatch,
      setOpenSuccessModal,
      email: currentUserEmail, 
      firstName: currentUserFirstName
    }
    switch (appRoute) {
      case AI_TRANSCRIPTION:
        return (
          <div className={css.descriptionDetail}>
            <OpenAITranscriptionForm
              onSubmit={values =>
                handleTranscriptionTransaction({ values, ...defaultProps})
              }
              openaiRequest={openaiRequest}
            />
          </div>
        );
      case AI_REPORT_GENERATOR:
        return (
          <div className={css.descriptionDetail}>
            <OpenAIReportIdeaGeneratorForm
              onSubmit={values =>
                handleReportIdeaTransaction({ values, ...defaultProps})
              }
              openaiRequest={openaiRequest}
              isError={<p className={css.error}>{error}</p>}
            />
          </div>
        );
      case AI_QUERY_REPORT:
        return (
          <div className={css.descriptionDetail}>
            <OpenAIQueryReportForm
              onSubmit={values =>
                handleQueryReportTransaction({ appRoute, values, ...defaultProps })
              }
              openaiRequest={openaiRequest}
              isError={error}
            />
          </div>
        );
      case AI_COMBINED_TOPIC_SENTIMENT:
        return (
          <div className={css.descriptionDetail}>
            <OpenAITextPreProcessingForm
              appTitle={title}
              onSubmit={(values, form) => {
                handleSentimentAnalysisTransaction({values, ...defaultProps})
              }}
              isError={error}
              openaiRequest={openaiRequest}
              appRoute={appRoute}
              manageDisableScrolling={(componentId, disableScrolling) =>
                dispatch(manageDisableScrolling(componentId, disableScrolling))
              }
            />
          </div>
        );
      case AI_ANALYZE_RAW_QUANTITATIVE_DATA:
        return (
          <div className={css.descriptionDetail}>
            <OpenAIAnalyzeRawQuantitativeDataForm
              initialValues={{step:0}}
              appData={appData}
              appTitle={title}
              appRoute={appRoute}
              analyzeFileError={analyzeFileError}
              setAnalyzeFileError={setAnalyzeFileError}
              openaiRequest={openaiRequest}
              handleRedirectToGigAppsPage={() => {
                setAppData({step: 0})
                history.push(createResourceLocatorString('GigAppsPage', routeConfiguration(), {}));
              }}
              onSubmit={(values, form)=> {
                handleAnalyzeRawDataTransaction({...defaultProps, values, form , setAnalyzeFileError, firstName: currentUserFirstName})
              }}
              manageDisableScrolling={(componentId, disableScrolling) =>
                dispatch(manageDisableScrolling(componentId, disableScrolling))
              }
            />
          </div>
        );
      case AI_CONTENT_ANALYSIS:
        return (
          <div className={css.descriptionDetail}>
            <OpenAIContentAnalysisForm
              onSubmit={() => { }}
              transactionId={transactionId}
              appData={appData}
              boxFolder={boxFolder}
              currentUserEmail={currentUserEmail}
              currentUserName={currentUserName}
              firstName={currentUserFirstName}
              handleRedirectToGigAppsPage={() => {
                setAppData({step: 0})
                setGigAppModalState(false)
                history.push(createResourceLocatorString('GigAppsPage', routeConfiguration(), {}));
              }}
              appRoute={appRoute}
              storeModifiedQuestion={async (id, questions) => {
                return await updateOpenAIData({type: appRoute, id: id, data: questions, isTransaction: true})
              }}
              manageDisableScrolling={(componentId, disableScrolling) =>
                dispatch(manageDisableScrolling(componentId, disableScrolling))
              }
            />
          </div>
        )
    }
  };

  function getLinkParams(fileId){
    return isQueryOutputApp ? {
      id: transactionId,
      tab: appRoute===AI_ANALYZE_RAW_QUANTITATIVE_DATA ? AI_ANALYZE_RAW_QUANTITATIVE_CHAT : appRoute,
      fileId: fileId
    } : {
      id: transactionId,
      tab: GIG_APPS_TRANSACTION_PAGE_BOX_TAB,
    }
  }

  return (
    <div className={css.gigAppHomePageSection}>
      <div>
        <div className={css.gigAppInformation}>
          <div className={css.leftApp}>
            <h2 className={css.appTitle}>{title}</h2>
            <p>{description}</p>
          </div>
          <Button
            className={css.runNowButtonText}
            onClick={() => setGigAppModalState(true)}
          >
            <FormattedMessage id="GigAppHomePageSection.runNowButtonText" />
          </Button>
        </div>
        <div className={css.previousVersions}>
          <div className={css.previousVersionsHeading}>
            <FormattedMessage id="GigAppHomePageSection.previousVersions" />
          </div>
          <div className={css.viewPrevious}>
            <FormattedMessage id="GigAppHomePageSection.viewPreviousVersions" />
          </div>
        </div>
      </div>

      <Modal
        id="GigAppHomePageSection.folderModal"
        containerClassName={css.modal}
        isOpen={gigAppModalState}
        onClose={() => {
          setAppData({step: 0})
          setGigAppModalState(false)
        }}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        {gigAppModalState === true 
        ? handleTransactionApps()
        : (  
          <div className={css.inputContainer}>
            <p className={css.generatorTitle}>{appName}</p>
            <label>Uploaded Files</label>
            <div>
              {Array.isArray(files) && files.length && files?.map(file => (
                <FileView file={file} />
              ))}
            </div>
          </div>
        )}
      </Modal>

      <SessionInProgressModal
        history={history}
        openSuccessModal={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
        dispatch={dispatch}
      />
      <div className={css.tableData}>
        {gigAppInputDataInProgress ? (
          <LinesLoader />
        ) : (
          <table>
            <thead className={css.tableHeader}>
              <tr>
                <th><FormattedMessage id="GigAppHomePageSection.serialNumber" /></th>
                <th><FormattedMessage id="GigAppHomePageSection.gigAppDateText" /></th>
                <th><FormattedMessage id="GigAppHomePageSection.gigAppInputText" /></th>
                <th><FormattedMessage id="GigAppHomePageSection.gigAppOutputText" /></th>
                <th><FormattedMessage id="GigAppHomePageSection.gigAppActionText" /></th>
              </tr>
            </thead>
            <tbody className={css.tableBody}>
              <>
                {gigAppInputData?.length > 0 && gigAppInputData?.map(({ downloadURL, mongoEntry }, index) => {
                  const { isCompleted } = mongoEntry || {};
                  return (
                    <tr key={mongoEntry?._id}>
                      <td>{index + 1}</td>
                      <td>{moment(mongoEntry?.createdAt).format('DD MMM YYYY')}</td>
                      {isCompleted ? (
                        <>
                          <td className={css.tabletd} onClick={() => setGigAppModalState({ ...mongoEntry, downloadURL, index })}>
                            <FormattedMessage id="GigAppHomePageSection.gigAppInputText" />
                          </td>
                          <td className={css.tabletd}>
                          {appRoute===AI_ANALYZE_RAW_QUANTITATIVE_DATA ? 
                              (mongoEntry.data?.inputsUsed ?
                              <NamedLink
                                name={isQueryOutputApp ? 'GigAppsTransactionPageWithExtraParams' : GIG_APPS_TRANSACTION_PAGE}
                                params={getLinkParams(mongoEntry?.data?.threadId)}
                              >
                                <FormattedMessage id="GigAppHomePageSection.gigAppOutputText" />
                              </NamedLink>
                              :
                              <span onClick={()=>{
                                setAppData({step: mongoEntry.data.inputsUsed ? 3 : 2, id: mongoEntry._id, threadId: mongoEntry?.data?.threadId})
                                setGigAppModalState(true)
                              }}>Output</span>)
                            :
                            appRoute===AI_CONTENT_ANALYSIS ?
                              (mongoEntry.data?.isQuestionsReady ? 
                                <NamedLink
                                  name={GIG_APPS_TRANSACTION_PAGE}
                                  params={getLinkParams(mongoEntry?._id)}
                                >
                                  <FormattedMessage id="GigAppHomePageSection.gigAppOutputText" />
                                </NamedLink>
                                :
                                <span onClick={()=>{
                                  setAppData({ step: 3, id: mongoEntry._id })
                                  setGigAppModalState(true)
                                }}>Output</span>
                              )
                            :
                            <NamedLink
                              name={isQueryOutputApp ? 'GigAppsTransactionPageWithExtraParams' : GIG_APPS_TRANSACTION_PAGE}
                              params={getLinkParams(mongoEntry?._id)}
                            >
                              <FormattedMessage id="GigAppHomePageSection.gigAppOutputText" />
                            </NamedLink>
                          }
                          </td>
                          <td className={css.pointerEvents}>
                            <FormattedMessage id="GigAppHomePageSection.exportToPdfText" />
                          </td>
                        </>
                      ) : (
                        <td className={css.inprogress} colspan="3">
                          <FormattedMessage id="GigAppHomePageSection.inProgress" />
                        </td>
                      )}
                    </tr>
                  )
                })}
              </>
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default GigAppHomePageSection;
