import React, { useEffect, useState } from 'react'
import { fetchQueryReportData } from '../../util/api';
import css from './OpenAIAppsPanel.module.css'
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import Modal from '../Modal/Modal';
import FileView from '../FileView/FileView';

const ActiveSession = ({ email, redirectToForm, onManageDisableScrolling, appRoute }) => {
  const [activeSessions, setActiveSessions] = useState([]);
  const [openForm, setOpenForm] = useState(false);

  const ids = {
    "ai-content-analysis":"OpenAIContentAnalysisForm",
    "ai-research-instrument-coverage-indicator":"OpenAIResearchInstrumentForm",
    "ai-analyze-raw-quantitative-data": "OpenAIAnalyzeRawDataForm"
  }

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      const response = await fetchQueryReportData({ type: appRoute, email });

      if (isMounted && response.status === 'success') {
        setActiveSessions(response.data);
      }
    };

    if (email) fetchData();

    return () => {
      isMounted = false;
    };
  }, [appRoute, email, setActiveSessions]);

  const modalContent = (fileName, size, createdAt, session)=>{
    const data = session?.data
    switch (appRoute) {
      case "ai-analyze-raw-quantitative-data":
        return (
          <>
            <div className={css.stepsLabel}>
              <FormattedMessage id='OpenAIAnalyzeRawDataForm.subHeading' />
            </div>
            <FileView file={{name: data?.questionnaireFile?.name, size: data?.questionnaireFile?.size, date: session?.createdAt}}></FileView>
            <FileView file={{name: data?.xlsxFile?.name, size: data?.xlsxFile?.size, date: session?.createdAt}}></FileView>
          </>
        )
        break;
      case "ai-research-instrument-coverage-indicator":
        return (<>
          <div className={css.stepsLabel}>
            <FormattedMessage id='OpenAIResearchInstrumentForm.proposalDocumentTitle' />
          </div>
          {data?.file && <FileView file={{name: data?.file?.name, size: data?.file?.size, date: createdAt}}></FileView>}
        </>)
        break;
      default:
        return(
          <>
            <div className={css.stepsLabel}>
              <FormattedMessage id='OpenAIContentAnalysisForm.step2' />
            </div>
            <FileView file={{name: fileName, size, date: createdAt}}></FileView>
          </>
          )
        break;
    }
  }

  const RenderConditionalMessage = ({ condition, id, data }) => (
    <p 
      className={condition ? css.viewInput : css.processingText} 
      onClick={condition ? () => {
        if(appRoute==="ai-analyze-raw-quantitative-data" && data?.inputsUsed){
          redirectToForm(id,data.threadId)
        }else{
          redirectToForm(id)
        }
      } : () => {}}
    >
      <FormattedMessage id={condition ? 'ActiveSession.continueNextText' : 'ActiveSession.processingText'} />
    </p>
  );

  return (
    <div className={css.sessionsWrapper}>
      <h3 className={css.mainHeading}>
        <FormattedMessage id='ActiveSession.pendingSessionLabel' />
        <span className={css.subMainHeading}>(Last {appRoute==="ai-analyze-raw-quantitative-data" ? 5 : 60} days)</span>
      </h3>
      <div className={activeSessions?.length !== 0 ? css.sessionContainer : ''}>
        {activeSessions?.length !== 0 ? activeSessions.map((session, index) => {
          const {_id: id, underProcessing = false} = session || {};
          const {fileName = '', size = 0, createdAt = 0, isQuestionsReady, list, title} = session?.data || {};

          return (
            <div key={id} className={css.session}>
              <span className={css.date}>{moment(session.createdAt).format('DD MMM YYYY')}</span>
              {ids[appRoute ] && <p className={css.viewInput} onClick={() => setOpenForm(index)}>
                <FormattedMessage id='ActiveSession.viewInputText' />
              </p>}
              {appRoute === "ai-content-analysis" && (
                <>
                  {underProcessing ? (
                    <p className={css.processingText}>
                      <FormattedMessage id={'ActiveSession.underProcessingText'} />
                    </p>
                  ) : (
                    <RenderConditionalMessage condition={isQuestionsReady} id={id} />
                  )}
                </>
              )}
              {appRoute === "ai-research-instrument-coverage-indicator" && (
                <RenderConditionalMessage condition={list && list.length > 0} id={id} />
              )}
              {appRoute === "ai-questionnaire" && (
                <RenderConditionalMessage condition={!!title} id={id} />
              )}
              {appRoute === "ai-analyze-raw-quantitative-data" && (
                <RenderConditionalMessage condition={list && list.length > 0} id={id} data={session.data}/>
              )}
              {!!ids[appRoute] && (
                <Modal
                  id={ids[appRoute]}
                  containerClassName={css.viewContentAnalysisForm}
                  isOpen={openForm === index}
                  onClose={() => setOpenForm(false)}
                  usePortal
                  onManageDisableScrolling={onManageDisableScrolling}
                >
                  <div className={css.formContainer}>
                    <h2>
                      <FormattedMessage id={`${ids[appRoute]}.heading`} />
                    </h2>
                    {modalContent(fileName, size, createdAt, session)}
                  </div>
                </Modal>
              )}
            </div>
          )
        }) : (
          <p><FormattedMessage id='ActiveSession.noSessionMessage' /></p>
        )}
      </div>
    </div>
  )
}

export default ActiveSession