import React, { useEffect, useState } from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import * as validators from '../../util/validators';
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { Form, FieldTextInput, PrimaryButton, SecondaryButton, FieldCheckbox, IconCard, IconSpinner, Modal, SkeletonLoader } from '../../components';
import css from './OpenAIContentAnalysisForm.module.css';
import { addGigAppSession, apiBaseUrl, fetchQueryReportData, generateAppDataFromOpenAI, handleFileUpload, updateOpenAIData } from '../../util/api';
import FileView from '../../components/FileView/FileView';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import MappedQuestions from './MappedQuestions';
import axios from 'axios';


const ACCEPT_FILE = '.doc,.docx,application/pdf';
const languages = [
  { key: 'english', label: 'English' },
  { key: 'spanish', label: 'Spanish' },
  { key: 'french', label: 'French' },
  { key: 'german', label: 'German' },
  { key: 'italian', label: 'Italian' },
  { key: 'portuguese', label: 'Portuguese' },
  { key: 'dutch', label: 'Dutch' },
  { key: 'russian', label: 'Russian' },
  { key: 'chinese', label: 'Chinese' },
  { key: 'korean', label: 'Korean' },
];

//Alert Modal Types - 
const MINIMUM_QUESTION_PROCESS_ALERT = 'minimumQuestionProcessAlert';
const QUESTION_PROCESSING_ALERT = 'questionProcessingAlert';
const RESET_ALL_ALERT = 'resetAllAlert';

export const OpenAIContentAnalysisForm = props => {

  const InputFileComponent = ({ form, id, files, isMultiple }) => {
    const [error, setError] = useState(false);
    return (
      <div className={css.inputAttachment}>
        {files?.length ? files?.map(file => (
          <React.Fragment key={file.name}>
            <FileView file={file}>
              <span className={css.removeFile} onClick={() => form.change(id, {})}>
                <FormattedMessage id="ZohoSignForm.remove" />
              </span>
            </FileView>
          </React.Fragment>
        )) : (
          <>
            <Field
              id={id}
              name={id}
              accept={ACCEPT_FILE}
              type="file"
              multiple={isMultiple}
            >
              {fieldprops => {
                const { accept, input } = fieldprops;
                const { name, type, multiple } = input;
                const onChange = async (e) => {
                  const files = Object.values(e.target.files);
                  const filesCount = e.target.files.length

                  if (isMultiple && (filesCount < 2 || filesCount > 50)) {
                    setError(true)
                    setTimeout(() => setError(false), 3000)
                    return
                  }

                  form.change(id, Object.values(e.target.files))
                  form.change('gigAppInputFiles', files)
                };
                const inputProps = { accept, id: name, name, onChange, type, multiple };
                return <input {...inputProps} className={css.addImageInput} />
              }}
            </Field>
            <label htmlFor={id} className={css.attachmentLabel}>
              <IconCard brand="upload" />
              <span className={css.dragDrop}><FormattedMessage id="ZohoSign.dragAndDropUpload" /></span>
              <span className={css.docType}>
                <FormattedMessage id="ZohoSign.pdfDoc" />
              </span>
            </label>
            {error && (
              <div className={css.errorMessage}><FormattedMessage id="OpenAIContentAnalysisForm.errorMessage" /></div>
            )}
          </>
        )}
      </div>
    )
  }

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          pristine,
          updated,
          values,
          form,
          storeUserInput,
          storeModifiedQuestion,
          uniqueInputId,
          handleRedirectToGigAppsPage,
          currentUserEmail,
          appRoute,
          manageDisableScrolling,
          firstName,
          updateAppUsageData,
          boxFolder,
          appData,
          transactionId
        } = formRenderProps;
        const params = useParams();
        // const submitDisabled = !values.requirement || !values.budget || !values.timeline;
        const [questions, setQuestions] = useState([]);
        const [uploadInProgress, setUploadInProgress] = useState(false);
        const transactionTab = "gig-app"
        const [step, setStep] = useState(params.tab === transactionTab ? 1 : params?.id ? 3 : 1);
        const [error, setError] = useState(false);
        const [alertModal, setAlertModal] = useState(false);

        function handleError(message) {
          setError(message)
          setTimeout(() => setError(false), 3000)
        }

        function getSubmitDisabledValue() {
          switch (step) {
            case 1: return !values.selectedLanguage;
            case 2: return !values.discussionDocument;
            case 3: return false;
            case 4: return !values.transcripts
          }
        }

        function handleNextClick() {
          if (step === 2) handleDiscussionAttachment(values.discussionDocument[0], values.gigAppInputFiles[0])
          else if (step === 3) handleStoreUpdatedQuestion()
          else if (step === 4) handleTranscriptUpload(values.gigAppInputFiles)
          else setStep(step + 1)
        }

        async function handleDiscussionAttachment(file, inputFile) {
          try {
            if (!file?.name) return
            setUploadInProgress(true)

            const response = await handleFileUpload('get-parsed-document', { file }) // get document text

            if (response.status === 200 && response?.data) {
              const data = {
                fileName: file?.name,
                size: file?.size,
                createdAt: new Date().toISOString(),
                selectedLanguages: values.selectedLanguage,
                isQuestionsReady: false
              }
              
              let formData =  new FormData
              formData.append('type', appRoute)
              formData.append('firstName', firstName)
              formData.append('email', currentUserEmail)

              // Store session in mongo
              if(params.tab===transactionTab){
                const appSessionResponse = await addGigAppSession({ rootFolderId: boxFolder.rootFolderId, transactionId: params?.id, files: [{name: file?.name, size: file?.size}], data : data});
                formData.append('data',JSON.stringify({
                  ...data,
                  id: appSessionResponse?.sessionId,
                  text: response?.data,
                  transaction: params?.id
                }))
                formData.append('file',inputFile)
              }else{
                handleFileUpload('upload-gigapp-input-file-aws', { file: inputFile, email: currentUserEmail })
                await storeUserInput(data)
                //Get generated questions from uploaded document text
                formData.append('data',JSON.stringify({
                  ...data,
                  id: uniqueInputId,
                  text: response?.data
                }))
              }

              axios.post(`${apiBaseUrl()}/api/question-heading-generator`,
                formData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
              );
              setUploadInProgress(false)
              setAlertModal(QUESTION_PROCESSING_ALERT)

              if(params.tab !== transactionTab) updateAppUsageData()
            }
            else throw new Error('Erorr: Failed to upload. Try Again!');
          } catch (error) {
            handleError(error?.message)
            setUploadInProgress(false)
          }
        }

        async function handleStoreUpdatedQuestion() {
          setUploadInProgress(true)

          if (questions.length) {
            const modifiedQuestions = questions.map(item => {
              return { textFromDG: item?.originalQuestion, question: item?.modifiedQuestion }
            })

            const response = await storeModifiedQuestion(params.tab===transactionTab ? appData?.id : params?.id, modifiedQuestions, params?.tab===transactionTab ? true : false)
            if (response.status === 'success') {
              setUploadInProgress(false)
              setStep(4)
            }
          }
        }

        async function handleTranscriptUpload(inputFile) {
          setUploadInProgress(true)

          //update inprogress varaible to true in database
          await updateOpenAIData({ type: appRoute, id: params?.id })

          const files = values.transcripts;
          const formData = new FormData()
          formData.append('email', currentUserEmail)
          formData.append('sessionId', params?.tab===transactionTab ? appData?.id : params?.id)
          formData.append('firstName', firstName)
          formData.append('appRoute', appRoute)
          if(params?.tab===transactionTab){
            formData.append('transactionId',transactionId)
          }
          for (let key in files) {
            formData.append('files', files[key]);
          }

          axios.post(`${process.env.REACT_APP_DJANGO_BACKEND_API}/text_splitter`,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
          );
          inputFile.map(async (file)=>{
            await handleFileUpload('upload-gigapp-input-file-aws', { file: file, email: currentUserEmail })
          })
          
          setTimeout(() => {
            setUploadInProgress(false)
            setStep(5)
          }, 3000)
        }

        async function fetchData() {
          const response = await fetchQueryReportData({
            type: appRoute,
            email: currentUserEmail,
            id: params?.tab===transactionTab ? appData?.id : params?.id,
            isTransaction: params?.tab===transactionTab
          });
          if (response.status === 'success') {
            const data = response.data?.data;
            // if(data.reason !== 'Yes') setAlertModal(MINIMUM_QUESTION_PROCESS_ALERT)
            const modifiedQuestionArray = data.questions?.map(item => ({
              originalQuestion: typeof item === 'string' ? item : item?.textFromDG,
              generatedQuestion: typeof item === 'string' ? item : item?.question,
              modifiedQuestion: typeof item === 'string' ? item : item?.question,
            }))
            setQuestions(modifiedQuestionArray)
          }
        }


        useEffect(() => {
          if(params?.tab===transactionTab){
            if(appData?.step===3){
              setStep(3)
              fetchData()
            }
          }else{
            if (currentUserEmail && params?.id) fetchData()
          }
        }, [currentUserEmail,appData])

        async function handleResetAll() {
          fetchData()
          setAlertModal(false)
        }

        return (
          <Form onSubmit={handleSubmit}>
            <div className={classNames(step !== 3 && css.contentAnalysisForm)}>
              <div className={classNames(css.container, step === 3 && css.questionContainer)}>
                <div className={css.heading}>
                  <FormattedMessage id='OpenAIContentAnalysisForm.heading' />
                </div>
                {step === 1 ? (
                  <div>
                    <div className={css.stepsLabel}>
                      <FormattedMessage id='OpenAIContentAnalysisForm.step1' />
                    </div>
                    <div className={css.languageCheckbox}>
                      {languages.map(lan => (
                        <FieldCheckbox
                          key={lan.key}
                          id={lan.key}
                          name='selectedLanguage'
                          value={lan.key}
                          label={lan.label}
                        />
                      ))}
                    </div>
                  </div>
                ) : step === 2 ? (
                  <div>
                    <div className={css.stepsLabel}>
                      <FormattedMessage id='OpenAIContentAnalysisForm.step2' />
                    </div>
                    <InputFileComponent form={form} id='discussionDocument' files={values.discussionDocument} />
                  </div>
                ) : step === 3 ? (
                  <div>
                    <div className={css.stepsLabel}>
                      <FormattedMessage id='OpenAIContentAnalysisForm.step3' />
                    </div>
                    {questions?.length !== 0 ? (
                      <MappedQuestions questions={questions} setQuestions={setQuestions} manageDisableScrolling={manageDisableScrolling} appRoute={appRoute} />
                    ) : (
                      <div className={css.loader}>
                        <SkeletonLoader isMobile={true} />
                      </div>
                    )}
                  </div>
                ) : step === 4 ? (
                  <div>
                    <div className={css.stepsLabel}>
                      <FormattedMessage id='OpenAIContentAnalysisForm.step4' />
                    </div>
                    <InputFileComponent form={form} id='transcripts' files={values.transcripts} isMultiple={true} />
                  </div>
                ) : step === 5 && (
                  <div>
                    <div className={css.stepsLabel}>
                      <h3><FormattedMessage id='OpenAIContentAnalysisForm.successStepMessage' /></h3>
                    </div>
                  </div>
                )}

                <div className={classNames(css.buttomButtonWrapper, css.singleButtonWrapper)}>
                  {step === 5 ? (
                    <PrimaryButton
                      type='button'
                      className={css.submitButton}
                      onClick={handleRedirectToGigAppsPage}
                    >
                      <FormattedMessage id='OpenAIContentAnalysisForm.closeButtonLabel' />
                    </PrimaryButton>
                  ) : (
                    <>
                      {step === 3 && (
                        <SecondaryButton
                          type='button'
                          className={css.resetButton}
                          onClick={() => setAlertModal(RESET_ALL_ALERT)}
                        >
                          <FormattedMessage id='OpenAIContentAnalysisForm.resetButtonLabel' />
                        </SecondaryButton>
                      )}
                      <PrimaryButton
                        type='button'
                        className={css.submitButton}
                        disabled={getSubmitDisabledValue()}
                        onClick={handleNextClick}
                      >
                        {uploadInProgress ? (
                          <IconSpinner />
                        ) : (
                          <FormattedMessage
                            id={step === 4
                              ? 'OpenAIContentAnalysisForm.submitButtonLabel'
                              : 'OpenAIContentAnalysisForm.nextButtonLabel'
                            }
                          />
                        )}
                      </PrimaryButton>
                    </>
                  )}
                </div>
                {error && <p className={css.error}>{error}</p>}
              </div>
            </div>

            <Modal
              id="alertModal"
              contentClassName={css.containerClassName}
              isOpen={!!alertModal}
              onClose={() => setAlertModal(false)}
              onManageDisableScrolling={manageDisableScrolling}
            >
              <div className={css.alertModal}>
                {alertModal === MINIMUM_QUESTION_PROCESS_ALERT ? (
                  <p>
                    <FormattedMessage id='OpenAIContentAnalysisForm.minimumQuestionProcessAlert' values={{
                      contactEmail: <a href='mailto:admin@insightgig.com'>admin@insightgig.com</a>
                    }} />
                  </p>
                ) : alertModal === QUESTION_PROCESSING_ALERT ? (
                  <>
                    <h2 style={{ textAlign: 'center' }}><FormattedMessage id="OpenAiAppsPanel.successModalHeading" /></h2>
                    <p><FormattedMessage id='OpenAIContentAnalysisForm.questionProcessingAlertMessage' /></p>
                    <PrimaryButton
                      type='button'
                      className={css.closeButton}
                      onClick={handleRedirectToGigAppsPage}
                    >
                      <FormattedMessage id='OpenAIContentAnalysisForm.closeButtonLabel' />
                    </PrimaryButton>
                  </>
                ) : alertModal === RESET_ALL_ALERT ? (
                  <>
                    <h2 style={{ textAlign: 'center' }}><FormattedMessage id='OpenAIContentAnalysisForm.resetAllQuestionAlert' /></h2>
                    <p><FormattedMessage id='OpenAIContentAnalysisForm.resetAllQuestionWarning' /></p>
                    <div className={css.buttonWrapper}>
                      <div className={css.cancelButton} onClick={() => setAlertModal(false)}>
                        Cancel
                      </div>
                      <PrimaryButton
                        type='button'
                        className={css.submitButton}
                        onClick={handleResetAll}
                      >
                        Yes
                      </PrimaryButton>
                    </div>
                  </>
                ) : null}
              </div>
            </Modal>
          </Form>
        );
      }}
    />
  )
}


OpenAIContentAnalysisForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  //   updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(OpenAIContentAnalysisForm);