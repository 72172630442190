import React, { useEffect, useState } from 'react'
import { FormattedMessage } from '../../util/reactIntl';
import css from './FirmDashboardPanel.module.css'
import { updateFirmUserDetails } from '../../util/api';
import { useDispatch } from 'react-redux';
import { types as sdkTypes } from '../../util/sdkLoader';
import { queryUserReviews } from '../../containers/TransactionPage/TransactionPage.duck';
import { APPLIED_PRIVATE_JOBS, APPLIED_PUBLIC_JOBS, AVERAGERATING, COMMISSIONED_PROJECTS, COMPLETED_PROJECTS, COMPLETED_RECRUITMENTS, FIRM_DASHBOARD_PAGE, LOGINS, ONGOING_RECRUITMENTS, PRIVATE_JOBS, PRIVATE_PROJECTS, PUBLIC_JOBS, PUBLIC_PROJECTS, UNDERWAY_PROJECTS, USERS } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import PaginationLinks from '../PaginationLinks/PaginationLinks';
import { calculateFirmDashboardUserRating, calculatePrivateProposalCounts, calculateProjectCommisionedCounts, calculateProjectCompletedCounts, calculatePublicPostedProjects, calculateProjectUnderwayCounts, calculatePublicProposalCounts, firmDashboardUserLoginDetails, getProjectChatData, getUserChatData, calculatePrivateSharedProjects, calculatePublicPostedJobs, calculatePrivatelySharedJobs, calculateongoingRecruitments, calculateCompletedRecruitments, calculatePublicAppliedJobs, calculatePrivatelyAppliedJobs } from '../../util/destructorHelpers';
import ManageListingCard from '../ManageListingCard/ManageListingCard';
const { UUID } = sdkTypes;

import UserStatsPanel from './UserStatsPanel';
import UserStatsModals from './UserStatsModals';
import IconCard from '../IconCard/IconCard';
import { appsData } from '../GigAppsPanel/appsData';
import useFetchGigAppData from '../../hooks/useFetchGigAppData';

const emptySpace = '-';
const gigApps = [{name: 'All GigApps', route: 'all'}, ...appsData.map((app) => ({name: app.name, route: app.route}))]

const FirmDashboardPanel = (props) => {
  const { firmName, firmId, state, isClient } = props;
  const { currentPageResultIds, pagination, firm, fetchFirmRequest } = state.FirmDashBoardPage;
  const listings = getListingsById(state, currentPageResultIds);
  const userDetails = firm?.newFirmUserDetails;
  const [openTabContent, setOpenTabContent] = useState([])
  const [modalType, setModalType] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [gigAppData, setGigAppData, calculateGigappUsageCount] =  useFetchGigAppData(userDetails)

  const {
    last30DaysLoginCount,
    percentageLast30Days,
    percentageNewUserLastQuarter,
    totalUsers
  } = !!firm?._id && firmDashboardUserLoginDetails(userDetails);

  const { averageRating, lastQuarterPercentage } = !!firm?._id && calculateFirmDashboardUserRating(userDetails);
  const {
    publiclyPostedProjects,
    last90DaysPercentage: publicPostedProjectPercentage
  } = !!firm?._id && calculatePublicPostedProjects(userDetails);

  const {
    publiclyPostedJobs,
    last90DaysPercentage: publicPostedJobsPercentage
  } = !!firm?._id && calculatePublicPostedJobs(userDetails);
  
  const {
    publiclyAppliedJobs,
    last90DaysPercentage: publiclyAppliedJobsPercentage
  } = !!firm?._id && calculatePublicAppliedJobs(userDetails);

  const {
    privatelySharedJobs,
    last90DaysPercentage: privatelySharedJobsPercentage
  } = !!firm?._id && calculatePrivatelySharedJobs(userDetails);

  const {
    privatelyAppliedJobs,
    last90DaysPercentage: privatelyAppliedJobsPercentage
  } = !!firm?._id && calculatePrivatelyAppliedJobs(userDetails);

  const {
    last90DaysRecruitmentCount,
    last90DaysPercentage: ongoingRecruitmentsPercentage
  } = !!firm?._id && calculateongoingRecruitments(userDetails);

  const {
    last90DaysCompletedRecruitmentCount,
    last90DaysPercentage: completedRecruitmentsPercentage
  } = !!firm?._id && calculateCompletedRecruitments(userDetails);

  const {
    privatelySharedProjects,
    last90DaysPercentage: privateSharedProjectPercentage
  } = !!firm?._id && calculatePrivateSharedProjects(userDetails);

  const {
    last90DaysPercentage: lastQuarterPrivateProposalPercentage,
    totalPrivateProposals
  } = !!firm?._id && calculatePrivateProposalCounts(userDetails);

  const {
    last90DaysPercentage: lastQuarterPublicProposalPercentage,
    totalPublicProposals
  } = !!firm?._id && calculatePublicProposalCounts(userDetails);

  const {
    last90DaysPercentage: lastQuarterProjectUnderwayPercentage,
    totalUnderwayProjects
  } = !!firm?._id && calculateProjectUnderwayCounts(userDetails)

  const {
    totalCommissionedProjects,
    last90DaysPercentage: last90DaysProjectCommissionedPercentage
  } = !!firm?._id && calculateProjectCommisionedCounts(userDetails);

  const {
    totalCompletedProjects,
    last90DaysPercentage: last90DaysProjectCompletedPercentage
  } = !!firm?._id && calculateProjectCompletedCounts(userDetails);

  const {
    gigAppUsageCount, 
    gigAppViewsCount, 
    gigAppCompletedCount, 
    gigAppUsageLast90DaysPercetange, 
    gigAppViewsLast90DaysPercetange, 
    gigAppCompletedLast90DaysPercetange
  } = gigAppData;

  const page = 1;
  const paginationLinks =
    listings.length >= 10 ? (
      <PaginationLinks
        className={css.pagination}
        pageName={FIRM_DASHBOARD_PAGE}
        pageSearchParams={{ page }}
        pagination={pagination}
      />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const sortedUsers = !!firm?._id && userDetails.sort((a, b) => {
    if (a.isAdmin === 1 && b.isAdmin !== 1) {
      return -1; // a comes before b
    } else if (a.isAdmin !== 1 && b.isAdmin === 1) {
      return 1; // b comes before a
    } else {
      return 0; // preserve the original order
    }
  });

  const IconChart = ({modalType}) => (
    <span className={css.openPopup} onClick={() => setModalType(modalType)}>
      <IconCard brand='chart'/>  
    </span>    
  )

  const handleOpenIcon = (tab) => {
    setOpenTabContent((prevOpenTabs) => 
    prevOpenTabs.includes(tab) 
      ? prevOpenTabs.filter(item => item !== tab) 
      : [...prevOpenTabs, tab]
    );
  }

  const handleFilterChange = (e) => {
    const filterValue = e.target.value === 'all' ? '' : e.target.value;
    const response = calculateGigappUsageCount(userDetails, filterValue);
    setGigAppData(response);
    setSelectedFilter(filterValue)
  }

  const getPercentage = (amount) => {
    return amount ? (amount === Infinity ? 'Growth comparison not possible' : amount.toFixed(0).toLocaleString() + "%")  : "0%";  
  };
  
  const getTextColor = (amount)=>{
    return getPercentage(amount)==="Growth comparison not possible" ? css.greyColor : (amount === Infinity || amount === 0 || !amount) ? css.blackColor : (amount < 0 ? css.redColor : css.greenColor)
  }

  return (
    <div className={css.firmDashboardPanel}>
      <div className={css.userContainer}>
        <div className={css.headerSection}>
          <h3>Users and Logins</h3>
          <span className={css.icon} onClick={() => handleOpenIcon('user')}>
            <IconCard brand={openTabContent.includes('user') ? 'dropArrowUp' : 'dropDown'} />
          </span>
        </div>
        <div className={css.cardWrapper}>
          <div className={css.cardBox}>
            <IconChart modalType={USERS}/>
            <div className={css.cardName}>
              <FormattedMessage id="FirmDashboardPanel.users" />
            </div>
            <div className={css.countNumber}>{totalUsers <= 0 ? emptySpace : totalUsers}</div>
            <div className={css.quarterPercent}>
              <span className={getTextColor(percentageNewUserLastQuarter)}>{getPercentage(percentageNewUserLastQuarter)}</span>
              {getPercentage(percentageNewUserLastQuarter) !== "Growth comparison not possible" && <FormattedMessage id="FirmDashboardPanel.fromPreviousQuarter" />}
            </div>
          </div>
          <div className={css.cardBox}>
            <IconChart modalType={LOGINS}/>
            <div className={css.cardName}>
              <FormattedMessage id="FirmDashboardPanel.logIns" />
              <div className={css.daysTime}>
                <FormattedMessage id="FirmDashboardPanel.inLastDays" />
              </div>
            </div>
            <div className={css.countNumber}>{last30DaysLoginCount <= 0 ? emptySpace : last30DaysLoginCount}</div>
            <div className={css.quarterPercent}>
              <span className={getTextColor(percentageLast30Days)}>{getPercentage(percentageLast30Days)}</span>
              {getPercentage(percentageLast30Days) !== "Growth comparison not possible" && <FormattedMessage id="FirmDashboardPanel.fromPreviousQuarter" />}
            </div>
          </div>
        </div>
        {openTabContent.includes('user') && (
          <UserStatsPanel sortedUsers={sortedUsers} isClient={isClient} firmName={firmName} isUserTable={true}/>
        )}
      </div>
      <div className={css.userContainer}>
        <div className={css.headerSection}>
          <h3>Ad Hoc Projects</h3>
          <span className={css.icon} onClick={() => handleOpenIcon('project')}>
            <IconCard brand={openTabContent.includes('project') ? 'dropArrowUp' : 'dropDown'} />
          </span>
        </div>
        <div className={css.cardWrapper}>
          <div className={css.cardBox}>
            <IconChart modalType={PUBLIC_PROJECTS}/>
            {isClient ? (
              <>
                <div className={css.cardName}>
                  <FormattedMessage id="FirmDashboardPanel.publiclyPostedProjects" />
                </div>
                <div className={css.countNumber}>{publiclyPostedProjects <= 0 ? emptySpace : publiclyPostedProjects}</div>
                <div className={css.quarterPercent}>
                  <span className={getTextColor(publicPostedProjectPercentage)}>{getPercentage(publicPostedProjectPercentage)}</span>
                  {getPercentage(publicPostedProjectPercentage) !== "Growth comparison not possible" && <FormattedMessage id="FirmDashboardPanel.fromPreviousQuarter" />}
                </div>
              </>
            ) : (
              <>
                <div className={css.cardName}>
                  <FormattedMessage id="FirmDashboardPanel.bidsForPubliclyPostedProjects" />
                </div>
                <div className={css.countNumber}>{totalPublicProposals <= 0 ? emptySpace : totalPublicProposals}</div>
                <div className={css.quarterPercent}>
                  <span className={getTextColor(lastQuarterPublicProposalPercentage)}>{getPercentage(lastQuarterPublicProposalPercentage)}</span>
                  {getPercentage(lastQuarterPublicProposalPercentage) !== "Growth comparison not possible" && <FormattedMessage id="FirmDashboardPanel.fromPreviousQuarter" />}
                </div>
              </>
            )
            }
          </div>
          <div className={css.cardBox}>
            <IconChart modalType={PRIVATE_PROJECTS}/>
            {isClient ? (
              <>
                <div className={css.cardName}>
                  <FormattedMessage id="FirmDashboardPanel.privatelySharedProjects" />
                </div>
                <div className={css.countNumber}>{privatelySharedProjects <= 0 ? emptySpace : privatelySharedProjects}</div>
                <div className={css.quarterPercent}>
                  <span className={getTextColor(privateSharedProjectPercentage)}>{getPercentage(privateSharedProjectPercentage)}</span>
                  {getPercentage(privateSharedProjectPercentage) !== "Growth comparison not possible" && <FormattedMessage id="FirmDashboardPanel.fromPreviousQuarter" />}
                </div>
              </>
            ) : (
              <>
                <div className={css.cardName}>
                  <FormattedMessage id="FirmDashboardPanel.bidsForPrivatelySharedProjects" />
                </div>
                <div className={css.countNumber}>{totalPrivateProposals <= 0 ? emptySpace : totalPrivateProposals}</div>
                <div className={css.quarterPercent}>
                  <span className={getTextColor(lastQuarterPrivateProposalPercentage)}>{getPercentage(lastQuarterPrivateProposalPercentage)}</span>
                  <FormattedMessage id="FirmDashboardPanel.fromPreviousQuarter" />
                </div>
              </>)
            }
          </div>
          <div className={css.cardBox}>
            <IconChart modalType={UNDERWAY_PROJECTS}/>
            <div className={css.cardName}>
              <FormattedMessage id="FirmDashboardPanel.projectsUnderway" />
            </div>
            <div className={css.countNumber}>{totalUnderwayProjects <= 0 ? emptySpace : totalUnderwayProjects}</div>
            <div className={css.quarterPercent}>
              <span className={getTextColor(lastQuarterProjectUnderwayPercentage)}>{getPercentage(lastQuarterProjectUnderwayPercentage)}</span>
              {getPercentage(lastQuarterProjectUnderwayPercentage) !== "Growth comparison not possible" && <FormattedMessage id="FirmDashboardPanel.fromPreviousQuarter" />}
            </div>
          </div>
          <div className={css.cardBox}>
            <IconChart modalType={COMMISSIONED_PROJECTS}/>
            <div className={css.cardName}>
              <FormattedMessage id="FirmDashboardPanel.projectsCommissioned" />
              <div className={css.daysTime}>
                <FormattedMessage id="FirmDashboardPanel.lastThreeMonths" />
              </div>
            </div>
            <div className={css.countNumber}>{totalCommissionedProjects <= 0 ? emptySpace : totalCommissionedProjects}</div>
            <div className={css.quarterPercent}>
              <span className={getTextColor(last90DaysProjectCommissionedPercentage)}>{getPercentage(last90DaysProjectCommissionedPercentage)}</span>
              {getPercentage(last90DaysProjectCommissionedPercentage) !== "Growth comparison not possible" && <FormattedMessage id="FirmDashboardPanel.fromPreviousQuarter" />}
            </div>
          </div>
          <div className={css.cardBox}>
            <IconChart modalType={COMPLETED_PROJECTS}/>
            <div className={css.cardName}>
              <FormattedMessage id="FirmDashboardPanel.projectsCompleted" />
              <div className={css.daysTime}>
                <FormattedMessage id="FirmDashboardPanel.lastThreeMonths" />
              </div>
            </div>
            <div className={css.countNumber}>{totalCompletedProjects <= 0 ? emptySpace : totalCompletedProjects}</div>
            <div className={css.quarterPercent}>
              <span className={getTextColor(last90DaysProjectCompletedPercentage)}>{getPercentage(last90DaysProjectCompletedPercentage)}</span>
              {getPercentage(last90DaysProjectCompletedPercentage) !== "Growth comparison not possible" && <FormattedMessage id="FirmDashboardPanel.fromPreviousQuarter" />}
            </div>
          </div>
          <div className={css.cardBox}>
            <IconChart modalType={AVERAGERATING}/>
            <div className={css.cardName}>
              <FormattedMessage id="FirmDashboardPanel.averageRating" />
            </div>
            <div className={css.countNumber}>{averageRating <= 0 ? emptySpace : averageRating?.toFixed(2)}</div>
            <div className={css.quarterPercent}>
              <span className={getTextColor(lastQuarterPercentage)}>{getPercentage(lastQuarterPercentage)}</span>
              {getPercentage(lastQuarterPercentage) !== "Growth comparison not possible" && <FormattedMessage id="FirmDashboardPanel.fromPreviousQuarter" />}
            </div>
          </div>
        </div>
        {openTabContent.includes('project') && (
          <UserStatsPanel sortedUsers={sortedUsers} isClient={isClient} firmName={firmName}/>
        )}
      </div>
      
      <div className={css.userContainer}>
        <div className={css.headerSection}>
          <h3>Employment / Secondment</h3>
          <span className={css.icon} onClick={() => handleOpenIcon('employment')}>
            <IconCard brand={openTabContent.includes('employment') ? 'dropArrowUp' : 'dropDown'} />
          </span>
        </div>
        {isClient ?
          <div className={css.cardWrapper}>
            <div className={css.cardBox}>
              <IconChart modalType={PUBLIC_JOBS}/>
              <div className={css.cardName}>
                <FormattedMessage id="FirmDashboardPanel.publiclyPostedJobs" />
              </div>
              <div className={css.countNumber}>{publiclyPostedJobs <= 0 ? emptySpace : publiclyPostedJobs}</div>
              <div className={css.quarterPercent}>
                <span className={getTextColor(publicPostedJobsPercentage)}>{getPercentage(publicPostedJobsPercentage)}</span>
                {getPercentage(publicPostedJobsPercentage) !== "Growth comparison not possible" && <FormattedMessage id="FirmDashboardPanel.fromPreviousQuarter" />}
              </div>
            </div>
            <div className={css.cardBox}>
              <IconChart modalType={PRIVATE_JOBS}/>
              <div className={css.cardName}>
                <FormattedMessage id="FirmDashboardPanel.privatelySharedJobs" />
              </div>
              <div className={css.countNumber}>{privatelySharedJobs <= 0 ? emptySpace : privatelySharedJobs}</div>
              <div className={css.quarterPercent}>
                <span className={getTextColor(privatelySharedJobsPercentage)}>{getPercentage(privatelySharedJobsPercentage)}</span>
                {getPercentage(privatelySharedJobsPercentage) !== "Growth comparison not possible" && <FormattedMessage id="FirmDashboardPanel.fromPreviousQuarter" />}
              </div>
            </div>
            <div className={css.cardBox}>
              <IconChart modalType={ONGOING_RECRUITMENTS}/>
              <div className={css.cardName}>
                <FormattedMessage id="FirmDashboardPanel.recruitmentsUnderway" />
              </div>
              <div className={css.daysTime}>
                <FormattedMessage id="FirmDashboardPanel.lastThreeMonths" />
              </div>
              <div className={css.countNumber}>{last90DaysRecruitmentCount <= 0 ? emptySpace : last90DaysRecruitmentCount}</div>
              <div className={css.quarterPercent}>
                <span className={getTextColor(ongoingRecruitmentsPercentage)}>{getPercentage(ongoingRecruitmentsPercentage)}</span>
                {getPercentage(ongoingRecruitmentsPercentage) !== "Growth comparison not possible" && <FormattedMessage id="FirmDashboardPanel.fromPreviousQuarter" />}
              </div>
            </div>
            <div className={css.cardBox}>
              <IconChart modalType={COMPLETED_RECRUITMENTS}/>
              <div className={css.cardName}>
                <FormattedMessage id="FirmDashboardPanel.recruitmentsDone" />
              </div>
              <div className={css.daysTime}>
                <FormattedMessage id="FirmDashboardPanel.lastThreeMonths" />
              </div>
              <div className={css.countNumber}>{last90DaysCompletedRecruitmentCount <= 0 ? emptySpace : last90DaysCompletedRecruitmentCount}</div>
              <div className={css.quarterPercent}>
                <span className={getTextColor(completedRecruitmentsPercentage)}>{getPercentage(completedRecruitmentsPercentage)}</span>
                {getPercentage(completedRecruitmentsPercentage) !== "Growth comparison not possible" && <FormattedMessage id="FirmDashboardPanel.fromPreviousQuarter" />}
              </div>
            </div>
          </div>
          :
          <div className={css.cardWrapper}>
            <div className={css.cardBox}>
              <IconChart modalType={APPLIED_PUBLIC_JOBS}/>
              <div className={css.cardName}>
                <FormattedMessage id="FirmDashboardPanel.applicationsForPubliclyPostedJobs" />
              </div>
              <div className={css.countNumber}>{publiclyAppliedJobs <= 0 ? emptySpace : publiclyAppliedJobs}</div>
              <div className={css.quarterPercent}>
                <span className={getTextColor(publiclyAppliedJobsPercentage)}>{getPercentage(publiclyAppliedJobsPercentage)}</span>
                {getPercentage(publiclyAppliedJobsPercentage) !== "Growth comparison not possible" && <FormattedMessage id="FirmDashboardPanel.fromPreviousQuarter" />}
              </div>
            </div>
            <div className={css.cardBox}>
              <IconChart modalType={APPLIED_PRIVATE_JOBS}/>
              <div className={css.cardName}>
                <FormattedMessage id="FirmDashboardPanel.applicationsForPrivatelySharedJobs" />
              </div>
              <div className={css.countNumber}>{privatelyAppliedJobs <= 0 ? emptySpace : privatelyAppliedJobs}</div>
              <div className={css.quarterPercent}>
                <span className={getTextColor(privatelyAppliedJobsPercentage)}>{getPercentage(privatelyAppliedJobsPercentage)}</span>
                {getPercentage(privatelyAppliedJobsPercentage) !== "Growth comparison not possible" && <FormattedMessage id="FirmDashboardPanel.fromPreviousQuarter" />}
              </div>
            </div>
          </div>
        }
        {openTabContent.includes('employment') && (
          <UserStatsPanel sortedUsers={sortedUsers} isClient={isClient} firmName={firmName} isEmploymentTable={true}/>
        )}
      </div>
      <div className={css.userContainer}>
        <div className={css.headerSection}>
          <h3>GigApps</h3>
          <span className={css.icon} onClick={() => handleOpenIcon('gigapp')}>
            <IconCard brand={openTabContent.includes('gigapp') ? 'dropArrowUp' : 'dropDown'} />
          </span>
        </div>
        <div className={css.gigAppsFilterButton}>
          <select onChange={handleFilterChange}>
            {gigApps.map(app => (
              <option key={app.route} value={app.route}>{app.name}</option>
            ))}
          </select>
        </div>
        <div className={css.cardWrapper}>
          <div className={css.cardBox}>
            {/* <IconChart modalType={USERS}/> */}
            <div className={css.cardName}>
              <FormattedMessage id="FirmDashboardPanel.detailsPageLabel" />(views)
            </div>
            <div className={css.countNumber}>{gigAppViewsCount <= 0 ? emptySpace : gigAppViewsCount}</div>
            <div className={css.quarterPercent}>
              <span className={getTextColor(gigAppViewsLast90DaysPercetange)}>
                {getPercentage(gigAppViewsLast90DaysPercetange)}
              </span>
              <FormattedMessage id="FirmDashboardPanel.fromPreviousQuarter" />
            </div>
          </div>
          <div className={css.cardBox}>
            {/* <IconChart modalType={LOGINS}/> */}
            <div className={css.cardName}>
              <FormattedMessage id="FirmDashboardPanel.appInitiatedLabel" />
            </div>
            <div className={css.countNumber}>{gigAppUsageCount <= 0 ? emptySpace : gigAppUsageCount}</div>
            <div className={css.quarterPercent}>
              <span className={getTextColor(gigAppUsageLast90DaysPercetange)}>
                {getPercentage(gigAppUsageLast90DaysPercetange)}
              </span>
              <FormattedMessage id="FirmDashboardPanel.fromPreviousQuarter" />
            </div>
          </div>
          <div className={css.cardBox}>
            {/* <IconChart modalType={LOGINS}/> */}
            <div className={css.cardName}>
              <FormattedMessage id="FirmDashboardPanel.outputGeneratedLabel" />
            </div>
            <div className={css.countNumber}>{gigAppCompletedCount <= 0 ? emptySpace : gigAppCompletedCount}</div>
            <div className={css.quarterPercent}>
              <span className={getTextColor(gigAppCompletedLast90DaysPercetange)}>
                {getPercentage(gigAppCompletedLast90DaysPercetange)}
              </span>
              <FormattedMessage id="FirmDashboardPanel.fromPreviousQuarter" />
            </div>
          </div>
        </div>
        {openTabContent.includes('gigapp') && (
          <UserStatsPanel sortedUsers={sortedUsers} isClient={isClient} firmName={firmName} isGigappTable={true} selectedFilter={selectedFilter}/>
        )}
      </div>
      {isClient &&
        <div className={css.postedProject}>
          <div className={css.projectHeading}>
            <FormattedMessage id="FirmDashboardPanel.publiclyPostedProjectsCount" values={{ count: listings?.length }} />
          </div>
          <div className={css.projectList}>
            {listings?.length ? listings?.map(l => {
              return (
                <ManageListingCard
                  className={css.listingCard}
                  key={l.id.uuid}
                  listing={l}
                  renderSizes={cardRenderSizes}
                  currentPage={FIRM_DASHBOARD_PAGE}
                />
              )
            }) : null}
          </div>
          {paginationLinks}
        </div>
      }
      <UserStatsModals modalType={modalType} setModalType={setModalType} userDetails={userDetails} isClient={isClient} />
    </div>
  )
}

export default FirmDashboardPanel