import React, { useEffect, useState, forwardRef } from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
// These relative imports need to point to correct directories
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import {
  Form, FieldTextInput, PrimaryButton, SecondaryButton, IconCard, FieldCheckbox, FieldSelect, IconSpinner
} from '../../components';
import css from './OpenAIDiscussionGuideForm.module.css';
import { handleFileUpload } from '../../util/api';
import FileView from '../../components/FileView/FileView';
import classNames from 'classnames';

const ACCEPT_FILE = '.doc,.docx,application/msword,application/pdf';
const FILE_SIZE_LIMIT = 25 * 1000 * 1000;

const fields = [
  { key: 'selectAllField', label: 'Select all' },
  { key: 'researchField', label: 'Research Objective' },
  { key: 'interviewTypeField', label: 'Interview Type' },
  { key: 'targetField', label: 'Target Segment' },
  { key: 'interviewLengthField', label: 'Interview Length' },
  { key: 'topicAreaField', label: 'Topic Areas' },
  { key: 'additionalInstructionsField', label: 'Additional Instructions' },
]


export const OpenAIDiscussionGuideForm = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          pristine,
          updated,
          formId,
          updateError,
          values,
          form,
          openaiRequest,
          initialText,
        } = formRenderProps;

        const [file, setFile] = useState();
        const [toggle, setToggle] = useState(false);
        const [selectedFields, setSelectedFields] = useState([]);
        const [isLoading, setIsLoading] = useState(false);
        const { targetField, researchField, interviewTypeField } = values || {};
        const submitDisabled = !toggle
          ? (!researchField || !interviewTypeField || !targetField)
          : (selectedFields.includes('selectAllField') ? false : (pristine && !file))

        const researchPlaceholder = intl.formatMessage({ id: "OpenAIDiscussionGuideForm.researchPlaceholder" });
        const interviewTypeLabel = intl.formatMessage({ id: "OpenAIDiscussionGuideForm.interviewTypeLabel" });
        const targetPlaceholder = intl.formatMessage({ id: "OpenAIDiscussionGuideForm.targetPlaceholder" });
        const interviewPlaceholder = intl.formatMessage({ id: "OpenAIDiscussionGuideForm.interviewPlaceholder" });
        const topicAreaPlaceholder = intl.formatMessage({ id: "OpenAIDiscussionGuideForm.topicAreaPlaceholder" });
        const addtionalInstructionPlaceholder = intl.formatMessage({ id: "OpenAIDiscussionGuideForm.addtionalInstructionPlaceholder" });

        useEffect(() => {
          if (initialText) {
            form.reset();
          }
        }, [initialText])

        function handleToggle() {
          if (toggle) {
            setSelectedFields([])
            setToggle(false)
          }
          else {
            setToggle(true)
          }
        }

        async function handleAttachment(file) {
          setIsLoading(true)
          try {
            const response = await handleFileUpload('get-parsed-document', { file })

            if (response?.data) {
              setFile(file)
              form.change('file',file)
              if (response?.data) Object.assign(values, { attachmentText: response?.data })
              setIsLoading(false)
            }
          }
          catch (error) {
            setIsLoading(false)
          }
        }

        function handleCheckboxSelect(e) {
          e.stopPropagation();
          const fieldId = e.target.id;
          if (fieldId === 'selectAllField') {
            e.target.checked
              ? setSelectedFields(fields.map(field => {
                delete values[field.key]
                return field.key
              }))
              : setSelectedFields([])
          }
          else {
            if (selectedFields.includes(fieldId)) {
              const modifiedSelectedFields = [...selectedFields].filter(field => field !== fieldId)
              setSelectedFields(modifiedSelectedFields)
            }
            else {
              delete values[e.target.value]
              setSelectedFields([...selectedFields, fieldId])
            }
          }
        }

        return (
          <Form className={css.contractForm} onSubmit={(e) => {
            e.preventDefault();
            let params = {};

            if (!toggle) params = { ...values, attachmentText: null }
            else {
              const notSelectedFields = fields.map(field => field.key).filter(field => !selectedFields.includes(field))
              notSelectedFields?.length && notSelectedFields?.forEach(field => params[field] = values[field])
              params.attachmentText = values.attachmentText
            }

            handleSubmit(params)
          }}>
            <div className={css.container}>
              <div className={css.briefGenerator}>
                <span className={css.generatorTitle}>
                  <FormattedMessage id="OpenAIDiscussionGuideForm.heading" />
                </span>
              </div>
              <div className={css.documentSwitcher}>
                <label>
                  <FormattedMessage id="OpenAIDiscussionGuideForm.documentSwitcherText" />
                </label>
                <div className={classNames(css.switcherWrapper, toggle && css.toggled)} onClick={handleToggle}>
                  <div className={css.ball}></div>
                </div>
              </div>
              {toggle && (
                <div className={css.attachment}>
                  {file ? (
                    <div className={css.file}>
                      <FileView file={file}>
                        <span className={css.close} onClick={() => {
                          setFile(null)
                          setSelectedFields([])
                          }}>
                          <IconCard brand='cross' />
                        </span>
                      </FileView>
                      <p><FormattedMessage id='OpenAIDiscussionGuideForm.selectFieldText' /></p>
                      <div className={css.checkboxWrapper}>
                        {fields.map(({ key, label }) => {
                          const isChecked = selectedFields.includes(key);
                          return (
                            <div key={key}>
                              <label htmlFor={key}>{label}</label>
                              <input
                                type='checkbox'
                                className={css.checkbox}
                                id={key}
                                name='selectFields'
                                value={key}
                                checked={isChecked}
                                onChange={handleCheckboxSelect}
                              />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                    : (
                      <Field
                        id="file"
                        name="file"
                        accept={ACCEPT_FILE}
                        type="file"
                      >
                        {fieldprops => {
                          const { accept, input, disabled: fieldDisabled } = fieldprops;
                          const { name, type } = input;
                          const onChange = e => {
                            const file = e.target.files[0];
                            if (file && file.name && file.size < FILE_SIZE_LIMIT) {
                              handleAttachment(file)
                            }
                          };

                          const inputProps = { accept, id: name, name, onChange, type };
                          return (
                            <div>
                              {fieldDisabled ? null : (
                                <input {...inputProps} className={css.addImageInput} />
                              )}
                              <label type='button' htmlFor={name} className={css.attachmentLabel}>
                                {isLoading ? <IconSpinner strokeColor='#0B96F5' /> : <IconCard brand="upload" />}
                                <span className={css.dragDrop}><FormattedMessage id="ZohoSign.dragAndDropUpload" /></span>
                                <span className={css.docType}>
                                  <FormattedMessage id="ZohoSign.pdfDoc" />
                                </span>
                              </label>
                            </div>
                          );
                        }}
                      </Field>
                    )}
                </div>
              )}
              {!selectedFields.includes('researchField') && <div className={css.row}>
                <label><FormattedMessage id="OpenAIDiscussionGuideForm.researchLabel" /></label>
                <FieldTextInput
                  className={css.textAreaBox}
                  type="textarea"
                  id='researchField'
                  name="researchField"
                  placeholder={researchPlaceholder}
                />
              </div>}
              {!selectedFields.includes('interviewTypeField') && <div className={css.row}>
                <FieldSelect
                  id='interviewTypeField'
                  name='interviewTypeField'
                  className={css.fieldSelector}
                  label={interviewTypeLabel}
                >
                  <option>{'Select option'}</option>
                  <option value='In-Depth Interviews'>In-Depth Interviews</option>
                  <option value='Focus Group Discussions'>Focus Group Discussions</option>
                </FieldSelect>
              </div>}
              {!selectedFields.includes('targetField') && <div className={css.row}>
                <label><FormattedMessage id="OpenAIDiscussionGuideForm.targetLabel" /></label>
                <FieldTextInput
                  className={css.textAreaBox}
                  type="textarea"
                  cols="50"
                  id='targetField'
                  name="targetField"
                  placeholder={targetPlaceholder}
                />
              </div>}
              {!selectedFields.includes('interviewLengthField') && <div className={css.row}>
                <label><FormattedMessage id="OpenAIDiscussionGuideForm.interviewLabel" /></label>
                <FieldTextInput
                  className={css.textAreaBox}
                  type="textarea"
                  cols="50"
                  id='interviewLengthField'
                  name="interviewLengthField"
                  placeholder={interviewPlaceholder}
                />
              </div>}
              {!selectedFields.includes('topicAreaField') && <div className={css.row}>
                <label><FormattedMessage id="OpenAIDiscussionGuideForm.topicAreaLabel" /></label>
                <FieldTextInput
                  className={css.textAreaBox}
                  type="textarea"
                  cols="50"
                  id='topicAreaField'
                  name="topicAreaField"
                  placeholder={topicAreaPlaceholder}
                />
              </div>}
              {!selectedFields.includes('additionalInstructionsField') && <div className={css.row}>
                <label><FormattedMessage id="OpenAIDiscussionGuideForm.addtionalInstructionLabel" /></label>
                <FieldTextInput
                  className={css.textAreaBox}
                  type="textarea"
                  cols="50"
                  id='additionalInstructionsField'
                  name="additionalInstructionsField"
                  placeholder={addtionalInstructionPlaceholder}
                />
              </div>}

              <div className={css.actionButtons}>
                <SecondaryButton
                  type='button'
                  className={css.resetButton}
                  onClick={() => {
                    form.reset()
                    setFile(null)
                  }}
                  disabled={openaiRequest}
                >
                  <FormattedMessage id="OpenAIContractForm.resetButtonText" />
                </SecondaryButton>
                <PrimaryButton
                  type='submit'
                  className={css.submitButton}
                  disabled={submitDisabled}
                  inProgress={openaiRequest}
                >
                  <FormattedMessage id="OpenAIContractForm.submitButtonText" />
                </PrimaryButton>
              </div>
            </div>
          </Form>
        );
      }}
    />
  )
}


OpenAIDiscussionGuideForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  //   updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(OpenAIDiscussionGuideForm);