import React from 'react'
import { FormattedMessage } from 'react-intl'
import css from './DiscourseCommunityForumPanel.module.css'
import ExternalLink from '../ExternalLink/ExternalLink'
const DiscourseCommunityForumPendingApproval = () => {
  return (
    <div className={css.discourseCommunityForumPendingApproval}>
      <h2>
        <FormattedMessage id = "DiscourseCommunityForumPendingApproval.pendingAccountStatus" />
      </h2>
      <FormattedMessage id = "DiscourseCommunityForumPendingApproval.communityForumLinkText" />
      <ExternalLink href='https://insightgig.discourse.group/'>
      <FormattedMessage id = "DiscourseCommunityForumPendingApproval.linkButtonText" />
      </ExternalLink>
    </div>
  )
}

export default DiscourseCommunityForumPendingApproval