import React, { useState } from 'react'
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldCheckboxGroup, FieldSelect, Form, IconCard, IconSpinner, Modal, PrimaryButton, SecondaryButton } from '../../components';
import css from './OpenAIResearchInstrumentTranslatorForm.module.css'
import { FormattedMessage } from 'react-intl';
import FileView from '../../components/FileView/FileView';
import { AI_OPEN_ENDED_RESPONSE_TRANSLATOR, AI_RESEARCH_INSTRUMENT_TRANSLATOR, AI_TRANSLATE_TRANSCRIPT } from '../../util/types';

const ACCEPT_DOC_FILE = ".doc, .docx";
const ACCEPT_DOC_PDF_FILE = ".doc, .docx, .pdf";
const ACCEPT_XLSX_FILE = ".xlsx, .xls";
const FILE_SIZE_LIMIT = 25 * 1000 * 1000;

const languagesArray = [
  {key:"English",label:"English"},
  {key:"Spanish",label:"Spanish"},
  {key:"French",label:"French"},
  {key:"German",label:"German"},
  {key:"Italian",label:"Italian"},
  {key:"Portuguese",label:"Portuguese"},
  {key:"Dutch",label:"Dutch"},
  {key:"Russian",label:"Russian"},
  {key:"Mandarin",label:"Mandarin"},
  {key:"Japanese",label:"Japanese"},
  {key:"Korean",label:"Korean"},
  {key:"Arabic",label:"Arabic"},
  {key:"Hindi",label:"Hindi"},
  {key:"Bengali",label:"Bengali"},
  {key:"Tamil",label:"Tamil"},
]

const OpenAIResearchTranslatorForm = (props) => {
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          pristine,
          updated,
          formId,
          updateError,
          values,
          form,
          openaiRequest,
          handleRedirectToGigAppsPage,
          manageDisableScrolling,
          appRoute,
          isResponseTranslatorApp,
          isTranslateTranscriptApp,
        } = formRenderProps;

        const {files = []} = values || {};
        const [error, setError] = useState(null)

        const submitDisabled = files?.length === 0 || !values?.language || values?.language?.length===0

        function getAppTextLabel(){
          switch(appRoute){
            case AI_RESEARCH_INSTRUMENT_TRANSLATOR: 
              return {title: "OpenAIResearchTranslatorForm.heading", attachmentLabel: "OpenAIResearchTranslatorForm.attachmentLabel"}
            case AI_OPEN_ENDED_RESPONSE_TRANSLATOR: 
              return {title: "OpenAIResponseTranslatorForm.heading", attachmentLabel: "OpenAIResponseTranslatorForm.attachmentLabel"}
            case AI_TRANSLATE_TRANSCRIPT: 
              return {title: "OpenAITranslateTranscriptForm.heading", attachmentLabel: "OpenAITranslateTranscriptForm.attachmentLabel"}
            default: 
              return {title: '', attachmentLabel: ''}
          }
        }

        async function handleFileUpload(e){
          const filesCount = e.target.files.length;
          const files = Object.values(e.target.files);
          const MAX_FILE_COUNT = AI_TRANSLATE_TRANSCRIPT ? 50 : 5;

          if (filesCount > MAX_FILE_COUNT) {
            setError("You can select maximum of 5 documents.")
            return
          } else {
            for (let i = 0; i < filesCount; i++) {
              const file = files[i]
              if (file && file.name && file.size > FILE_SIZE_LIMIT) {
                setError(`You can select files with maximum size of ${FILE_SIZE_LIMIT / 1000000}MB.`)
                return
              }
            }
              
            form.change('files', files)
            form.change('gigAppInputFiles',files)
          }
        }

        return (
          <>
            <Form className={css.researchTranslatorForm} onSubmit={handleSubmit}>
              <div className={css.container}>
                <div className={css.generator}>
                  <span className={css.generatorTitle}>
                    <FormattedMessage id={getAppTextLabel().title} />
                  </span>
                </div>
                <div className={css.attachment}>
                  <label> 
                    <FormattedMessage id={getAppTextLabel().attachmentLabel} />
                  </label>
                  {(files && files.length > 0) ? (
                    files?.map((item, index) => (
                      <FileView key={index} file={item}>
                        <span
                          className={css.close}
                          onClick={() => {
                            const modifiedFiles = [...files]
                            modifiedFiles.splice(index, 1)
                            form.change('files', modifiedFiles)
                          }}
                        >
                          <IconCard brand="cross" />
                        </span>
                      </FileView>
                    ))
                  ) : (
                    <>
                      <Field
                        id="file"
                        name="file"
                        accept={isResponseTranslatorApp ? ACCEPT_XLSX_FILE : isTranslateTranscriptApp ? ACCEPT_DOC_PDF_FILE : ACCEPT_DOC_FILE}
                        type="file"
                      >
                        {fieldprops => {
                          const { accept, input, disabled: fieldDisabled } = fieldprops;
                          const { name, type } = input;

                          const inputProps = { accept, id: name, name, onChange: handleFileUpload, type };
                          return (
                            <>
                              {fieldDisabled ? null : (
                                <input {...inputProps} className={css.addImageInput} multiple={!isResponseTranslatorApp} />
                              )}
                              <label type="button" htmlFor={name} className={css.attachmentLabel}>
                                <IconCard brand="upload" />
                                <span className={css.dragDrop}>
                                  <FormattedMessage id="ZohoSign.dragAndDropUpload" />
                                </span>
                                <span className={css.docType}>
                                  <FormattedMessage id={isResponseTranslatorApp ? "OpenAIAnalyzeRawDataForm.excelAcceptFile" : "ZohoSign.pdfDoc"} />
                                </span>
                              </label>
                            </>
                          );
                        }}
                      </Field>
                      {error ? (
                        <p className={css.fileSizeError}>
                          {error}
                        </p>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                  <label>
                    <FormattedMessage id={`OpenAIResearchTranslatorForm.outputLanguages`} />
                  </label>
                  <div className={css.checkBoxGroup}>
                    {(isResponseTranslatorApp || isTranslateTranscriptApp) ? (
                      <FieldSelect id="language"  name="language" >
                        <option disabled></option>
                        {languagesArray.map(language => (
                          <option key={language.key} value={language.key}>{language.label}</option>
                        ))}
                      </FieldSelect>
                    ) : (
                      <FieldCheckboxGroup id={`OpenAIResearchTranslatorForm.selectLanguages`} name='language' options={languagesArray}/>
                    )}
                  </div>
                </div>
                <div className={css.actionButtons}>
                  <SecondaryButton
                    type="button"
                    className={css.resetButton}
                    onClick={() => {
                      form.reset()
                    }}
                    disabled={openaiRequest}
                  >
                    <FormattedMessage id="OpenAIResearchInstrumentForm.resetButtonText" />
                  </SecondaryButton>
                  <PrimaryButton
                    type="submit"
                    className={css.submitButton}
                    disabled={submitDisabled}
                    inProgress={openaiRequest}
                  >
                    <FormattedMessage
                      id={'OpenAIResearchInstrumentForm.submitButtonText'}
                    />
                  </PrimaryButton>
                </div>
              </div>
            </Form>
            <Modal 
              id={`OpenAIResearchTranslatorForm.modal`} 
              isOpen={values?.success} 
              onClose={()=> {
                form.change('success',false)
                handleRedirectToGigAppsPage()
              }} 
              onManageDisableScrolling={manageDisableScrolling}
            >
              <div className={css.alertModal}>
                <h2><FormattedMessage id="OpenAiAppsPanel.successModalHeading" /></h2>
                <p><FormattedMessage id="OpenAiAppsPage.expansionContent"/></p>
                <PrimaryButton
                  type='button'
                  className={css.closeButton}
                  onClick={() => {
                    form.change('success',false)
                    handleRedirectToGigAppsPage()
                  }}
                >
                  <FormattedMessage id='OpenAIContentAnalysisForm.closeButtonLabel' />
                </PrimaryButton>
              </div>
            </Modal>
          </>

        )
      }}
    />
  )
}

export default OpenAIResearchTranslatorForm