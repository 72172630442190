import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string, oneOfType } from 'prop-types';
import { USER_ROLE_CLIENT, USER_ROLE_PARTNER, INBOX_TAB_BRIEFS, SIGNUP_PAGE_PATHNAME, LOGIN_PAGE_PATHNAME, ContactDetailsPage, LISTING_STATE_DRAFT, USER_ROLE_CSM,} from '../../util/types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES, draftId, draftSlug } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  ListingLink,
  UserRoleSwitcher,
  ExternalLink,
  Modal,
  CsmAvatar
} from '../../components';

import icon from '../../assets/Vectorsvg.svg';
import { TopbarSearchForm } from '../../forms';
import { approvedUserStatus, getUserRole } from '../../util/userRole';

import css from './TopbarDesktop.module.css';
import { ensureCurrentUser } from '../../util/data';
import moment from 'moment';
import { checkDiscourseUser, checkIfUserSubscribed, getFirmId, isEmailVerified } from '../../util/destructorHelpers'
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { useDispatch } from 'react-redux';
import { DESCRIPTION } from '../EditListingWizard/EditListingWizardTab';
import { LISTING_PAGE_PARAM_TYPE_EDIT } from '../../util/urlHelpers';
import { ROLE } from '../EditListingBriefWizard/EditListingBriefWizardTab';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    typePartner,
    currentPage,
    rootClassName,
    currentUserListing,
    currentUserBriefs,
    currentUserListingFetched,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    showRoleSwitcher,
    windowWidth,
    location,
  } = props;
  
  const checkEmailVerified = !!currentUser?.id && isEmailVerified(currentUser);
  const dispatch = useDispatch();
  const currentPathName = location?.pathname;
  const disableLogoLink = [
    LOGIN_PAGE_PATHNAME, 
    '/terms-of-use', 
    '/non-disclosure-agreement', 
    '/privacy-policy', 
    '/cancellation-return-policy'
  ].includes(currentPathName);

  const [mounted, setMounted] = useState(false);
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);
  const typeUser = getUserRole(currentUser);
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const linkedToFirms = !!ensuredCurrentUser?.id && ensuredCurrentUser?.attributes?.profile?.publicData?.linkedToFirms;

  const firmId = !!ensuredCurrentUser?.id && (Array.isArray(linkedToFirms) && linkedToFirms[0]?.firmId || getFirmId(ensuredCurrentUser));

  const firmName = !!ensuredCurrentUser?.id && (Array.isArray(linkedToFirms) && linkedToFirms[0]?.firmName || ensuredCurrentUser?.attributes?.profile?.publicData?.firmListing?.firmTitle);

  const isOwnFirm = !!ensuredCurrentUser?.id && !!ensuredCurrentUser?.attributes?.profile?.publicData?.firmId;
  // Define the offer end date as a constant
  const OFFER_END_DATE = moment('2023-03-31');
  // Check if the current date is before the offer end date
  const isOfferStillValid = moment().isBefore(OFFER_END_DATE);
  const locationFrom = location.state && location.state.from ? location.state.from : null;
  const engagementRoles = currentUserListing?.id && currentUserListing?.attributes?.publicData?.engagementRoles;
  const listingId = currentUserListing?.id && currentUserListing?.id?.uuid;
  const listingTitle = currentUserListing?.id && currentUserListing?.attributes?.title;
  const isApprovedUser = approvedUserStatus(currentUser);
  const isBriefUpdate = currentUserBriefs?.length &&
    currentUserBriefs?.filter(brief => brief?.attributes?.publicData?.packageKeyword !== undefined).length

  const isPartner = typeUser === USER_ROLE_PARTNER
  const hasLinkedFirms = linkedToFirms?.length > 0;
  const hasFirmId = !!firmId;

  const currentUserLocation = ensuredCurrentUser?.id && ensuredCurrentUser?.attributes?.profile?.publicData?.location || {};
  const isCurrentUserHasLocation = Object.keys(currentUserLocation)?.length;
  const isDiscourseUser = currentUser?.id && !!checkDiscourseUser(currentUser);
  const isUserHasSubscribed = checkIfUserSubscribed(currentUser);
  const shouldRenderFavouriteButton = (isUserHasSubscribed || !!linkedToFirms?.length) ?? false;

  
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };
  const renderFirmMenuItem = (
    <MenuItem key="FirmDashBoardPage" className={classNames(css.borderTop)}>
      <NamedLink
        className={classNames(css.yourListingsLink, currentPageClass('FirmDashBoardPage'))}
        name="FirmDashBoardPage"
        params={{
          slug: firmName ?? draftSlug,
          id: firmId ?? draftId,
        }}
      >
        <span className={css.menuItemBorder} />
        <FormattedMessage id="TopbarDesktop.myFirm" />
      </NamedLink>
    </MenuItem>
  );

  const renderBecomePartnerMenuItem = (
    <MenuItem key="BecomeInsightGigPartnerPage" className={classNames(css.borderTop)}>
      <NamedLink
        className={classNames(css.yourListingsLink, currentPageClass('BecomeInsightGigPartnerPage'))}
        name="BecomeInsightGigPartnerPage"
      >
        <span className={css.menuItemBorder} />
        {isOwnFirm ? <FormattedMessage id="TopbarDesktop.myFirm" /> :
          <FormattedMessage id="TopbarDesktop.becomeInsightGigPartnerPage" />}
      </NamedLink>
    </MenuItem>
  );

  let renderFirmItems = null;

  if (isPartner || hasLinkedFirms || hasFirmId || isUserHasSubscribed) {
    if (hasLinkedFirms || hasFirmId || !hasFirmId || isUserHasSubscribed) {
      const shouldRenderBecomePartnerMenuItem = (!isPartner && !hasFirmId) || (isPartner && !hasFirmId);
      renderFirmItems = shouldRenderBecomePartnerMenuItem ? renderBecomePartnerMenuItem : renderFirmMenuItem;
    }
  }

  // renderFirmItems will now contain the appropriate menu item based on the conditions

  useEffect(() => {
    setMounted(true);
    if (isAuthenticated && !checkEmailVerified && currentUser?.id) {
      setIsVerifyModalOpen(true);
    }
  }, [!checkEmailVerified]);

  const searchPlaceholder = () => {
    if (windowWidth < 850)
      return intl.formatMessage({ id: 'LocationSearchForm.placeholderMobile' });
    return typePartner === USER_ROLE_PARTNER
      ? intl.formatMessage({ id: 'TopbarSearchForm.placeholderBriefs' })
      : intl.formatMessage({ id: 'TopbarSearchForm.placeholderExpert' });
  };

  const isCurrentUserListingType =
    currentUserListing &&
    currentUserListing.attributes &&
    currentUserListing.attributes.publicData &&
    currentUserListing.attributes.publicData.listingType;

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topBarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      placeholder={searchPlaceholder()}
      typeUser={typeUser}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.yourListingsLink}
      name="InboxPage"
      params={{ tab: INBOX_TAB_BRIEFS, usertype: typePartner }}
    >
      <span className={css.inboxItems}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;



  const editListing =
    typeUser === USER_ROLE_PARTNER && typePartner === USER_ROLE_PARTNER ? (
      <ListingLink className={css.yourListingsLink} listing={currentUserListing}>
        <span className={css.menuItemBorder} />
        <FormattedMessage id="TopbarDesktop.viewListingPartner" />
      </ListingLink>
    ) : currentUserBriefs && currentUserBriefs.length ? (
      <NamedLink name="ManageListingsPage" className={css.yourListingsLink} params={{ listingType: 'client' }}>
        <span className={css.menuItemBorder} />
        <FormattedMessage id="TopbarDesktop.viewListing" />
      </NamedLink>
    ) : (
      <NamedLink className={css.yourListingsLink} name="ManageListingsPage" params={{ listingType: 'client' }}>
        <span className={css.menuItemBorder} />
        <FormattedMessage id="TopbarDesktop.clientBrief" />
      </NamedLink>
    );
  const createListingLinkMenu =
    isAuthenticatedOrJustHydrated && !(currentUserListingFetched && !currentUserListing) ? null : (
      <>
        <span className={css.menuItemBorder} />
        {typeUser === USER_ROLE_PARTNER && typePartner === USER_ROLE_PARTNER ? (
          <NamedLink className={css.yourListingsLink} name="NewListingPage">
            <FormattedMessage id="TopbarDesktop.createListing" />
          </NamedLink>
        ) : [USER_ROLE_CLIENT, USER_ROLE_CSM]?.includes(typePartner) ? (
          <NamedLink className={css.yourListingsLink} name="ManageListingsPage" params={{ listingType: 'client' }}>
            <FormattedMessage id="TopbarDesktop.clientBrief" />
          </NamedLink>
        ) : (
          <NamedLink className={css.yourListingsLink} name="NewListingPage">
            <FormattedMessage id="TopbarDesktop.createListing" />
          </NamedLink>
        )}
      </>
    );

  const createListingLink =
    isAuthenticatedOrJustHydrated && !(currentUserListingFetched && !currentUserListing) ? null : (
      <NamedLink className={classNames(css.profileSettingsLink, currentPageClass('EditListingPage'))} name="NewListingPage">
        <span >
          {typeUser === USER_ROLE_PARTNER && typePartner === USER_ROLE_PARTNER ? (
            <FormattedMessage id="TopbarDesktop.createListing" />
          ) : [USER_ROLE_CLIENT, USER_ROLE_CSM].includes(typePartner) ? (
            <FormattedMessage id="TopbarDesktop.createBrief" />
          ) : (
            <FormattedMessage id="TopbarDesktop.createListing" />
          )}
        </span>
      </NamedLink>
    );

  const createJobDescriptionLink = (
    <NamedLink
      className={css.profileSettingsLink}
      name="EditJobListingPage"
      params={{ slug: draftSlug, id: draftId, type: 'new', tab: ROLE }}
    >
      <FormattedMessage id="TopbarDesktop.createJobDescription" />
    </NamedLink>
  )

  const manageJobDescriptionLink = (
    <NamedLink className={css.profileSettingsLink} name="ManageListingsPage" params={{ listingType: 'job' }}>
      <FormattedMessage id="TopbarDesktop.manageJobDescription" />
    </NamedLink>
  )

  const HandleLandingPageRedirect = ({url, pageName, children}) => {
    return isApprovedUser ? (
      <ExternalLink 
        href={url}
        className={classNames(css.yourListingsLink, currentPageClass(pageName))}
        name={pageName}
      >
        <span className={css.menuItemBorder} />
        {children}
      </ExternalLink>
    ) : (
      <NamedLink name="LandingPage" className={classNames(css.yourListingsLink, currentPageClass(pageName))}>
        <span className={css.menuItemBorder} />
        {children}
      </NamedLink>
    )
  }

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        {typeUser === USER_ROLE_CSM ?
          <CsmAvatar user={currentUser} typeUser={typeUser} /> :
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        }
      </MenuLabel>

      <MenuContent className={typeUser === USER_ROLE_PARTNER ? css.profileMenuContent : css.profileMenuclient}>
        <MenuItem key="InboxPage">
          {inboxLink}
        </MenuItem>
        <MenuItem key="EditListingPageBrief" className={classNames(css.borderTop, css.paddingBottom)}>
          {authenticatedOnClientSide && createListingLink}
        </MenuItem>
        <MenuItem key="EditListingPage" className={classNames(typeUser === USER_ROLE_CSM ? css.paddingTop : css.noPadding)}>
          {(typeUser === USER_ROLE_PARTNER && currentUserListing) || (typeUser === USER_ROLE_CLIENT && currentUserBriefs && currentUserBriefs.length)
            ? editListing
            : createListingLinkMenu}
        </MenuItem>
        {typeUser === USER_ROLE_CLIENT &&
          <MenuItem key="CreateJobDescription" className={classNames(css.noPadding)}>
            {authenticatedOnClientSide && createJobDescriptionLink}
          </MenuItem>
        }
        {typeUser === USER_ROLE_CLIENT &&
          <MenuItem key="ManageJobDescription" className={classNames(css.paddingTop)}>
            {authenticatedOnClientSide && manageJobDescriptionLink}
          </MenuItem>
        }
        {typeUser === USER_ROLE_CLIENT && shouldRenderFavouriteButton && (
            <MenuItem key="FavouritesPage" className={classNames(css.borderTop)}>
              <NamedLink
                className={classNames(css.yourListingsLink, currentPageClass('FavouritesPage'))}
                name="FavouritesPage"
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.myExperts" />
              </NamedLink>
            </MenuItem>
          )
        }
        
        {renderFirmItems}
        {/* <MenuItem key="IntegrationPage">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('IntegrationPage')
            )}
            name="IntegrationPage"
            params={{
              slug: firmName ?? draftSlug,
              id: firmId ?? draftId,
            }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.integrationPage" />
          </NamedLink>
        </MenuItem> */}
        {/* <MenuItem key="DiscourseCommunityForumPage" className={classNames(css.borderTop)}>
          <HandleLandingPageRedirect url={process.env.REACT_APP_DISCOURSE_URL} pageName='DiscourseCommunityForumPage'>
            <FormattedMessage id={isDiscourseUser ? "TopbarDesktop.discourseCommunityForumPageInsightCafe" : "TopbarDesktop.discourseCommunityForumPage"} />
          </HandleLandingPageRedirect>
        </MenuItem> */}
        <MenuItem key="GigAppsPage" className={classNames(css.borderTop)}>
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('GigAppsPage'))}
            name='GigAppsPage'
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.gigAppsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="StripeSubscriptionPage" className={classNames(css.borderTop, css.paddingBottom)}>
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('StripeSubscriptionPage')
            )}
            name={isUserHasSubscribed ? "ManageSubscriptionPage" : "StripeSubscriptionPage"}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.stripeSubscriptionPage" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage" className={classNames(css.noPadding)}>
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="InsightGigDocumentPage" className={classNames(css.paddingTop)}>
          <HandleLandingPageRedirect url='https://docs.insightgig.com/' pageName='InsightGigDocumentPage'>
            <FormattedMessage id="TopbarDesktop.insightGigDocumentLink" />
          </HandleLandingPageRedirect>
        </MenuItem>
        <MenuItem key="logout" className={classNames(css.borderTop, css.noPadding)}>
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const ShowTopBarBurgerButton = () => {
    return (
      <Menu>
        <MenuLabel isOpenClassName={css.profileMenuIsOpen} className={css.topBarBurger}>
          <div className={css.menuMargin}>
            <span className={css.topBarBurgerButton}>Menu</span>
          </div>
        </MenuLabel>
        <MenuContent className={css.AboutMenuContent}>
          <MenuItem key="About">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.forBrands" />
              <img src={icon} alt="" className={css.textMenuIcon} />
            </NamedLink>
          </MenuItem>
          <MenuItem key="How it work">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.forExperts" />
              <img src={icon} alt="" className={css.textMenuIcon} />
            </NamedLink>
          </MenuItem>
          <MenuItem key="FAQs">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.concierge" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="Why trust AmiqDent">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.taskForce" />
            </NamedLink>
          </MenuItem>
        </MenuContent>
      </Menu>
    );
  };
  const becomeAnExpert = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.becomeAnExpertLink} to={{ state: { from: locationFrom, becomeAnExpert: true } }}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.becomeAnExpert" />
      </span>
    </NamedLink>
  );
  const findAnExpert = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.findAnExpertLink} to={{ state: { from: locationFrom, findAnExpert: true } }}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.findAnExpert" />
      </span>
    </NamedLink>
  );
  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink} to={locationFrom && { state: { from: locationFrom } }}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.signupLink} to={locationFrom && { state: { from: locationFrom } }}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const isListingLinkValid =
    authenticatedOnClientSide && currentUserListingFetched && currentUserListing;

  const listingLink =
    typeUser === USER_ROLE_PARTNER && typePartner === USER_ROLE_PARTNER ? (
      <ListingLink
        className={css.createListingLink}
        listing={currentUserListing}
        children={
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.viewListingPartner" />
          </span>
        }
      />
    ) : isCurrentUserListingType === USER_ROLE_PARTNER ? (
      <NamedLink className={css.createListingLink} name="NewListingPage">
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.createBrief" />
        </span>
      </NamedLink>
    ) : (
      <NamedLink className={css.createListingLink} name="NewListingPage">
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.createBrief" />
        </span>
      </NamedLink>
    );

  const favouritesLink =
    isAuthenticatedOrJustHydrated && typeUser === USER_ROLE_CLIENT ? (
      <NamedLink className={css.createListingLink} name="FavouritesPage">
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.FavouritesPage" />
        </span>
      </NamedLink>
    ) : null;

  const manageProfileLink =
    <NamedLink
      className={css.nonApprovedUserInfoLink}
      name='ListingPage'
      params={{
        slug: listingTitle,
        id: listingId,
      }}
    >
      here.
    </NamedLink>

  const updateProfileLink =
    <NamedLink
      className={css.nonApprovedUserInfoLink}
      name='EditListingPage'
      params={{
        slug: listingTitle?.toLowerCase().replace(/[^a-z0-9]+/g, "-"),
        id: listingId,
        type: LISTING_PAGE_PARAM_TYPE_EDIT,
        tab: DESCRIPTION
      }}
    >
      here.
    </NamedLink>


  const manageBriefLink =
    <NamedLink
      className={css.nonApprovedUserInfoLink}
      name='ManageListingsPage'
      params={{ listingType: 'client' }}
    >
      here.
    </NamedLink>

  return (
    <nav className={classes}>
      <NamedLink className={classNames(css.logoLink, disableLogoLink && css.disableLink)} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      {search}
      {/* {showRoleSwitcher && <UserRoleSwitcher />} */}
      <div className={css.sectionLink}>
        {/* {isListingLinkValid && listingLink} */}
        {/* {inboxLink} */}
        {/* {favouritesLink} */}
        {!!ensuredCurrentUser?.id && profileMenu}
        {/* {[SIGNUP_PAGE_PATHNAME, LOGIN_PAGE_PATHNAME]?.includes(currentPathName) ? null : becomeAnExpert} */}
        {/* {[SIGNUP_PAGE_PATHNAME, LOGIN_PAGE_PATHNAME]?.includes(currentPathName) ? null : findAnExpert} */}
        {loginLink}
        {/* {signupLink} */}
        <Modal
          id="VerifyEmailModal"
          className={css.disableModalBorder}
          contentClassName={css.containerClassName}
          isOpen={isVerifyModalOpen && (currentPage !== ContactDetailsPage) }
          onClose={() => setIsVerifyModalOpen(false)}
          usePortal
          onManageDisableScrolling={(componentId, disableScrolling) =>
            dispatch(manageDisableScrolling(componentId, disableScrolling))
          }
        >
          <NamedLink name="AccountSettingsPage" className={css.verifyEmailMessageButton}>
            <FormattedMessage id="LandingPage.verifyEmailMessage" />
          </NamedLink>
        </Modal>
      </div>
      <div>
        {ensuredCurrentUser?.id && currentUserListing?.id && currentUserListing?.attributes?.state !== LISTING_STATE_DRAFT
          && (!engagementRoles) &&
          <p className={css.nonApprovedUserInfo}>
            <FormattedMessage id="TopbarDesktop.updateMessageExpert"
              values={{ link: manageProfileLink }}
            />
          </p>
        }
        {ensuredCurrentUser?.id && currentUserBriefs?.length ? !isBriefUpdate &&
          <p className={css.nonApprovedUserInfo}>
            <FormattedMessage id="TopbarDesktop.updateMessageClient"
              values={{ link: manageBriefLink }}
            />
          </p> : null
        }
        {ensuredCurrentUser?.id && currentUserListing?.id && currentUserListing?.attributes?.state !== LISTING_STATE_DRAFT && !isCurrentUserHasLocation &&
          <p className={css.nonApprovedUserInfo}>
            <FormattedMessage id="TopbarDesktop.updateLocationMessage"
              values={{ link: updateProfileLink }}
            />
          </p>
        }
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  switchUserType: string,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: oneOfType([propTypes.listing, propTypes.ownListing]),
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
