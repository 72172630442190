import io from 'socket.io-client';
import { storeRawQuantitativeChat } from '../../components/OpenAIAppsPanel/gigAppsHelperFunction';
const URL = process.env.REACT_APP_CANONICAL_ROOT_URL; //For staging
// const URL = process.env.REACT_APP_SOCKET_URL; //For local
const socket = io(URL);

export const  handleThreadResponse = (params) => {
  const { type, threadId, question, displayQuestion, setLastItem, setIsLoading, handleError, id, isTransaction } = params;
  try {
    if (socket) {
      const responseString = `openaiStreamResponse_${threadId}`;
      socket.removeAllListeners(responseString);
      socket.removeAllListeners("quantAppStreamRequest");

      socket.emit('quantAppStreamRequest', { question, threadId, appRoute: type, responseString });

      let isFirstResponse = true;
      let answer = ''
      socket.on(responseString, (data) => {
        if (isFirstResponse) {
          setLastItem({question: displayQuestion, answer: ""})
        }
        if (data?.error) {
          throw new Error('Error getting response!');
        } else if (data?.response === 'stream-response-end') {
          storeRawQuantitativeChat({type, id, data: {question: displayQuestion, answer}, isTransaction})
          setIsLoading(false);
          return;
        }
        isFirstResponse = false
        answer = data?.response
        setLastItem({question: displayQuestion, answer: data?.response});
      });
    }
  } catch (error) {
    // console.log(error, 'Error handling the thread query!');
    handleError(error.message);
  }
};