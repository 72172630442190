import { useState } from "react";
import { fetchQueryReportData, handleWeavaiteDatabase } from "../util/api";

const useFetchQueryReportData = (handleError) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);

  async function fetchData(id, isTransaction){
    try{
      fetchQueryReportData({ id, isTransaction })
      .then(response => {
        if(response.status === 'success'){
          const {data: {files = []} = {}, createdAt, files: transactionFiles = []} = response.data || {};
          const modifiedFilesData = [...files, ...transactionFiles].map(file => ({...file, date: createdAt }));
          setUploadedFiles(modifiedFilesData);
        }
      })
      .catch(error => {
        throw error
      })
    }
    catch(error){
      console.log(error, 'Error fetching Query Report session!')
      handleError(error)
    }
  }

  return [uploadedFiles, fetchData]
}

export default useFetchQueryReportData