import React from "react";

function ArrowIcon({className}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="13"
      fill="none"
      viewBox="0 0 29 13"
      className={className}
    >
      <path
        fill="#fff"
        d="M28.263 5.555L23.045.338a.785.785 0 00-.559-.23.785.785 0 00-.559.23l-.473.474a.8.8 0 000 1.125l3.043 3.05H1.08a.772.772 0 00-.78.777v.67c0 .436.344.812.78.812h23.452l-3.078 3.067a.78.78 0 000 1.11l.473.473c.15.149.348.23.56.23.211 0 .41-.082.559-.231l5.217-5.217a.786.786 0 00.23-.561.786.786 0 00-.23-.562z"
      ></path>
    </svg>
  );
}

export default ArrowIcon;
