import { omit } from "lodash";
import { addMarketplaceEntities, getMarketplaceEntities } from "../../ducks/marketplaceData.duck";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { fetchCurrentUser } from "../../ducks/user.duck";
import { storableError } from "../../util/errors";
import { types as sdkTypes } from '../../util/sdkLoader';
import { addHttpsToLink, getTotalWorkMonths, getUserDetails } from "../../util/destructorHelpers";
import { createAffiliatedFirm, extractKeyword, fetchDataFromURL, getKeyword, handleWeavaiteDatabase, updateListingPublicData } from "../../util/api";
import similarExpertTemplate from "../../components/EditListingWizard/similarExpertTemplate";
import { countryCodes } from "../../translations/countryCodes";
const { UUID } = sdkTypes;


//Action types

export const PUBLISH_FIRM_REQUEST = 'app/BecomeInsightGigPartnerPage/PUBLISH_FIRM_REQUEST';
export const PUBLISH_FIRM_SUCCESS = 'app/BecomeInsightGigPartnerPage/PUBLISH_FIRM_SUCCESS';
export const PUBLISH_FIRM_ERROR = 'app/BecomeInsightGigPartnerPage/PUBLISH_FIRM_ERROR';

export const SHOW_LISTING_REQUEST = 'app/BecomeInsightGigPartnerPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/BecomeInsightGigPartnerPage/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/BecomeInsightGigPartnerPage/SHOW_LISTING_ERROR';
export const UPLOAD_IMAGE_REQUEST = 'app/BecomeInsightGigPartnerPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/BecomeInsightGigPartnerPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/BecomeInsightGigPartnerPage/UPLOAD_IMAGE_ERROR';

export const UPDATE_IMAGE_ORDER = 'app/BecomeInsightGigPartnerPage/UPDATE_IMAGE_ORDER';

export const REMOVE_LISTING_IMAGE = 'app/BecomeInsightGigPartnerPage/REMOVE_LISTING_IMAGE';
export const CLEAR_FIRM_DATA = 'app/BecomeInsightGigPartnerPage/CLEAR_FIRM_DATA';
export const FETCH_KEYWORDS_SUCCESS = 'app/BecomeInsightGigPartnerPage/FETCH_KEYWORDS_SUCCESS';

//InitialState
const initialState = {
  id: null,
  publishFirmInProgress: false,
  publishFirmError: null,
  firmId: null,
  images: {},
  imageOrder: [],
  removedImageIds: [],
  uploadImageError: null,
  industryKeywords: [],
};


//reducer function
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CLEAR_FIRM_DATA:
      return {
        ...state,
        id: null,
        firmId: null
      };
    case PUBLISH_FIRM_REQUEST:
      return {
        ...state,
        publishFirmInProgress: true,
        publishFirmError: null,
        firmId: null,
      };

    case PUBLISH_FIRM_SUCCESS:
      return {
        ...state,
        publishFirmInProgress: false,
        firmId: payload,
      };
    case PUBLISH_FIRM_ERROR:
      return {
        ...state,
        publishFirmInProgress: false,
        publishFirmError: payload,
      };
    case SHOW_LISTING_REQUEST:
      return { ...state, showListngInProgress: true, showListingError: null };
    case SHOW_LISTING_SUCCESS:
      return {
        ...state,
        id: payload?.id,
        showListngInProgress: false,
        showListingError: null
      };
    case SHOW_LISTING_ERROR:
      return { ...state, showListngInProgress: false, showListingError: payload };
    case UPLOAD_IMAGE_REQUEST: {
      // payload.params: { id: 'tempId', file }
      const images = {
        ...state.images,
        [payload.params.id]: { ...payload.params },
      };
      return {
        ...state,
        images,
        imageOrder: state.imageOrder.concat([payload.params.id]),
        uploadImageError: null,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      // payload.params: { id: 'tempId', imageId: 'some-real-id'}
      const { id, imageId } = payload;
      const file = state.images[id].file;
      const images = { ...state.images, [id]: { id, imageId, file } };
      return { ...state, images };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      const { id, error } = payload;
      const imageOrder = state.imageOrder.filter(i => i !== id);
      const images = omit(state.images, id);
      return { ...state, imageOrder, images, uploadImageError: error };
    }
    case UPDATE_IMAGE_ORDER:
      return { ...state, imageOrder: payload.imageOrder };

    case FETCH_KEYWORDS_SUCCESS:
      return { ...state, industryKeywords: payload };

    case REMOVE_LISTING_IMAGE: {
      const id = payload.imageId;

      // Only mark the image removed if it hasn't been added to the
      // listing already
      const removedImageIds = state.images[id]
        ? state.removedImageIds
        : state.removedImageIds.concat(id);

      // Always remove from the draft since it might be a new image to
      // an existing listing.
      const images = omit(state.images, id);
      const imageOrder = state.imageOrder.filter(i => i !== id);

      return { ...state, images, imageOrder, removedImageIds };
    }

    default:
      return state;
  }
}


//Selectors

export const checkScrollingDisabled = state => isScrollingDisabled(state);

// ================ Action creators ================ //
const requestAction = actionType => params => ({ type: actionType, payload: { params } });
const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });
export const publishFirmRequest = () => ({
  type: PUBLISH_FIRM_REQUEST
});
export const publishFirmSuccess = data => ({
  type: PUBLISH_FIRM_SUCCESS,
  payload: data.id
});
export const publishFirmError = (error) => ({
  type: PUBLISH_FIRM_ERROR,
  error: true,
  payload: error,
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
});
export const showListingSuccess = id => ({
  type: SHOW_LISTING_SUCCESS,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const updateImageOrder = imageOrder => ({
  type: UPDATE_IMAGE_ORDER,
  payload: { imageOrder },
});

export const removeListingImage = imageId => ({
  type: REMOVE_LISTING_IMAGE,
  payload: { imageId },
});

export const clearFirmData = () => ({
  type: CLEAR_FIRM_DATA
})

export const fetchKeywordsSuccess = (values) => ({
  type: FETCH_KEYWORDS_SUCCESS,
  payload: values,
})


// SDK method: images.upload
export const uploadImage = requestAction(UPLOAD_IMAGE_REQUEST);
export const uploadImageSuccess = successAction(UPLOAD_IMAGE_SUCCESS);
export const uploadImageError = errorAction(UPLOAD_IMAGE_ERROR);
// ================ Thunk ================ //

export const showListing = (listingId, isOwn = false) => async (dispatch, getState, sdk) => {
  dispatch(showListingRequest());
  try {
    if (listingId) {
      dispatch(showListingSuccess(listingId));
    }

    const params = {
      id: listingId,
      include: ['author', 'currentUser', 'author.publicData', 'author.profileImage', 'author.protectedData', 'images'],
      'fields.image': [
        // Listing page
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.landscape-crop4x',
        'variants.landscape-crop6x',

        // Social media
        'variants.facebook',
        'variants.twitter',

        // Image carousel
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],

    };

    const response = isOwn ? await sdk.ownListings.show(params) : await sdk.listings.show(params);

    dispatch(addMarketplaceEntities(response));

    return response;
  } catch (e) {
    dispatch(showListingError(storableError(e)));
  }
};
export const publishFirm = (params) => async (dispatch, getState, sdk) => {
  const {
    firmTitle = '',
    aboutUs,
    nickname,
    id,
    backgroundImage = params?.backgroundImage ?? null,
    logo = params?.logo ?? null,
    country,
    countryKeyword,
    firmSize,
    firmWebsite,
    department,
    locations,
    industrySector,
    typeOfFirm,
    firmId,
    keywords,
    isClient,
    logoShape
  } = params;
  const modifiedWebsiteLink = firmWebsite ? addHttpsToLink(firmWebsite) : null;
  const publicData = {
    type: 'firm',
    ownerType: '1st Owner',
    subscriptionPlan: isClient ? { nickname, id } : null,
    firmSize,
    firmWebsite: modifiedWebsiteLink,
    department,
    firmLogoId: logo?.uuid ?? null,
    firmBackgroundImageId: backgroundImage?.uuid ?? null,
    aboutUs,
    country,
    countryKeyword,
    locations,
    industrySector,
    typeOfFirm,
    keywords,
    logoShape
  };
  let imageIds = null;

  if (logo && backgroundImage) {
    imageIds = [logo, backgroundImage];
  } else if (logo) {
    imageIds = [logo];
  } else if (backgroundImage) {
    imageIds = [backgroundImage];
  }


  const commonData = firmId ? { id: firmId, publicData, images: imageIds } : { publicData, images: imageIds };

  const options = {
    expand: true,
    include: ["images"],
    'fields.image': [ 'variants.scaled-small', 'variants.scaled-xlarge'],
  };

  const listingData = firmId
    ? { ...commonData, title: firmTitle }
    : { ...commonData, title: firmTitle, publicData: { ...publicData, type: 'firm' } };


  dispatch(publishFirmRequest());
  try {
    const response = firmId
      ? await sdk.ownListings.update(listingData, options)
      : await sdk.ownListings.create(listingData, options);
    const { data: { data = {} } = {} } = response || {};

    if (data?.id) {
      const { attributes: { title = '' }, id } = data;
      const firmListing = {
        firmTitle: title,
        firmId: id.uuid
      };
      await sdk.currentUser.updateProfile({
        publicData: {
          firmId: id?.uuid,
          firmListing
        }
      });

      dispatch(addMarketplaceEntities(response));
      dispatch(publishFirmSuccess(data));
      createAffiliatedFirm({
        firmId: id?.uuid,
        firmName: title,
        firmLogo: response?.data?.included?.[0]?.attributes.variants['scaled-small'].url ?? '',
      });
      dispatch(showListingSuccess(id));
      return response;
    }

  } catch (error) {
    dispatch(publishFirmError(storableError(error)));

  }
}

// Images return imageId which we need to map with previously generated temporary id
export const requestImageUpload = (actionPayload) => async (dispatch, getState, sdk) => {
  const id = actionPayload.id;
  try {

    dispatch(uploadImage(actionPayload));
    const response = await sdk.images.upload({ image: actionPayload.file })
    dispatch(uploadImageSuccess({ data: { id, imageId: response.data.data.id } }));
    return response?.data?.data;
  }
  catch (error) {
    dispatch(uploadImageError({ id, error: storableError(error) }));
  }
};

//Update user profile listing
export const updateUserListing = params => async (dispatch, getState, sdk) => {
  const { listingId, publicData } = params;

  const listingParams = {
    id: listingId,
    publicData
  }
  const options = { expand: true };
  try {
    const response = await sdk.ownListings.update(listingParams, options)
  } catch (error) {
  }
}

//To fetch extracted keywords from Firm Descriptione
export const getExtractedKeywords = param => async (dispatch, getState, sdk) => {
  if (typeof param !== 'string') return []
  dispatch(publishFirmRequest());

  try {
    const { keywords = [] } = await extractKeyword(param)
    const extractedKeywords = (Array.isArray(keywords) && keywords?.length) ? keywords : [];

    return extractedKeywords
  } catch (error) {
  }
}

export const updateFirmNameInWeaviate = ({firmLogoId, firmId, firmTitle, expertIds, shape}) => async (dispatch, getState, sdk) => {
  try {
    let firmLogo = 'none';
    if(firmLogoId){
      const state = getState();
      const ref = { id: firmId, type: 'ownListing' };
      const listings = getMarketplaceEntities(state, [ref]);
      const logoImage = listings[0].images?.find(image => image.id.uuid === firmLogoId)
      firmLogo = logoImage.attributes.variants['scaled-small'].url;
    }

    for(let id of expertIds){
      const response = await sdk.listings.query({ 
        authorId: id,
        pub_listingType: 'partner',
        pub_type: 'partner',
      });

      if(response.status === 200 && response.data.data.length){
        const listingId = response.data.data[0].id.uuid;

        const basicInfo = {
          expertId: listingId,
          firmName: firmTitle,
          firmLogo: {
            url: firmLogo,
            shape: shape
          },
        }

        handleWeavaiteDatabase({
          actionType: 'handleUpdateExpertProfile',
          details: basicInfo,
        })
      }
    }
  } catch (error) {
    console.log(error)
    dispatch(publishFirmError(storableError(error)));
  }
}

export const updateUserDetailsInWeaviate = ({currentUser, listingId}) => async (dispatch, getState, sdk) => {
  try {
    let expertListing = getState().EditListingPage.listing;

    const userId = getUserDetails(currentUser).id;
    const currentUserName = getUserDetails(currentUser)?.fullName;
    const profileImage = getUserDetails(currentUser)?.profileImage;
    const firmName = getUserDetails(currentUser)?.firmName;

    if(!listingId){
      const response = await sdk.listings.query({
        authorId: new UUID(userId),
        pub_listingType: 'partner',
      })
      expertListing = response.data.data[0] ?? {};
    }

    const reviewResponse = await sdk.reviews.query({ listingId: expertListing.id.uuid });
    const ratings = reviewResponse.data.data?.map(review => review.attributes.rating)?.filter(review => !!review);

    const {title, description, publicData, createdAt} = expertListing?.attributes; 
    const {userLocation, industrySector, domainExpertise, engagementRoles, servicePackage, tags, spokenLanguages, insightTechniquesOrModels,
      ['expert-unavailability']: unavailability, Experience, Education, Certification, spokenLanguagesKeywords, softwareKnowledge, category
    } = publicData; 

    const countryName = userLocation?.search ? userLocation.search.split(',').at(-1).trim() : 'India';
    const country = countryCodes.find(country => country.en === countryName).code;

    const basicInfo = {
      expertId: expertListing.id.uuid,
      author: currentUserName,
      country: country,
      languages: spokenLanguages?.length ? spokenLanguages?.map(ln => ln.key) : [],
      totalExperience: Math.floor(getTotalWorkMonths(Experience || []) / 12),
      titleExpert: title,
      aboutExpert: description,
      engagementRoles: engagementRoles || [],
      domainExpertise: domainExpertise || [],
      industrySector: industrySector || [],
      software: softwareKnowledge || [],
      unavailability: unavailability?.[0] ?? 'available',
      servicePackage: servicePackage || [],
      tags: tags || [],
      expertType: category,
      specialTechniques: insightTechniquesOrModels || [],
      countryName: countryName,
      workExperience: Experience?.length ? Experience.map(ex => `${ex.job}, ${ex.company}, ${ex.description}`) : [],
      education: Education?.length ? Education.map(ex => `${ex.qualification}, ${ex.institution}, ${ex.description || ''}`) : [],
      certification: Certification?.length ? Certification.map(ex => `${ex.certification}, ${ex.organisation}`) : [],
      profileImage: profileImage || "",
      ratings: ratings || [],
      createdAt
    }

    const similarExpertProfile = await similarExpertTemplate({
      ...basicInfo, description, spokenLanguagesKeywords, Experience, Certification, firmName
    })

    return await handleWeavaiteDatabase({
      actionType: 'handleUpdateExpertProfile',
      details: basicInfo,
      profile: similarExpertProfile,
    })
  } catch (error) {
    // console.log(error)
    dispatch(publishFirmError(storableError(error)));
  }
}

// Function to remove objects with duplicate keys or labels
function removeDuplicates(arr) {
  const uniqueKeys = new Set();
  const uniqueLabels = new Set();

  return arr.filter(obj => {
    if (!uniqueKeys.has(obj.key) && !uniqueLabels.has(obj.label)) {
      uniqueKeys.add(obj.key);
      uniqueLabels.add(obj.label);
      return true;
    }
    return false;
  });
}

//LoadData
export const loadData = params => async (dispatch, getState, sdk) => {
  try {
    await dispatch(fetchCurrentUser());
    const currentUser = getState().user?.currentUser;

    const { suggestions = {} } = await getKeyword();
    dispatch(fetchKeywordsSuccess(suggestions?.industry?.length ? removeDuplicates(suggestions?.industry) : []));

    if(params.id){
      const listingId = new UUID(params.id);
      const data = {
        id: listingId,
        include: ['author', 'author.profileImage', 'images'],
      };
  
      const currentAuthor = await sdk.listings.show(data);
      const isOwnListing = currentUser?.id?.uuid === currentAuthor?.data?.data?.relationships?.author?.data?.id?.uuid;

      return dispatch(showListing(listingId, isOwnListing));
    }
  } catch (error) {
    dispatch(showListingError(error));
    return;
  }
};