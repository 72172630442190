import React, { useEffect, useState } from 'react'
import { getFirmData } from '../../util/destructorHelpers';
import css from './FirmMemberExpertsPanel.module.css';
import { FormattedMessage } from 'react-intl';
import ListingCard from '../ListingCard/ListingCard';
import { FIRM_EXPERT_LISTING_PAGE } from '../../util/types';
import IconCard from '../IconCard/IconCard';
import OutsideClickHandler from '../OutsideClickHandler/OutsideClickHandler';
import { v4 as uuidv4 } from 'uuid';
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader';
import { getUserShortName } from '../../util/typeHelpers';
import useGetScreenWidth from '../../hooks/useGetScreenWidth';

const FirmMemberExpertsPanel = (props) => {
  const {
    currentListing,
    firmExpertListing,
    listingTags
  } = props;
  const firmData = currentListing?.id && getFirmData(currentListing);
  const [selectedUsernames, setSelectedUsernames] = useState([]);
  const [listings, setListings] = useState([]);
  const [isUIVisible, setIsUIVisible] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const screenWidth = useGetScreenWidth()

  useEffect(() => {
    setListings(firmExpertListing)
  }, [firmExpertListing?.length])

  // const [filteredListings, setFilteredListings] = useState();
  const panelWidth = 62.5;
  // Render hints for responsive image
  const renderSizes = [
    `(max-width: 767px) 100vw`,
    `(max-width: 1920px) ${panelWidth / 2}vw`,
    `${panelWidth / 3}vw`,
  ].join(', ');

  const handleUsernameChange = (event) => {
    const selectedUsername = event.target.value;
    const updatedSelectedUsernames = selectedUsernames.includes(selectedUsername)
      ? selectedUsernames.filter((username) => username !== selectedUsername)
      : [...selectedUsernames, selectedUsername];

    setSelectedUsernames(updatedSelectedUsernames);
  };

  const handleApplyFilter = () => {
    if (selectedUsernames.length === 0) {
      // All checkboxes are unselected, show all listings
      setListings(firmExpertListing || []);
    } else {
      // Filter listings based on selected usernames
      setListings(firmExpertListing.filter((listing) => {
        const isLikedBySelectedAuthor = selectedUsernames.some((username) => listing?.likedByAuthor?.includes(username));

        return isLikedBySelectedAuthor;
      }));
    }
    setIsUIVisible(false)
  }

  const handleClearFilter = () => {
    setListings(firmExpertListing || []);
    setSelectedUsernames([])
    setIsUIVisible(false)
  }


  const handleFilterTags = (tag) => {
    // Toggle the selected state of the tag
    const updatedTags = selectedTags.includes(tag)
      ? selectedTags.filter(selectedTag => selectedTag !== tag)
      : [...selectedTags, tag];

    // Update the state with the new selected tags
    setSelectedTags(updatedTags);

    // Use the updatedTags array to filter the listings
    const filteredListings = firmExpertListing.filter(listing => {
      const listingTags = listing.attributes.publicData?.tags ?? [];
      return updatedTags.some(updatedTag => listingTags.includes(updatedTag));
    });

    // Update the state with the filtered listings
    setListings(updatedTags.length > 0 ? filteredListings : firmExpertListing/* Original listings array when no tags are selected */);
  };

  return (
    <div className={css.firmMemberExpertsPanel}>
      <div className={css.selectExpert}>
        <div onClick={() => setIsUIVisible(!isUIVisible)} className={css.userNameDropdown}>
          <FormattedMessage id="FirmMemberExpertsPanel.filterByUserNameHeading" />
          <span className={css.dropdownArrow}>
            {isUIVisible ?
              <IconCard brand="openarrow" /> :
              <IconCard brand="closearrow" />}
          </span>
        </div>
        {isUIVisible && (
          <div className={isUIVisible ? css.dropdownBox : ""}>
            {firmData?.map((user) => (
              <div key={user.userId} className={css.dropdownList}>
                <input
                  type="checkbox"
                  id={user.userId}
                  value={user.userId}
                  checked={selectedUsernames.includes(user.userId)}
                  onChange={handleUsernameChange}
                />
                {user?.profileImage ? (
                  <img src={user?.profileImage}/>
                ) : (
                  <span className={css.profileImage}>{getUserShortName(user.fullName, user.email)}</span>
                )}
                <label htmlFor={user.userId} className={css.fullNameBox}>{user.fullName}</label>
              </div>
            ))}
            <div className={css.bottomWrapper}>
              <span onClick={handleClearFilter}>
                <FormattedMessage id="FirmMemberExpertsPanel.resetButtonText" />

              </span>
              <span onClick={handleApplyFilter}>
                <FormattedMessage id="FirmMemberExpertsPanel.applyButtonText" />

              </span>
            </div>
          </div>
        )}
      </div>
      <div className={css.filterBox}>
        <label>
          <FormattedMessage id="FirmMemberExpertsPanel.tagsHeading" />
        </label>
        <div className={css.filtersContainer}>
          <ul className={css.filters}>
            {listingTags?.length > 0 && listingTags.map(tag => (
              <li
                key={uuidv4()}
                className={selectedTags?.includes(tag) ? css.selected : css.unSelected}
                onClick={() => handleFilterTags(tag)}

              >
                <FormattedMessage id="FirmMemberExpertsPanel.tagValue" values={{ tag }} />
              </li>

            ))}
          </ul>
        </div>
      </div>

      {/* filter data */}
      <div className={css.expertMemberList}>
        {listings.length > 0 ? listings.map(l => (
          <ListingCard
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={renderSizes}
            setActiveListing={() => { }}
            currentPage={FIRM_EXPERT_LISTING_PAGE}
            screenWidth={screenWidth}
          />
        )) : (
          <>
            {[...Array(3)].map((_, index) => (
              <div className={css.skeletonLoader}>
                <SkeletonLoader key={index} />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default FirmMemberExpertsPanel