import React from 'react';
import loadable from '@loadable/component';
import getPageDataLoadingAPI from './containers/pageDataLoadingAPI';
import { NotFoundPage } from './containers';
import { INBOX_TAB_PROPOSALS, INBOX_TAB_BRIEFS, USER_TYPE_CLIENT } from './util/types';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AboutPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ './containers/AboutPage/AboutPage'));
const AffiliatedFirmPage = loadable(() => import(/* webpackChunkName: "AffiliatedFirmPage" */ './containers/AffiliatedFirmPage/AffiliatedFirmPage'));
const AuthenticationPage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ './containers/AuthenticationPage/AuthenticationPage'));
const BecomeInsightGigPartnerPage = loadable(() => import(/* webpackChunkName: "BecomeInsightGigPartnerPage" */ './containers/BecomeInsightGigPartnerPage/BecomeInsightGigPartnerPage'));
const FirmMemberExpertsPage = loadable(() => import(/* webpackChunkName: "FirmMemberExpertsPage" */ './containers/FirmMemberExpertsPage/FirmMemberExpertsPage'));
const CheckoutPage = loadable(() => import(/* webpackChunkName: "CheckoutPage" */ './containers/CheckoutPage/CheckoutPage'));
const ContactDetailsPage = loadable(() => import(/* webpackChunkName: "ContactDetailsPage" */ './containers/ContactDetailsPage/ContactDetailsPage'));
const ContactUsPage = loadable(() => import(/* webpackChunkName: "ContactUsPage" */ './containers/ContactUsPage/ContactUsPage'));
const DiscourseCommunityForumPage = loadable(() => import(/* webpackChunkName: "DiscourseCommunityForumPage" */ './containers/DiscourseCommunityForumPage/DiscourseCommunityForumPage'));
const DocumentationPage = loadable(() => import(/* webpackChunkName: "DocumentationPage" */ './containers/DocumentationPage/DocumentationPage'));
const EditListingPage = loadable(() => import(/* webpackChunkName: "EditListingPage" */ './containers/EditListingPage/EditListingPage'));
const EmailVerificationPage = loadable(() => import(/* webpackChunkName: "EmailVerificationPage" */ './containers/EmailVerificationPage/EmailVerificationPage'));
const FavouritesPage = loadable(() => import(/* webpackChunkName: "FavouritesPage" */ './containers/FavouritesPage/FavouritesPage'));
const FirmPage = loadable(() => import(/* webpackChunkName: "FirmPage" */ './containers/FirmPage/FirmPage'));
const GigAppsPage = loadable(() => import(/* webpackChunkName: "GigAppsPage" */ './containers/GigAppsPage/GigAppsPage'));
const GigAppsTransactionPage = loadable(() => import(/* webpackChunkName: "GigAppsTransactionPage" */ './containers/GigAppsTransactionPage/GigAppsTransactionPage'));
const FirmDashBoardPage = loadable(() => import(/* webpackChunkName: "FirmDashBoardPage" */ './containers/FirmDashBoardPage/FirmDashBoardPage'));
const GoogleMeetRedirectPage = loadable(() => import(/* webpackChunkName: "FavouritesPage" */ './containers/GoogleMeetRedirectPage/GoogleMeetRedirectPage'));
const InboxPage = loadable(() => import(/* webpackChunkName: "InboxPage" */ './containers/InboxPage/InboxPage'));
const InvitationPage = loadable(() => import(/* webpackChunkName: "InvitationPage" */ './containers/InvitationPage/InvitationPage'));
const IntegrationPage = loadable(() => import(/* webpackChunkName: "IntegrationPage" */ './containers/IntegrationPage/IntegrationPage'));
const AIAppsResultPage = loadable(() => import(/* webpackChunkName: "InboxPage" */ './containers/AIAppsResultPage/AIAppsResultPage'));
const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ './containers/LandingPage/LandingPage'));
const ListingPage = loadable(() => import(/* webpackChunkName: "ListingPage" */ /* webpackPrefetch: true */ './containers/ListingPage/ListingPage'));
const OpenAiAppsPage = loadable(() => import(/* webpackChunkName: "OpenAiAppsPage" */ './containers/OpenAIAppsPage/OpenAIAppsPage'));
const PasswordChangePage = loadable(() => import(/* webpackChunkName: "PasswordChangePage" */ './containers/PasswordChangePage/PasswordChangePage'));
const PasswordRecoveryPage = loadable(() => import(/* webpackChunkName: "PasswordRecoveryPage" */ './containers/PasswordRecoveryPage/PasswordRecoveryPage'));
const PasswordResetPage = loadable(() => import(/* webpackChunkName: "PasswordResetPage" */ './containers/PasswordResetPage/PasswordResetPage'));
const PaymentMethodsPage = loadable(() => import(/* webpackChunkName: "PaymentMethodsPage" */ './containers/PaymentMethodsPage/PaymentMethodsPage'));
const PrivacyPolicyPage = loadable(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ './containers/PrivacyPolicyPage/PrivacyPolicyPage'));
const CancellationPolicyPage = loadable(() => import(/* webpackChunkName: "CancellationPolicyPage" */ './containers/CancellationPolicyPage/CancellationPolicyPage'));
const ProfilePage = loadable(() => import(/* webpackChunkName: "ProfilePage" */ './containers/ProfilePage/ProfilePage'));
const ProfileSettingsPage = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ './containers/ProfileSettingsPage/ProfileSettingsPage'));
const SearchPage = loadable(() => import(/* webpackChunkName: "SearchPage" */ /* webpackPrefetch: true */  './containers/SearchPage/SearchPage'));
const StripePayoutPage = loadable(() => import(/* webpackChunkName: "StripePayoutPage" */ './containers/StripePayoutPage/StripePayoutPage'));
const StripeSubscriptionPage = loadable(() => import(/* webpackChunkName: "StripeSubscriptionPage" */ './containers/StripeSubscriptionPage/StripeSubscriptionPage'));
const StripeSubscriptionSuccessPage = loadable(() => import(/* webpackChunkName: "StripeSubscriptionSuccessPage" */ './containers/StripeSubscriptionSuccessPage/StripeSubscriptionSuccessPage'));
const SupportPage = loadable(() => import(/* webpackChunkName: "SupportPage" */ './containers/SupportPage/SupportPage'));
const TermsOfUsePage = loadable(() => import(/* webpackChunkName: "TermsOfUsePage" */ './containers/TermsOfUsePage/TermsOfUsePage'));
const NonDisclosureAgreementPage = loadable(() => import(/* webpackChunkName: "NonDisclosureAgreementPage" */ './containers/NonDisclosureAgreementPage/NonDisclosureAgreementPage'));
const TransactionPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ './containers/TransactionPage/TransactionPage'));
const ManageListingsPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ /* webpackPrefetch: true */ './containers/ManageListingsPage/ManageListingsPage'));
const ManageSubscriptionPage = loadable(() => import(/* webpackChunkName: "ManageSubscriptionPage" */ /* webpackPrefetch: true */ './containers/ManageSubscriptionPage/ManageSubscriptionPage'));
const ComparisonPage = loadable(() => import(/* webpackChunkName: "ComparisonPage" */ './containers/ComparisonPage/ComparisonPage'));
const QueryReportPage = loadable(() => import(/* webpackChunkName: "QueryReportPage" */ './containers/QueryReportPage/QueryReportPage'));
const ExpertPage = loadable(() => import(/* webpackChunkName: "ExpertPage" */ './containers/ExpertPage/ExpertPage'));

// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() => import(/* webpackChunkName: "StyleguidePage" */ './containers/StyleguidePage/StyleguidePage'));

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
export const draftId = '00000000-0000-0000-0000-000000000000';
export const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage,
      loadData: pageDataLoadingAPI.LandingPage.loadData,
    },
    {
      path: '/home/:tab',
      auth: true,
      authPage: 'SignupPage',
      name: 'LandingPageWithTabs',
      component: LandingPage,
      // loadData: pageDataLoadingAPI.LandingPage.loadData,
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage,
    },
    {
      path: '/affiliated-firm/:slug/:id',
      name: 'AffiliatedFirmPage',
      auth: true,
      authPage: 'SignupPage',
      component: AffiliatedFirmPage,
      loadData: pageDataLoadingAPI.AffiliatedFirmPage.loadData,

    },
    {
      path: '/gig-apps-results',
      name: 'AIAppsResultPage',
      auth: true,
      authPage: 'LoginPage',
      component: AIAppsResultPage,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: SearchPage,
      auth: true,
      authPage: 'LoginPage',
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/auth/google',
      name: 'GoogleMeetRedirectPage',
      component: GoogleMeetRedirectPage,
    },
    {
      path: '/favourites',
      name: 'FavouritesPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <FavouritesPage {...props} />,
      loadData: pageDataLoadingAPI.FavouritesPage.loadData,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/google',
      name: 'ListingPageAuth',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/listings/:listingType',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/job/:slug/:id/:type/:tab',
      name: 'EditJobListingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/gig-apps/:slug',
      name: 'OpenAiAppsPage',
      auth: true,
      authPage: 'LoginPage',
      component: OpenAiAppsPage,
      // loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
    {
      path: '/gig-apps/:slug/form',
      name: 'OpenAiAppsPageForm',
      auth: true,
      authPage: 'LoginPage',
      component: OpenAiAppsPage,
    },
    {
      path: '/gig-apps/:slug/:id/form/:type',
      name: 'OpenAiAppsPageEditForm',
      auth: true,
      authPage: 'LoginPage',
      component: OpenAiAppsPage,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
    },
    {
      path: '/firm/new',
      name: 'BecomeInsightGigPartnerPage',
      auth: true,
      authPage: 'SignupPage',
      component: BecomeInsightGigPartnerPage,
      loadData: pageDataLoadingAPI.BecomeInsightGigPartnerPage.loadData,

    },
    {
      path: '/firm/our-experts/:id',
      name: 'FirmMemberExpertsPage',
      auth: true,
      authPage: 'SignupPage',
      component: FirmMemberExpertsPage,
      loadData: pageDataLoadingAPI.FirmMemberExpertsPage.loadData,

    },
    {
      path: '/firm/:slug/:id',
      name: 'EditBecomeInsightGigPartnerPage',
      auth: true,
      authPage: 'SignupPage',
      component: BecomeInsightGigPartnerPage,
      loadData: pageDataLoadingAPI.BecomeInsightGigPartnerPage.loadData,

    },
    {
      path: '/firm/:slug/:id/details',
      name: 'EditBecomeInsightGigPartnerPageDetails',
      auth: true,
      authPage: 'SignupPage',
      component: BecomeInsightGigPartnerPage,
      loadData: pageDataLoadingAPI.FirmDashBoardPage.loadData,

    },
    // {
    //   path: '/firm/:slug/:id',
    //   name: 'EditBecomeInsightGigPartnerPage',
    //   auth: true,
    //   authPage: 'SignupPage',
    //   component: FirmPage,
    //   loadData: pageDataLoadingAPI.BecomeInsightGigPartnerPage.loadData,

    // },
    {
      path: '/firm-user-management/:slug/:id',
      name: 'FirmPage',
      auth: true,
      authPage: 'SignupPage',
      component: FirmPage,
      loadData: pageDataLoadingAPI.FirmPage.loadData,

    },
    {
      path: '/gig-apps',
      name: 'GigAppsPage',
      auth: true,
      authPage: 'SignupPage',
      component: GigAppsPage,
      // loadData: pageDataLoadingAPI.GigAppsPage.loadData,
    },
    {
      path: '/gig-app/:id/:tab',
      name: 'GigAppsTransactionPage',
      auth: true,
      authPage: 'SignupPage',
      component: GigAppsTransactionPage,
      loadData: pageDataLoadingAPI.GigAppsTransactionPage.loadData,
    },
    {
      path: '/gig-app/:id/:tab/:fileId',
      name: 'GigAppsTransactionPageWithExtraParams',
      auth: true,
      authPage: 'SignupPage',
      component: GigAppsTransactionPage,
      loadData: pageDataLoadingAPI.GigAppsTransactionPage.loadData,
    },
    {
      path: '/gig-apps/category/:slug',
      name: 'GigAppsCategoryPage',
      auth: true,
      authPage: 'SignupPage',
      component: GigAppsPage,
      // loadData: pageDataLoadingAPI.GigAppsPage.loadData,
    },
    {
      path: '/query-report/:slug/:id',
      name: 'QueryReportPage',
      auth: true,
      authPage: 'SignupPage',
      component: QueryReportPage,
    },
    {
      path: '/comparison/:id',
      name: 'ComparisonPage',
      auth: true,
      authPage: 'SignupPage',
      component: ComparisonPage,
      loadData: pageDataLoadingAPI.ComparisonPage.loadData,
    },
    {
      path: '/firm-dashboard/:slug/:id',
      name: 'FirmDashBoardPage',
      auth: true,
      authPage: 'SignupPage',
      component: FirmDashBoardPage,
      loadData: pageDataLoadingAPI.FirmDashBoardPage.loadData,

    },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
    },
    {
      path: '/signuptoig',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signuptoig' },
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: INBOX_TAB_BRIEFS, usertype: USER_TYPE_CLIENT }} />,
    },
    {
      path: '/inbox/:usertype/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },
    {
      path: '/invited-transaction/:id',
      name: 'InvitationPage',
      auth: true,
      authPage: 'SignupPage',
      component: InvitationPage,
      loadData: pageDataLoadingAPI.InvitationPage.loadData,
    },
    {
      path: '/integrations/:slug/:id',
      name: 'IntegrationPage',
      auth: true,
      authPage: 'SignupPage',
      component: IntegrationPage,
      loadData: pageDataLoadingAPI.IntegrationPage.loadData,
    },

    // {
    //   path: '/order/:id',
    //   name: 'OrderPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    // },
    // {
    //   path: '/order/:id/details',
    //   name: 'OrderDetailsPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: TransactionPage,
    //   extraProps: { transactionRole: 'customer' },
    //   loadData: params =>
    //     pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
    //   setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    // },
    // {
    //   path: '/sale/:id',
    //   name: 'SalePage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    // },
    // {
    //   path: '/sale/:id/details',
    //   name: 'SaleDetailsPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: TransactionPage,
    //   extraProps: { transactionRole: 'provider' },
    //   loadData: params =>
    //     pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    // },
    {
      path: '/t/:id/:tab',
      name: 'TransactionDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      // extraProps: { transactionRole: 'customer' },
      loadData: pageDataLoadingAPI.TransactionPage.loadData,
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/t/:id/details/google',
      name: 'TransactionDetailsPageAuth',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      // extraProps: { transactionRole: 'customer' },
      loadData: pageDataLoadingAPI.TransactionPage.loadData,
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: '/contact-us-form',
      name: 'ContactUsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactUsPage,
    },
    {
      path: '/community-forum',
      name: 'DiscourseCommunityForumPage',
      auth: true,
      authPage: 'LoginPage',
      component: DiscourseCommunityForumPage,
    },
    {
      path: '/community-forum/account-status',
      name: 'DiscourseCommunityForumPageAccountStatus',
      auth: true,
      authPage: 'LoginPage',
      component: DiscourseCommunityForumPage,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
    },
    {
      path: '/account/subscription',
      name: 'StripeSubscriptionPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripeSubscriptionPage,
      loadData: pageDataLoadingAPI.StripeSubscriptionPage.loadData,
    },
    {
      path: '/account/subscription-success',
      name: 'StripeSubscriptionSuccessPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripeSubscriptionSuccessPage,
    },
    {
      path: '/account/manage-subscription',
      name: 'ManageSubscriptionPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageSubscriptionPage,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/onboard-user-success',
      name: 'StripePayoutSuccessPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: '/terms-of-use',
      name: 'TermsOfUsePage',
      component: TermsOfUsePage,
    },
    {
      path: '/non-disclosure-agreement',
      name: 'NonDisclosureAgreementPage',
      component: NonDisclosureAgreementPage,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
    },
    {
      path: '/cancellation-return-policy',
      name: 'CancellationPolicyPage',
      component: CancellationPolicyPage,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: StyleguidePage,
      extraProps: { raw: true },
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
    {
      path: '/support',
      name: 'SupportPage',
      // auth: true,
      // authPage: 'LoginPage',
      component: SupportPage,
    },
    {
      path: '/documentation',
      name: 'DocumentationPage',
      // auth: true,
      // authPage: 'LoginPage',
      component: DocumentationPage,
    },
    {
      path: '/expert-profile/:id',
      name: 'ExpertPage',
      // auth: true,
      // authPage: 'LoginPage',
      component: ExpertPage,
    },
  ];
};

export default routeConfiguration;
