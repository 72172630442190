import React from 'react'
import { AI_BRIEF_GENERATOR, AI_CONTENT_ANALYSIS, AI_CONTRACT, AI_DISCUSSION_GUIDE, AI_JOB_DESCRIPTION, AI_PERFECT_QUESTION, AI_PROPOSAL, AI_QUERY_REPORT, AI_QUESTIONNAIRE, AI_REPORT_GENERATOR, AI_TRANSCRIPTION, AI_SUGGEST_ANSWER_OPTION, AI_PRE_TEXT_PROCESSING, AI_KEYWORD_EXTRACTION, AI_TOPIC_MODELING, AI_SENTIMENT_ANALYSIS, AI_COMBINED_TOPIC_SENTIMENT, AI_RESEARCH_INSTRUMENT_COVERAGE_INDICATOR, AI_ANALYZE_RAW_QUANTITATIVE_DATA, AI_TEXTUAL_CONCEPT_CREATER, AI_RESEARCH_INSTRUMENT_TRANSLATOR, AI_OPEN_ENDED_RESPONSE_TRANSLATOR, AI_TRANSLATE_TRANSCRIPT } from '../../util/types';
import css from './OpenAIAppsPanel.module.css'
import ExternalLink from '../ExternalLink/ExternalLink';

const OpenAIAppDescriptionPanel = (props) => {
  const { appRoute } = props;
  const handleDescription = () => {
    switch (appRoute) {
      case AI_BRIEF_GENERATOR:
        return (
          <div className={css.descriptionDetail}>
            <p>
              A research brief is a document outlining relevant information that a potential vendor of insight services needs
              to understand your insight requirement. The document forms the basis for potential vendors to create a proposal
              for your specific requirement. Often, research briefs tend to be too short which necessitate lengthy discussions
              with potential vendors to detail out the exact requirement. Further, a vague research document could lead the
              potential insight provider down the wrong path if they make assumptions that you did not have in mind. Using this
              app, you can create a detailed research brief easily in just a few minutes!
            </p>

            <h3 className={css.mainHeading}>What does the app do?</h3>
            <p>The app creates a detailed research brief from the inputs given by you.</p>

            <h3 className={css.mainHeading}>Who should use it?</h3>
            <p>Anyone looking to create a document detailing out their insight requirement before approaching potential vendors.
            </p>

            <h3 className={css.mainHeading}>When should you use it?</h3>
            <p>Whenever you need to procure insight-related services.</p>

            <h3 className={css.mainHeading}>Why should you use it?</h3>
            <p>The research brief is the basis for your insight project. A specific, detailed document enables successful
              outcomes.</p>

            <h3 className={css.mainHeading}>Anything I need to be cautious about?</h3>
            <p>We highly recommend reading through the generated output and making your own edits before sharing the briefs with
              potential vendors. While the AI can reduce your workload by a large factor, it is not perfect!</p>
          </div>
        );
      case AI_QUESTIONNAIRE:
        return (
          <div className={css.descriptionDetail}>
            <p>
              A questionnaire is the basis of your primary data collection. It is critical to the success of your data collection process. It is also a time consuming process, often requiring several days and multiple versions before deployment. This app is designed to make things a little easier for you. Basis your inputs, it can generate a quantitative questionnaire in seconds. You can subsequently edit the output for your particular use case and deploy.
            </p>

            <h3 className={css.mainHeading}>What does the app do? </h3>
            <p>Generate a quantitative questionnaire from the inputs and instructions given by you.</p>

            <h3 className={css.mainHeading}>Who can use it?</h3>
            <p>Anyone looking to create a quantiative questionnaire for conducting a primary survey.
            </p>

            <h3 className={css.mainHeading}>When should you use it? </h3>
            <p>Whenever you need a quantitative questionnaire for conducting a primary survey with target respondents.</p>

            <h3 className={css.mainHeading}>Why should you use it?</h3>
            <p>To reduce the time taken to create a questionnaire.To get ideas on the kind of questions that you can potentially ask for a survey.</p>

            <h3 className={css.mainHeading}>Anything I need to be cautious about?</h3>
            <p>We highly reccommend reading through the generated output and making your own edits before using the questionnaire. While the AI can reduce your workload by a large factor, it is not perfect!</p>
          </div>
        );
      case AI_TRANSCRIPTION:
        return (
          <div className={css.descriptionDetail}>
            <p>
              The traditional manual transcription approach to recorded interviews is time-consuming. With this app, you can
              transcribe audio files accurately in minutes automatically. You will never go back to the old way!
            </p>

            <h3 className={css.mainHeading}>What does the app do?</h3>
            <p>Transcribe audio files in the mp3, mp4, mpeg, mpga, m4a, wav, and webm formats.</p>

            <h3 className={css.mainHeading}>Who can use it?</h3>
            <p>Anyone looking to transcribe audio files in the formats mentioned above.</p>

            <h3 className={css.mainHeading}>When should you use it?</h3>
            <p>Whenever you have an audio file to be transcribed.</p>

            <h3 className={css.mainHeading}>Why should you use it?</h3>
            <p>Save time and effort when compared to manual methods.</p>

            <h3 className={css.mainHeading}>Anything I need to be cautious about?</h3>
            <p>We highly recommend reading through the generated output and making your own edits. Typically, you would have to
              format the files in the 'Q&A' format so that the files are more readable.</p>
            <p>The app may be inaccurate or fail when the audio in the file is unclear.</p>

          </div>
        );
      case AI_CONTRACT:
        return (
          <div className={css.descriptionDetail}>
            <p>
              When commissioning an insights study, it is good practice to have a contract in place with your vendor covering
              aspects like data confidentiality, non-disclosure, delivery time period, exact project scope, etc. You may not
              have these contracts readily available with you. With this app, it is now possible to generate such contracts in
              seconds. With some edits to suit your specific use case, you should be good to go!
            </p>

            <h3 className={css.mainHeading}>What does the app do?</h3>
            <p>Generate contracts such as NDA and Service Procurement Agreement based on the inputs given by you.</p>

            <h3 className={css.mainHeading}>Who can use it?</h3>
            <p>Anyone procuring insight-related services, wanting to protect their interests with a signed contract.</p>

            <h3 className={css.mainHeading}>When should you use it?</h3>
            <p>After finalizing your insights vendor and before starting the insights project.</p>

            <h3 className={css.mainHeading}>Why should you use it?</h3>
            <p>Reduce the time and effort taken to create a contract.</p>

            <h3 className={css.mainHeading}>Anything I need to be cautious about?</h3>
            <p>We highly recommend reading through the generated output and making your own edits before sharing the contract
              with your potential vendor. While the AI can reduce your workload by a large factor, it is not perfect!</p>
          </div>
        );
      case AI_PROPOSAL:
        return (
          <div className={css.descriptionDetail}>
            <p>
              A proposal for an insight requirement is a key document given by a service provider. It is the basis for all discussions and negotiations, till the research is commissioned and is constantly referred throughout the course of the research project. A detailed research proposal showcases the ability of the service provider to achieve the stated research objectives. This app enables a service provider to create a proposal document that covers most of the bases, allowing further edits for the service provider to add more details from their thought process. The app saves time by providing a more advance starting point when compared to starting from a blank slate. It is not intended to be a repalcement for the entire proposal creation process.
            </p>

            <h3 className={css.mainHeading}>What does the app do?</h3>
            <p>The app creates an insights proposal from the inputs given by you.</p>

            <h3 className={css.mainHeading}>Who can use it?</h3>
            <p>Anyone looking to create a research proposal for an insight requirement.</p>

            <h3 className={css.mainHeading}>When should you use it?</h3>
            <p>Whenever you need to sell insight related services for an insight requirement.</p>

            <h3 className={css.mainHeading}>Why should you use it?</h3>
            <p>The research proposal is the basis for your discussion with a prospective client. A specific, detailed document provides clarity on the services to be provided, aiding successful outcomes.</p>

            <h3 className={css.mainHeading}>Anything I need to be cautious about?</h3>
            <p>We highly reccommend reading through the generated output and making your own edits before sharing the briefs with potential vendors. While the AI can reduce your workload by a large factor, it is not perfect!</p>
          </div>
        );
      case AI_JOB_DESCRIPTION:
        return (
          <div className={css.descriptionDetail}>
            <p>
              A job description is a document outlining relevant information that potential applicants can read to understand the job requirement. The dcoument forms the basis for applicants to decide if they would like to apply for the job. Sometimes, job descriptions tend to miss out on certain key details, especially when created from scratch. Such documents can lead to wrong applicants applying for the job, leading to sub-optimal outcomes. Using this app, you can create a detailed job description easily in just a few minutes!
            </p>

            <h3 className={css.mainHeading}>What does the app do?</h3>
            <p>The app creates a detailed job description from the inputs given by you.</p>

            <h3 className={css.mainHeading}>Who can use it?</h3>
            <p>Anyone looking to create a job description for an insights related role. For example, HR professionals or insights practitioners.</p>

            <h3 className={css.mainHeading}>When should you use it?</h3>
            <p>Whenever you need to hire talent for an insights role.</p>

            <h3 className={css.mainHeading}>Why should you use it?</h3>
            <p>The job description is the basis for attracting the right candidates for the job. A specific, detailed document enables successful outcomes.</p>

            <h3 className={css.mainHeading}>Anything I need to be cautious about?</h3>
            <p>We highly reccommend reading through the generated output and making your own edits before sharing the briefs with potential vendors. While the AI can reduce your workload by a large factor, it is not perfect!</p>
          </div>
        );
      case AI_CONTENT_ANALYSIS:
        return (
          <div className={css.descriptionDetail}>
            <p>
              If you are qualitative researcher or a moderator, you have probably spent several days arranging the data available in interview transcripts on an Excel spreadsheet for easy reporting. With this revolutionary app, you can focus completely on creating a high quality report. The app completely automates the Content Analysis task. Simply upload your discussion guide, the transcripts generated from that discussion guide, and the Content Analysis will be emailed to you in 24 to 48 hours.
            </p>

            <h3 className={css.mainHeading}>What does the app do?</h3>
            <p>The app analyzes the content from a set of qualitative transcripts, and generates a document where (a) each question in the discussion guide is arranged in columns (b) each transcript is arranged in rows and (c) the answers provided by the respondent in the transcript is placed in the appropriate cell matching the row and column. Such an arrangment allows qualitative data to be analysed more easily, since the answers for any particular question for all respondents in a study are available in a single easy-to-read column.</p>

            <h3 className={css.mainHeading}>Who can use it?</h3>
            <p>Anyone looking to analyze the content of a set of transcripts. For example, qualitative researchers or moderators.  </p>

            <h3 className={css.mainHeading}>When should you use it?</h3>
            <p>Whenever you need to analyze the content from a qualitative study.</p>

            <h3 className={css.mainHeading}>Why should you use it?</h3>
            <p>Content analysis is traditionally a manual process where the qualitative researcher or moderator needs to read each transcript and arrange the answers on a spreadsheet. This can often take several days. This app can do this task within 24 hours in most cases, and within 48 hours for large datasets. Further, the app can do this task accurately, without any fatigue associated with the manual approach.
            </p>

            <h3 className={css.mainHeading}>Anything I need to be cautious about?</h3>
            <p>We highly reccommend reading through a sample of the generated output and compare it with the manual approach. While AI can reduce your workload by a large factor, it is not perfect!</p>
          </div>
        );
      case AI_DISCUSSION_GUIDE:
        return (
          <div className={css.descriptionDetail}>
            <p>
              A Discussion Guide is the basis of your primary qualitative data collection. It is critical to the success of your data collection process. It is also time consuming, often requiring several days and multiple versions before deployment. This app is designed to make things a little easier for you. Basis your inputs, it can generate a qualitative Discussion Guide in seconds. You can subsequently edit the output for your particular use case and deploy.
            </p>

            <h3 className={css.mainHeading}>What does the app do?</h3>
            <p>Generate a qualitative Discussion Guide from the inputs and instructions given by you.</p>

            <h3 className={css.mainHeading}>Who can use it?</h3>
            <p>Anyone looking to create a qualitative Discussion Guide for conducting a primary survey.</p>

            <h3 className={css.mainHeading}>When should you use it?</h3>
            <p>Whenever you need a qualitative Discussion Guide for conducting a primary survey with target respondents.</p>

            <h3 className={css.mainHeading}>Why should you use it?</h3>
            <p>To reduce the time taken to create a Discussion Guide.
              To get ideas on the kind of questions that you can potentially ask in your qualitative study.
            </p>

            <h3 className={css.mainHeading}>Anything I need to be cautious about?</h3>
            <p>We highly reccommend reading through the generated output and making your own edits before using the Discussion Guide. While the AI can reduce your workload by a large factor, it is not perfect!</p>
          </div>
        );
      case AI_QUERY_REPORT:
        return (
          <div className={css.descriptionDetail}>
            <p>
              Sometimes you need to know a key piece of insight from one or several historic studies. If you have several old studies, it tis time consuming to read through each of them to find what you are looking for. Enter AI. This application allows you to upload up to 10 files, and ask questions that you need answers for, potentially saving you a lot of time and effort.
            </p>

            <h3 className={css.mainHeading}>What does the app do?</h3>
            <p>You can upload up to 10 documents in the prescribed format, and ask questions of it. The AI tool answers your questions from the information available in the files uploaded by you.</p>

            <h3 className={css.mainHeading}>Who can use it?</h3>
            <p>Anyone looking to find specific insights from reports available with them. If uploading multiple reports, our recommendation would be to keep them in similar areas or topics.</p>

            <h3 className={css.mainHeading}>When should you use it?</h3>
            <p>When you need to extract a piece of insight quickly from one or several reports on the same topic.</p>

            <h3 className={css.mainHeading}>Why should you use it?</h3>
            <p>Cut the time needed to extract insight from historic reports drastically.</p>

            <h3 className={css.mainHeading}>Anything I need to be cautious about?</h3>
            <p>While AI can reduce your workload by a large factor, it is not perfect! Always check the output by asking the same question in multiple ways. </p>
          </div>
        );
      case AI_REPORT_GENERATOR:
        return (
          <div className={css.descriptionDetail}>
            <p>
              If you are familiar with the GigApps section, you would already be aware of our Content Analysis app. Once you have a Content Analysis app,it can become time consuming to read the whole document to start building a story for your report. This applications is designed to assist you in this task. After uploading the Content Analyis, you can chat with your document! The AI 'reads' the document and answers your questions with supporting data from each interview. And dont worry, the AI has been programmed to answer your question only from the uploaded document and nothing else.
            </p>

            <h3 className={css.mainHeading}>What does the app do?</h3>
            <p>You can upload a Content Analysis in the prescribed format, and ask questions of it. The AI tool answers your questions along with supporting data from individual interviews available within the Content Analysis document.</p>

            <h3 className={css.mainHeading}>Who can use it?</h3>
            <p>Anyone looking to write a report from a Content Analysis document. For example, qualitative researchers or moderators.</p>

            <h3 className={css.mainHeading}>When should you use it?</h3>
            <p>Whenever you need to write a report from the Content Analysis document generated from a qualitative study.</p>

            <h3 className={css.mainHeading}>Why should you use it?</h3>
            <p>Writing a report is a time-consuming task, often taking several days. This application allows a user to extract key insights from a Content Analysis document much faster, without having to read through each and every interview finding in the Content Analysis. Our estimate is that it can save up to 40% of time in a typical qualitative report writing task.</p>

            <h3 className={css.mainHeading}>Anything I need to be cautious about?</h3>
            <p>We highly reccommend comparing the output of the application with your own reading of the Content Analysis document. While AI can reduce your workload by a large factor, it is not perfect!</p>
          </div>
        );
      case AI_PERFECT_QUESTION:
        return (
          <div className={css.descriptionDetail}>
            <p>
              Are you one of those insights professionals who obsess about the way a question needs to be asked to elicit the right response from the respondent? We understand you! This application takes inputs from you for a particular question and suggests different ways the question could be worded to meet your objectives. You can choose to use any of the three suggested options directly or take inspiration from them to write your question differently.
            </p>

            <h3 className={css.mainHeading}>What does the app do?</h3>
            <p>You can enter a question that you want to be analysed along with your objective of asking the question to the respondent and the target audience from whom you are seeking answers. The application would provide three alternative ways of asking the same question based on the inputs given by you.</p>

            <h3 className={css.mainHeading}>Who can use it?</h3>
            <p>Any insights professional drafting a questionnaire or a discussion guide.</p>

            <h3 className={css.mainHeading}>When should you use it?</h3>
            <p>Whenever you require assistance in wording a question correctly, in order to achieve your research objectives.</p>

            <h3 className={css.mainHeading}>Why should you use it?</h3>
            <p>To endure you aer asking the question in the most approriate way to meet your research objectives.</p>

            <h3 className={css.mainHeading}>Anything I need to be cautious about?</h3>
            <p>While AI can creatively provide you multiple options, it is not perfect! Always check the output before using it in your questionnaire or discussion guide.</p>
          </div>
        );
      case AI_SUGGEST_ANSWER_OPTION:
        return (
          <div className={css.descriptionDetail}>
            <p>
              When drafting quantitative questionnaires, one of the key considerations is a comprehensive set of answer options for all closed-ended questions. Without it, respondents would need to spend more time entering their choices manually. Further you would end up with several open-ended responses that need to be classified before analysis can take place. To assist you, this application will suggest answer options in seconds!
            </p>

            <h3 className={css.mainHeading}>What does the app do?</h3>
            <p>Create a set of answer options based on just two inputs; the question you are asking and the context in which you are asking the question.</p>

            <h3 className={css.mainHeading}>Who can use it?</h3>
            <p>Anyone creating a quantiative questionnaire for market research or other purposes.</p>

            <h3 className={css.mainHeading}>When should you use it?</h3>
            <p>When you want to be sure that you are covering all the possible answer option for a quantiative question.</p>

            <h3 className={css.mainHeading}>Why should you use it?</h3>
            <p>To list out all the answer options intuitively to respondents so that they can choose an appropriate one with just a click instead of writing out their response manually. Consequently, it also allows for faster data analysis.</p>

            <h3 className={css.mainHeading}>Anything I need to be cautious about?</h3>
            <p>While AI can creatively provide you multiple options, it is not perfect! Always check the output before using it in your questionnaire.</p>
          </div>
        );
      case AI_PRE_TEXT_PROCESSING:
        return (
          <div className={css.descriptionDetail}>
            <p>
              Consider you have a set of open-ended responses in a large quantitative questionnaire. For example, it could be a reason for rating question in a feedback survey. Before analysing such questions, the responses need to be pre-processed to aid analysis. For example, removing spelling errors, removal of stop words which do not provide any benefit during analysis, and so on. This app lets you do pre-processing easily by just uploading your file!
            </p>

            <h3 className={css.mainHeading}>What does the app do?</h3>
            <p>Perform the following pre-processing steps on open-ended responses of quantitative questionnaires, aiding better analysis.</p>
            <ul>
              <li>Spelling correction</li>
              <li>Correction of letter case </li>
              <li>Removal of punctuations</li>
              <li>Removal of stop words</li>
              <li>Stemming i.e. reducing an inflected word to its word stem</li>
              <li>Lemmatization i.e. grouping different inflected forms of a word</li>
              <li>Removal of emojis/emoticons</li>
              <li>Conversion of emojis/emoticons to words</li>
              <li>Removal of URLs</li>
              <li>Removal of HTML tags</li>
            </ul>

            <h3 className={css.mainHeading}>Who can use it?</h3>
            <p>Anyone who needs to pre-process a set of open-ended responses in a quantitative survey.</p>

            <h3 className={css.mainHeading}>When should you use it?</h3>
            <p>Just before performing analysis on a quantitative questionnaire which has open-ended responses.</p>

            <h3 className={css.mainHeading}>Why should you use it?</h3>
            <p>To impriove the quality of your analysis of open-ended responses. </p>

            <h3 className={css.mainHeading}>Anything I need to be cautious about?</h3>
            <p>The applications covers the use cases stated above. If you would like to do any other type of pre-processing, please contact us.</p>
          </div>
        );
      case AI_KEYWORD_EXTRACTION:
        return (
          <div className={css.descriptionDetail}>
            <p>
              TFIDF (term frequency inverse document frequency) is a statistical measure that evaluates how relevant a word is to a paragraph of block of text. Common words in the text such as is, what, if etc. would rank low when they appear many times. On the other hand, words that appear infrequently are likely to be at the core of the piece of text that is being analyzed. TFIDF can extract such words. For a more technical explanation, refer <ExternalLink href='https://www.geeksforgeeks.org/understanding-tf-idf-term-frequency-inverse-document-frequency/'>here</ExternalLink>.
            </p>

            <h3 className={css.mainHeading}>What does the app do?</h3>
            <p>Enables a user to quickly extract the keywords from the answers given to an open-ended question in a quantitative questionnaire. Once the topic are extracted, it can be used for further analysis. The app also has pre=processing built-in i.e. it removes spelling errors, stop words etc. so that the extracted topics are meaningful. </p>

            <h3 className={css.mainHeading}>Who can use it?</h3>
            <p>Any user who is looking to extract the keywords from the answers to an open-ended question in a quantitative questionnaire.</p>

            <h3 className={css.mainHeading}>When should you use it?</h3>
            <p>Analysis of open-ended questions in quantitative questionnaires is often a tedious job, if done manually. This app allows you to extract the keywords quickly enabling you to understand the most important words in a piece of text. For instance, this is an alternative to a word cloud which simply counts the number of times a words appears in a piece of text.</p>

            <h3 className={css.mainHeading}>Why should you use it?</h3>
            <p>To save time in analysing qualitative comments in a quantitative questionnaire or to add a new workflow to your analysis of such questions.</p>

            <h3 className={css.mainHeading}>Anything I need to be cautious about?</h3>
            <p>We reccomend that you analyse at least 300 records at a time. Further, make sure you have a high percentage of responses with at least one sentence. For very short responses of a single word or two, the TFIDF algorithm will not be able to do a good job.</p>
          </div>
        );
      case AI_TOPIC_MODELING:
        return (
          <div className={css.descriptionDetail}>
            <p>
              Consider you have a set of open-ended responses in a large quantitative questionnaire. For example, it could be a reason for rating question in a feedback survey. This app enables you to model the topics of the answers to the open-ended question by just uploading a file with two columns; the response ID, and the answers to the open-ended question you want to analyse.
            </p>

            <h3 className={css.mainHeading}>What does the app do?</h3>
            <p>Enables a user to quickly model the topics from the answers given to an open-ended question in a quantitative questionnaire. Once the topic is modeled, it can be used for further analysis. The app also has pre=processing built-in i.e. it removes spelling errors, stop words etc. so that the extracted topics are meaningful.</p>

            <h3 className={css.mainHeading}>Who can use it?</h3>
            <p>Any user who is looking to identify the topics of discussion from the answers to an open-ended question in a quantitative questionnaire.</p>

            <h3 className={css.mainHeading}>When should you use it?</h3>
            <p>Analysis of open-ended questions in quantitative questionnaires is often a tedious job, if done manually. This app allows you to model the topics quickly enabling you to run additional analysis. For example, you could plot the topics with the rating given by the customer or the sentiment. This enables you to dive deeper into the results of your survey quickly.</p>

            <h3 className={css.mainHeading}>Why should you use it?</h3>
            <p>To save time in analysing qualitative comments in a quantitative questionnaire or to add a new workflow to your analysis of such questions.</p>

            <h3 className={css.mainHeading}>Anything I need to be cautious about?</h3>
            <p>We reccomend that you analyse at least 300 records at a time. Further, make sure you have a high percentage of responses with at least one sentence. For very short responses of a single word or two, the topic modeling algorithm will not be able to do a good job.</p>
          </div>
        );
      case AI_SENTIMENT_ANALYSIS:
        return (
          <div className={css.descriptionDetail}>
            <p>
              Consider you have a set of open-ended responses in a large quantitative questionnaire. For example, it could be a reason for rating question in a feedback survey. This app enables you to get the sentiment of the answers to the open-ended question by just uploading a file with three columns; the response ID, rating, and reason for rating open-ended question.
            </p>

            <h3 className={css.mainHeading}>What does the app do?</h3>
            <p>Enables a user to quickly quantify the sentiment of the answers given to an open-ended question in a quantitative questionnaire. Once the sentiment is quantified, it can be tracked for movement as well as combined with other metrics for deeper analysis. For example, the quantified sentiment can be plotted along with the rating given by the respondent. </p>

            <h3 className={css.mainHeading}>Who can use it?</h3>
            <p>Any user who is looking to quantify the sentiment of an open-ended question in a quantitative questionnaire.</p>

            <h3 className={css.mainHeading}>When should you use it?</h3>
            <p>Analysis of open-ended questions in quantitative questionnaires is often a tedious job, if done manually. This app allows you to quantify the sentiment quickly allowing you to run additional analysis. For example, tracking sentiment over time as you make improvements.</p>

            <h3 className={css.mainHeading}>Why should you use it?</h3>
            <p>To save time in analysing qualitative comments in a quantitative questionnaire or to add a new workflow to your analysis of such questions.</p>

            <h3 className={css.mainHeading}>Anything I need to be cautious about?</h3>
            <p>We reccomend that you analyse at least 300 records at a time. Further, make sure you have a high percentage of responses with at least one sentence. For very short responses of a single word or two, the sentiment analysis algorithm will not be able to do a good job.</p>
          </div>
        );
      case AI_COMBINED_TOPIC_SENTIMENT:
        return (
          <div className={css.descriptionDetail}>
            <p>
              Consider you have a set of open-ended responses in a large quantitative questionnaire. For example, it could be a reason for rating question in a feedback survey. This app enables you to get a PDF report with an analysis of the topics being discussed in the comments and the sentiment of each of the topics. All you need to di is upload a file with two columns; the response ID, and the answers to the open-ended question you want to analyse!
            </p>

            <h3 className={css.mainHeading}>What does the app do?</h3>
            <p>Enables a user to quickly model the topics and quantify the sentiment for each of these topics from the answers given to an open-ended question in a quantitative questionnaire. The report generated from the data allows a user to quickly draw insights from the qualitative comments in a questionnaire, an often neglected analysis. The app also has pre=processing built-in i.e. it removes spelling errors, stop words etc. so that the extracted topics and sentiment are meaningful.</p>

            <h3 className={css.mainHeading}>Who can use it?</h3>
            <p>Any user who is looking to identify the topics and quantify the sentiment for those topics of discussion from the answers to an open-ended question in a quantitative questionnaire.</p>

            <h3 className={css.mainHeading}>When should you use it?</h3>
            <p>Analysis of open-ended questions in quantitative questionnaires is often a tedious job, if done manually. This app allows you to model the topics quickly and quantify the sentiment associated with each of the topics. This enables faster decision making from these qualitative comments as well as additional analysis when combined with other quantitative data such as a rating.</p>

            <h3 className={css.mainHeading}>Why should you use it?</h3>
            <p>To save time in analysing qualitative comments in a quantitative questionnaire or to add a new workflow to your analysis of such questions.</p>

            <h3 className={css.mainHeading}>Anything I need to be cautious about?</h3>
            <p>We reccomend that you analyse at least 300 records at a time. Further, make sure you have a high percentage of responses with at least one sentence. For very short responses of a single word or two, the topic modeling and sentiment analysis algorithm will not be able to do a good job.</p>
          </div>
        );
      case AI_RESEARCH_INSTRUMENT_COVERAGE_INDICATOR:
        return (
          <div className={css.descriptionDetail}>
            <p>
              Do you have to  go through research instruments in detail to check if all your objectives in the brief or proposal are covered? This can now be done in seconds with this app!
            </p>

            <h3 className={css.mainHeading}>What does the app do?</h3>
            <p>Simply upload the brief or proposal and the research instrument(s) developed for the study. The application will provide a detailed report on which of the objectives in the brief or proposal are covered and to what extent. The app would also suggest the improvements that need to be made in the research instrument(s) to achieve the objectives laid out in the brief or proposal.</p>

            <h3 className={css.mainHeading}>Who can use it?</h3>
            <p>A client or an expert who wants to check if the research instrument(s) are addressing the objectives laid out in the brief or proposal.</p>

            <h3 className={css.mainHeading}>When should you use it?</h3>
            <p>After completing the research instrument(s).</p>

            <h3 className={css.mainHeading}>Why should you use it?</h3>
            <p>The research instruments are the basis for achieving the study's objectives. It is prudent to check the coverage of the research instrument(s) to achieve the study's objectives. Using AI, you can now do this in minutes! There is no need to go through documents in detail.</p>

            <h3 className={css.mainHeading}>Anything I need to be cautious about?</h3>
            <p>You can use this application over multiple iterations till the research instrument(s) achieve the study's objectives.</p>
          </div>
        )
      case AI_ANALYZE_RAW_QUANTITATIVE_DATA:
        return (
          <div className={css.descriptionDetail}>
            <p>
              Do you have raw (Excel) data from a quantitative survey that you want to analyze? With this application, you can simply upload your raw data in the prescribed format and ask questions in natural language. Our AI assistant will reply with the relevant analysis of your data!
            </p>

            <h3 className={css.mainHeading}>What does the app do?</h3>
            <p>Analyze raw data from any quantitative survey, by asking relevant questions. </p>

            <h3 className={css.mainHeading}>Who can use it?</h3>
            <p>Any user who has a raw data from a quantitative survey can use this application for data analysis. </p>

            <h3 className={css.mainHeading}>When should you use it?</h3>
            <p>After data collection is completed for a quantitative survey. </p>

            <h3 className={css.mainHeading}>Why should you use it?</h3>
            <p>You could make your own report from the analysis quickly by using this application, doing away with the need for hiring a person or analysing the data yourself. The analysis provided by the application is instant!</p>

            <h3 className={css.mainHeading}>Anything I need to be cautious about?</h3>
            <p>You need to make sure that the uploaded data is in the prescribed format. We also encourage you to check the answer to a simple question manually yourself, so that you get confidence that the automated analysis provided by the application is correct.</p>
          </div>
        )
      case AI_TEXTUAL_CONCEPT_CREATER:
        return (
          <div className={css.descriptionDetail}>
            <p>
              Often, researchers would like to test a concept by reading out a statement to respondents in a study. This application uses the power of AI to create a textual concept easily in a few minutes!
            </p>

            <h3 className={css.mainHeading}>What does the app do?</h3>
            <p>Create textual concepts within the set word limit by providing a few inputs regarding the study.</p>

            <h3 className={css.mainHeading}>Who can use it?</h3>
            <p>Any researcher creating a textual concept for a market research study.</p>

            <h3 className={css.mainHeading}>When should you use it?</h3>
            <p>While creating the questionnaire for the concept test.</p>

            <h3 className={css.mainHeading}>Why should you use it?</h3>
            <p>Using AI, you can generate several versions of the concept within minutes. If you ever face a writer's block, check this app out!</p>

            <h3 className={css.mainHeading}>Anything I need to be cautious about?</h3>
            <p>We recommend using the application multiple times to generate different versions of the concept. Use the one you like the most!</p>
          </div>
        )
      case AI_RESEARCH_INSTRUMENT_TRANSLATOR:
        return (
          <div className={css.descriptionDetail}>
            <p>
              Ever wanted an easy way to translate questionnaires or discussion guides to other languages? This app lets you do just that easily in minutes!
            </p>

            <h3 className={css.mainHeading}>What does the app do?</h3>
            <p>Simply upload your research instrument and translate it into another language of your choice, while preserving the formatting of your original file.</p>

            <h3 className={css.mainHeading}>Who can use it?</h3>
            <p>Any researcher looking to translate a research instrument to another language.</p>

            <h3 className={css.mainHeading}>When should you use it?</h3>
            <p>Whenever you are collecting data in multiple languages.</p>

            <h3 className={css.mainHeading}>Why should you use it?</h3>
            <p>Locating resources for manual translation is not easy. They are also likely to be mich costlier and time-consuming compared to this application.</p>

            <h3 className={css.mainHeading}>Anything I need to be cautious about?</h3>
            <p>While we try to preserve the formatting of your original document, it may not always be possible. You may need to format the output if the original one is not preserved.</p>
          </div>
        )
      case AI_OPEN_ENDED_RESPONSE_TRANSLATOR:
        return (
          <div className={css.descriptionDetail}>
            <p>
              Ever been delayed on analysis because your open-ended responses are in another language in a quantiative questionnaire? This app lets you translate such responses easily in minutes!
            </p>

            <h3 className={css.mainHeading}>What does the app do?</h3>
            <p>Simply upload your data file with open-ended responses and translate them into another language of your choice. The translated responses are added as an additional column next to the original response column.</p>

            <h3 className={css.mainHeading}>Who can use it?</h3>
            <p>Any researcher or analyst looking to translate open-ended responses in a quantitative questionnaire into another language.</p>

            <h3 className={css.mainHeading}>When should you use it?</h3>
            <p>Whenever you want all your open-ended responses in a quantitative questionnaire to be in one language before starting analysis.</p>

            <h3 className={css.mainHeading}>Why should you use it?</h3>
            <p>Locating resources for manual translation is not easy. They are also likely to be much costlier and time-consuming compared to this application.</p>

            <h3 className={css.mainHeading}>Anything I need to be cautious about?</h3>
            <p>Currently, we cover a set of output translated languages. If you need an output language other than the ones listed on the app, feel free to contact us. In some cases, it may be possible to accomodate your request, albeit with slightly reduced accuracy.</p>
          </div>
        )
      case AI_TRANSLATE_TRANSCRIPT:
        return (
          <div className={css.descriptionDetail}>
            <p>
            Doing a multilingual qualitative study and need all your transcripts in one language? This app enables you to quickly translate transcripts from qualitative interviews into an output language of your choice. 
            </p>

            <h3 className={css.mainHeading}>What does the app do?</h3>
            <p>Simply upload your qualitative transcripts and translate it into another language of your choice.</p>

            <h3 className={css.mainHeading}>Who can use it?</h3>
            <p>Any researcher looking to translate qualitative transcripts to another language.</p>

            <h3 className={css.mainHeading}>When should you use it?</h3>
            <p>Whenever you are conducting qualitative interviews in multiple languages, but need all the transcripts in one language for analysis.</p>

            <h3 className={css.mainHeading}>Why should you use it?</h3>
            <p>Locating resources for manual translation is not easy. They are also likely to be much costlier and time-consuming compared to this application.</p>

            <h3 className={css.mainHeading}>Anything I need to be cautious about?</h3>
            <p>Currently, we cover a set of output translated languages. If you need an output language other than the ones listed on the app, feel free to contact us. In some cases, it may be possible to accomodate your request, albeit with slightly reduced accuracy.</p>
          </div>
        )
      default:
        return null;
    }
  }
  return (
    <div className={css.description}>
      {handleDescription()}
    </div>
  )
}

export default OpenAIAppDescriptionPanel