import { useEffect, useState } from 'react';
import { Field, Form as FinalForm  } from 'react-final-form';
import {
  Form,
  FieldTextInput,
  PrimaryButton,
  FieldCheckbox,
  IconCard,
  ExternalLink,
} from '../../components';
import css from './ZohoSignForm.module.css'
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import { compose } from 'redux';
import moment from 'moment';
import pdfimage from '../../assets/pdfimage.png';
import docicon from '../../assets/docicon.png';

const documentation_link = 'https://help.zoho.com/portal/en/kb/zoho-sign/user-guide/sending-a-document/articles/automatic-field-addition-in-zoho-sign'

const ZohoSignForm = (props) => {
  const [showObject, setShowObject] = useState(false);
  const [files, setFiles] = useState([]);
  const [submitProgress, setSubmitProgress] = useState(false);

  useEffect(() => {
    setSubmitProgress(false)
  },[props.openZohoModal])

  showObject && document.body.addEventListener('click', () => {
    setShowObject(false);
  })

  const ACCEPT_FILE = ".doc,.docx,.pdf";

  return(
    <FinalForm 
      {...props}
      render={formRenderProps => {
        const {
          disabled,
          rootClassName,
          className,
          handleSubmit,
          intl,
          pristine,
          values,
          setOpenZohoModal,
          handleUpload
        } = formRenderProps;
        const removeFile = (fileName) => {
          const newFiles = files?.filter(file => file.name !== fileName)
          setFiles(newFiles)
        }

        const clientSignLabel = intl.formatMessage({ id: 'ZohoSignForm.myself' });
        const expertLeadLabel = intl.formatMessage({ id: 'ZohoSignForm.expertLead' });
        const expertInviteeLabel = intl.formatMessage({ id: 'ZohoSignForm.expertInvitee' });
        const thirdPartyExpertLabel = intl.formatMessage({ id: 'ZohoSignForm.thirdPartyExpert' });
        const privateNotePlaceholderMessage = intl.formatMessage({ id: 'ZohoSignForm.privateNotePlaceholderMessage' });
        const privateNoteLabel = intl.formatMessage({ id: 'ZohoSignForm.privateNotes' });

        return(
          <Form onSubmit={e => {
            e.preventDefault()
            setSubmitProgress(true)
            handleUpload(values, files, setSubmitProgress)
          }}>
            <div>
              <div className={css.suggestionBox}>
                <h1><FormattedMessage id="ZohoSignForm.addNewContract"/></h1>
                <h4><FormattedMessage id="ZohoSignForm.guidanceNotes"/></h4>
                <p><FormattedMessage id="ZohoSignForm.messageOne" values={{text: <b>three</b>}}/></p>
                <p><FormattedMessage id="ZohoSignForm.messageTwo"/></p>
                <ul>
                  <li><FormattedMessage id="ZohoSignForm.addTagInstructionOne" /></li>
                  <li><FormattedMessage id="ZohoSignForm.addTagInstructionTwo" /></li>
                  <li><FormattedMessage id="ZohoSignForm.addTagInstructionThree" /></li>
                  <li><FormattedMessage id="ZohoSignForm.addTagInstructionFour" /></li>
                  <li><FormattedMessage id="ZohoSignForm.addTagInstructionFive" /></li>
                  <li><FormattedMessage id="ZohoSignForm.addTagInstructionSix" /></li>
                </ul>
              </div>
              <div className={css.unSureHeading}>
                <FormattedMessage id="ZohoSignForm.documentationLink" 
                  values={{link: <ExternalLink href={documentation_link}>here</ExternalLink>}}
                />
              </div>
              <div className={css.unSureHeading}>
                <FormattedMessage id="ZohoSign.stillUnsure" />
                <a onClick={() => setShowObject(!showObject)}>
                  <FormattedMessage id="ZohoSign.seeExampleDocument" />
                </a>
              </div>
              <div className={css.dividerLine} />
              {showObject &&
                <object className={css.object} data={`${window.location.origin}/static/SampleAgreement.pdf`}></object>
              }
              <div className={css.inputSection}>
                <Field
                  id="fileInput"
                  name="fileInput"
                  accept={ACCEPT_FILE}
                  type="file"
                  required
                >
                  {fieldprops => {
                    const {
                      accept,
                      input,
                    } = fieldprops;
                    const { name, type } = input;
                    const onChange = e => {
                      setFiles([...files, e.target.files[0]])
                    };

                    const inputProps = { accept, id: name, name, onChange, type };
                    return <input {...inputProps} className={css.addImageInput} />
                  }}
                </Field>
                {files?.length !== 0 && files?.length === 3 ? 
                  <div className={css.maxFileError}>
                    <FormattedMessage id="ZohoSignForm.maxFileError"/>
                  </div> :
                  <label htmlFor="fileInput" className={css.inputLabel}>
                    <IconCard brand="upload" />
                    <span className={css.dragDrop}><FormattedMessage id="ZohoSign.dragAndDropUpload" /></span>
                    <span className={css.docType}>
                      <FormattedMessage id="ZohoSign.pdfDoc" />
                    </span>
                  </label>
                }
                {files?.length !== 0 && files?.map(file => {
                  const fileTypeExt = file.name.split('.')[1];
                  return(
                    <div className={css.files}>
                      <div className={css.pdfBox}>
                        <span className={css.pdfImage}>
                          {fileTypeExt === "pdf" ? <img src={pdfimage}/> :
                            ['doc', 'docs', 'docx'].includes(fileTypeExt) && <img src={docicon}/>
                          }
                        </span>
                        <div className={css.pdfRight}>
                          <div className={css.pdfName}>{file.name}</div>
                          <div className={css.pdfDate}>
                            {moment().format("Do MMM YYYY")} at {moment().format("LT")}
                            <span className={css.fileSize}>
                              {(file.size/1000000).toFixed(2)} mb
                            </span>
                          </div>
                        </div>
                      </div>
                      <span className={css.removeFile} onClick={() => removeFile(file.name)}>
                        <FormattedMessage id="ZohoSignForm.remove"/>
                      </span>
                    </div>
                  )
                })}
              </div>
              <div className={css.clientSignCheck}>
                <h4><FormattedMessage id="ZohoSignForm.signatories" /></h4>
                <div className={css.checkList}>
                  <div className={css.checkBoxTag}>
                    <FieldCheckbox
                      className={css.checkbox} 
                      id={'clientSign'} 
                      name={'signatories'} 
                      label={clientSignLabel} 
                      value={'clientSign'} 
                    />
                  </div>
                  <div className={css.checkBoxTag}>
                    <FieldCheckbox 
                      className={css.checkbox}
                      id={'expertSign'} 
                      name={'signatories'} 
                      label={expertLeadLabel} 
                      value={'expertSign'}
                      disabled 
                    />
                  </div>
                  <div className={css.checkBoxTag}>
                    <FieldCheckbox
                      className={css.checkbox} 
                      id={'thirdPartySign'} 
                      name={'signatories'} 
                      label={thirdPartyExpertLabel} 
                      value={'thirdPartySign'} 
                    />
                  </div>
                  <div className={css.checkBoxTag}>
                    <FieldCheckbox
                      className={css.checkbox} 
                      id={'expertInviteeSign'} 
                      name={'signatories'} 
                      label={expertInviteeLabel} 
                      value={'expertInviteeSign'} 
                    />
                  </div>
                </div>
              </div>
              <div>
                <FieldTextInput
                  id="privateNote"
                  name="privateNote"
                  type="text"
                  label={privateNoteLabel}
                  placeholder={privateNotePlaceholderMessage}
                />
              </div>
              <div className={css.buttonsContainer}>
                <button type='button' onClick={()=> setOpenZohoModal(false)} className={css.cancelButton}>
                  <FormattedMessage id="ZohoSignForm.cancel"/>
                </button>
                <PrimaryButton
                  type="submit"
                  inProgress={submitProgress}
                  disabled={!values.privateNote || files?.length < 1}
                >
                  <FormattedMessage id="ZohoSignForm.upload"/>
                </PrimaryButton>
              </div>
            </div>
          </Form>
        )
      }}
    />
  )
}

export default compose(injectIntl)(ZohoSignForm)