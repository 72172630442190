import React, { useEffect, useState } from 'react';
import css from './OpenAIAnalyzeRawQuantitativeDataForm.module.css'
import { Field, Form as FinalForm } from 'react-final-form';
import { Form, PrimaryButton, SecondaryButton, IconCard, Modal, IconSpinner } from '../../components';
import { FormattedMessage } from 'react-intl';
import FileView from '../../components/FileView/FileView';
import { useParams } from 'react-router-dom';
import { fetchQueryReportData } from '../../util/api';
import { handleFileUpload } from '../../util/api';
import MappedQuestions from '../OpenAIContentAnalysisForm/MappedQuestions';
import QueryAnalyzeRawQuantitativeData from './QueryAnalyzeRawQuantitativeData';

const ACCEPT_FILE = '.csv, .xlsx';
const ACCEPT_DOC_FILE = '.doc,.docx,application/pdf';
const ALLOWED_FILE_TYPES = ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
const FILE_SIZE_LIMIT = 25 * 1000 * 1000;

const OpenAIAnalyzeRawQuantitativeDataForm = (props) => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          pristine,
          values,
          form,
          openaiRequest,
          appRoute,
          isReportApp,
          isError,
          manageDisableScrolling,
          setAnalyzeFileError,
          handleRedirectToGigAppsPage,
          analyzeFileError,
          appData
        } = formRenderProps;

        const params = useParams();
        const { step } = values

        const [file, setFile] = useState(null);
        const [questionnaireFile, setQuestionnareFile] = useState(null);
        const [questionsList, setQuestionsList] = useState([]);
        const [error, setError] = useState("");
        const [isLoading, setIsLoading] = useState(false);

        const submitDisabled = (step === 0 && !values.questionnaireDoc) || (step === 1 && (!values.file || !file))

        const handleClose = () => handleRedirectToGigAppsPage()

        async function handleAttachment(file) {
          setIsLoading(true)
          try {
            const response = await handleFileUpload('get-parsed-document', { file })
            if (response?.data) {
              setQuestionnareFile(file)
              if (response?.data) {
                Object.assign(values, { questionnaireDoc: response?.data })
                form.change('gigAppInputFiles', file)
                form.change('questionnaireFile', file)
              }
              setIsLoading(false)
            }
          }
          catch (error) {
            setIsLoading(false)
          }
        }

        async function fetchQuestions(transaction, id) {
          const response = await fetchQueryReportData({ id: id ? id : params.id, isTransaction: transaction })
          let list = response.data.data.list.map(item => {
            return ({
              originalQuestion: `${item?.columnName?.trim()}\n\n${item?.answer?.trim()}`.trim(),
              modifiedQuestion: `${item?.modifiedQuestion}`.trim(),
              generatedQuestion: `${item?.context}`.trim()
            })
          })
          form.change('threadId', response?.data?.data?.threadId)
          let fileName = response?.data?.data?.xlsxFile?.name
          form.change('fileExtension', fileName.split(".")[fileName.split(".").length - 1])
          setQuestionsList(list)
        }

        useEffect(() => {
          if(params.tab){
            form.change('paramsId', appData?.id)
            if(appData?.step===2){
              fetchQuestions(true, appData?.id)
              form.change('step', 2)
            }if(appData?.step===3){
              form.change('step', 3)
            }if(appData?.step===0){
              form.change('step', 0)
            }
          }else{
            if (params.id) {
              if (params.type !== 'edit') {
                form.change('step', 3)
              } else {
                fetchQuestions()
                form.change('paramsId', params.id)
                form.change('step', 2)
              }
            }
          }
        }, [params])

        return (
          <Form onSubmit={handleSubmit}>
            {step === 3 && (
              <QueryAnalyzeRawQuantitativeData 
                form={form} 
                setQuestionsList={setQuestionsList} 
                params={params} 
                step={step} 
                error={error}
                setError={setError}
                appRoute={appRoute}
                fileExtension={values?.fileExtension}
                manageDisableScrolling={manageDisableScrolling}
                threadId={params?.fileId}
                queryId={appData?.id}
              />
            )}
            {step === 2 && (
              <div className={css.mappedQuestions}>
                <div className={css.briefGenerator}>
                  <span className={css.generatorTitle}>
                    <FormattedMessage id={"OpenAIAnalyzeRawDataForm.heading"} />
                  </span>
                </div>
                <div className={css.stepsLabel}><FormattedMessage id="OpenAIContentAnalysisForm.step3" /></div>
                <div>
                  <MappedQuestions questions={questionsList} setQuestions={setQuestionsList} manageDisableScrolling={manageDisableScrolling} appRoute={appRoute} />
                </div>
                <div className={css.actionButtons} style={{justifyContent: 'flex-end'}}>
                  <PrimaryButton
                    type='submit'
                    className={css.submitButton}
                    disabled={submitDisabled}
                    onClick={() => {
                      form.change('questionsList', questionsList)
                    }}
                    inProgress={openaiRequest}
                  >
                    <FormattedMessage id="OpenAIContractForm.submitButtonText" />
                  </PrimaryButton>
                </div>
              </div>
            )}
            {(step === 0 || step === 1 )&& (
              <div className={css.analyzeDataForm}>
                <div className={css.container}>
                  <div className={css.closeButtonContainer} onClick={handleClose}>
                    <span><FormattedMessage id="SelectedExpertsTab.close" /></span>
                    <IconCard brand="crossSign" />
                  </div>
                  <div className={css.briefGenerator}>
                    <span className={css.generatorTitle}>
                      <FormattedMessage id={"OpenAIAnalyzeRawDataForm.heading"} />
                    </span>
                  </div>
                  {step === 0 &&
                    <>
                      <div className={css.attachment}>
                        <h3><FormattedMessage id="OpenAIAnalyzeRawDataForm.questionnaireAttachmentLabel" /></h3>
                        {/* <div className={css.attachment}> */}
                        {questionnaireFile ? (
                          <FileView file={questionnaireFile}>
                            <span className={css.close} onClick={() => {
                              setQuestionnareFile(null)
                              form.change('questionnaireDoc', '')
                            }}>
                              <IconCard brand='cross' />
                            </span>
                          </FileView>
                        ) : (
                          <>
                            <Field
                              id="file"
                              name="file"
                              accept={ACCEPT_DOC_FILE}
                              type="file"
                            >
                              {fieldprops => {
                                const { accept, input, disabled: fieldDisabled } = fieldprops;
                                const { name, type } = input;
                                const onChange = async e => {
                                  const file = e.target.files[0];
                                  if (file && file.name && file.size < FILE_SIZE_LIMIT) {
                                    handleAttachment(file)
                                  }
                                };

                                const inputProps = { accept, id: name, name, onChange, type };
                                return <input {...inputProps} className={css.addImageInput} />
                              }}
                            </Field>
                            <label htmlFor="file" className={css.attachmentLabel}>
                              {isLoading ? <IconSpinner strokeColor='#0B96F5' /> : <IconCard brand="upload" />}
                              <span className={css.dragDrop}><FormattedMessage id="ZohoSign.dragAndDropUpload" /></span>
                              <span className={css.docType}>
                                <FormattedMessage id="ZohoSign.pdfDoc" />
                              </span>
                            </label>
                          </>
                        )}
                        {/* </div> */}
                      </div>
                      <div className={css.actionButtons}>
                        <SecondaryButton
                          type='button'
                          className={css.resetButton}
                          onClick={() => {
                            setQuestionnareFile(null)
                            form.reset()
                          }}
                          disabled={openaiRequest}
                        >
                          <FormattedMessage id="OpenAIContractForm.resetButtonText" />
                        </SecondaryButton>
                        <PrimaryButton
                          type='submit'
                          onClick={(e) => {
                            e.preventDefault();
                            form.change('step', 1);
                          }}
                          className={css.submitButton}
                          disabled={submitDisabled}
                          inProgress={openaiRequest}
                        >
                          <FormattedMessage id="OpenAIContractForm.submitButtonText" />
                        </PrimaryButton>
                      </div>
                    </>
                  }
                  {step === 1 && (
                    <>
                      <div className={css.attachment}>
                        <h3><FormattedMessage id={"OpenAIAnalyzeRawDataForm.subHeading"} /></h3>
                        {file ?
                          <span key={file.name}>
                            <FileView file={file}>
                              <span className={css.close} onClick={() => {
                                setAnalyzeFileError(false)
                                form.change('file', null)
                                setFile(null)
                              }}>
                                <IconCard brand='cross' />
                              </span>
                            </FileView>
                          </span>
                          :
                          <Field
                            id="proposalDocument"
                            name="proposalDocument"
                            accept={ACCEPT_FILE}
                            type="file"
                          >
                            {fieldprops => {
                              const { accept, input, disabled: fieldDisabled } = fieldprops;
                              const { name, type } = input;
                              const onChange = async e => {
                                const file = e.target.files[0];
                                if (file && file.name && file.size > FILE_SIZE_LIMIT) {
                                  setError(`Please select a file less than ${FILE_SIZE_LIMIT / 1000000}MB size.`);
                                  return;
                                } if (!ALLOWED_FILE_TYPES.includes(file.type)) {
                                  setError('Invalid file type. Only CSV and XLSX files are allowed.');
                                  return;
                                }
                                setError(false)
                                setFile(file)
                                form.change('file', file)
                                // form.change('gigAppInputFiles',[values.gigAppInputFiles,file])
                              };

                              const inputProps = { accept, id: name, name, onChange, type };
                              return (
                                <>
                                  {fieldDisabled ? null : (
                                    <input {...inputProps} className={css.addImageInput} />
                                  )}
                                  <label type="button" htmlFor={name} className={css.attachmentLabel}>
                                    <IconCard brand="upload" />
                                    <span className={css.dragDrop}>
                                      <FormattedMessage id="ZohoSign.dragAndDropUpload" />
                                    </span>
                                    <span className={css.docType}>
                                      <FormattedMessage id="OpenAIAnalyzeRawDataForm.excel" />
                                    </span>
                                  </label>
                                </>
                              );
                            }}
                          </Field>
                        }
                      </div>
                      {!!error && (
                        <p className={css.fileSizeError}>{error}</p>
                      )}
                      {!!analyzeFileError &&
                        <p className={css.fileSizeError}>Invalid file format.</p>
                      }
                      <div className={css.actionButtons}>
                        <SecondaryButton
                          type='button'
                          className={css.resetButton}
                          onClick={() => {
                            setFile(null)
                            setQuestionnareFile(null)
                            form.reset()
                            form.change('step', 0)
                          }}
                          disabled={openaiRequest}
                        >
                          <FormattedMessage id="OpenAIContractForm.resetButtonText" />
                        </SecondaryButton>
                        <PrimaryButton
                          type='submit'
                          className={css.submitButton}
                          disabled={submitDisabled}
                          inProgress={openaiRequest}
                        >
                          <FormattedMessage id="OpenAIContractForm.submitButtonText" />
                        </PrimaryButton>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            <Modal
              id={`analyseRawQuantitativeDataSuccessModal`}
              isOpen={values.showModal}
              onClose={() => {
                form.change('showModal', false)
                handleRedirectToGigAppsPage()
              }}
              onManageDisableScrolling={manageDisableScrolling}
              removeCloseButton={true}
            >
              <div className={css.alertModal}>
                <h2>
                  <FormattedMessage id="OpenAiAppsPanel.successModalHeading" />
                </h2>
                <p><FormattedMessage id='OpenAIAnalyzeRawDataForm.docProcessingContent' /></p>
                <PrimaryButton
                  type='button'
                  className={css.closeButton}
                  onClick={() => {
                    form.change('showModal', false)
                    handleRedirectToGigAppsPage()
                  }}
                >
                  <FormattedMessage id='OpenAIContentAnalysisForm.closeButtonLabel' />
                </PrimaryButton>
              </div>
            </Modal>
          </Form>
        )
      }}
    />
  )
}

export default OpenAIAnalyzeRawQuantitativeDataForm