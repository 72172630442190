import React, { useRef, useEffect, useState } from 'react';
import css from './QuillTextEditor.module.css';
import { generateExpansion, sendOpenaiResponseOnEmail, updateOpenAIData } from '../../util/api';
import Modal from '../Modal/Modal';
import { FormattedMessage } from 'react-intl';
import { PrimaryButton } from '../Button/Button';
import axios from 'axios';
import { saveAs } from 'file-saver';
import IconSpinner from '../IconSpinner/IconSpinner';
import classNames from 'classnames';
import ReactToPrint from 'react-to-print';
import useDownloadContent from '../../hooks/useDownloadContent';

const undoSVG = `<svg style="transform: scaleX(-1); color: grey;" width="18px" height="18px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M500.33 0h-47.41a12 12 0 0 0-12 12.57l4 82.76A247.42 247.42 0 0 0 256 8C119.34 8 7.9 119.53 8 256.19 8.1 393.07 119.1 504 256 504a247.1 247.1 0 0 0 166.18-63.91 12 12 0 0 0 .48-17.43l-34-34a12 12 0 0 0-16.38-.55A176 176 0 1 1 402.1 157.8l-101.53-4.87a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12h200.33a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12z"/></svg>`

const redoSVG = `<svg style="color: grey;" width="18px" height="18px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M500.33 0h-47.41a12 12 0 0 0-12 12.57l4 82.76A247.42 247.42 0 0 0 256 8C119.34 8 7.9 119.53 8 256.19 8.1 393.07 119.1 504 256 504a247.1 247.1 0 0 0 166.18-63.91 12 12 0 0 0 .48-17.43l-34-34a12 12 0 0 0-16.38-.55A176 176 0 1 1 402.1 157.8l-101.53-4.87a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12h200.33a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12z"/></svg>`

export function downloadFile(blob) {
  const date = new Date().valueOf();
  let url = typeof window !== 'undefined' && window.URL.createObjectURL(blob);
  let a = typeof window !== 'undefined' && window.document.createElement('a');
  a.href = url; //Setting dynamic created URL
  a.download = `GigAppsResponse-${date}`; //Setting fileName
  a.click();
}

const QuillTextEditor = props => {
  const {
    initialText,
    appRoute,
    email,
    id,
    setInitialText,
    onManageDisableScrolling,
    history,
    handleRedirectToGigAppsPage,
    appTitle,
    firstName,
    refreshUniqueId
  } = props;

  const editorRef = useRef(null);
  const scriptRef = useRef(null);

  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [copyButtonState, setCopyButtonState] = useState('Copy');
  const [fetch, downloadFetchContent] = useDownloadContent();
  const [expansionModal,setExpansionModal] = useState(false)

  useEffect(() => {
    try {
      const loadScript = (src) => {
        return new Promise((resolve, reject) => {
          const script = document.createElement('script');
          script.src = src;
          script.async = true;
          script.onload = resolve;
          script.onerror = reject;
          scriptRef.current = script;
          document.body.appendChild(script);
        });
      };
  
      const createButton = (svg, onClick) => {
        const button = document.createElement('button');
        button.innerHTML = svg;
        button.addEventListener('click', onClick);
        button.style.background = 'none';
        button.style.border = 'none';
        button.style.cursor = '0';
        button.style.margin = '0';
        return button;
      };
  
      const initializeEditor = () => {
        const editor = new toastui.Editor({
          el: document.querySelector('#editor'),
          height: '600px',
          previewStyle: 'tab',
          initialEditType: 'wysiwyg',
          hideModeSwitch: true,
          initialValue: initialText?.content,
        });
  
        editor.insertToolbarItem({ groupIndex: 4, itemIndex: 2 }, {
          name: 'myUndo',
          tooltip: 'Undo',
          command: 'undo',
          el: createButton(undoSVG, () => editor.exec('undo')),
        });
  
        editor.insertToolbarItem({ groupIndex: 4, itemIndex: 3 }, {
          name: 'myRedo',
          tooltip: 'Redo',
          command: 'redo',
          el: createButton(redoSVG, () => editor.exec('redo')),
        });
  
        editorRef.current = editor;
      };
  
      loadScript('https://uicdn.toast.com/editor/latest/toastui-editor-all.min.js')
        .then(initializeEditor)
        .catch((error) => {
          console.error('Failed to load the ToastUI script:', error);
        });

      return () => {
        if (scriptRef.current) {
          document.body.removeChild(scriptRef.current);
        }
        if (editorRef.current) {
          editorRef.current.reset();
        }
      };
    } catch (error) {
      // console.log(error, 'Error initializing Editor Instance!')
    }
  }, []);

  useEffect(() => {
    try {
      if(editorRef.current){
        editorRef.current.setMarkdown(initialText?.content, false);
        
        // Scroll the editor container at the end with stream
        editorRef.current.setScrollTop(1000000000000000);
      }
    } catch (error) {
      // console.log(error, 'Error setting HTML content into Editor!')
    }
  }, [initialText?.content]);

  function sendResponseOnEmail() {
    history.goBack();
    sendOpenaiResponseOnEmail({
      id: id,
      appName: document.title,
      email: email,
      firstName: firstName,
      appName: appTitle,
      appRoute
    });
  }

  async function handleDownloadContent(type){
    const content = editorRef.current.getMarkdown();
    downloadFetchContent(type, content)
  }

  async function handleExpansion(){
    try {
      const payload = { proposalContents: initialText?.content, email: email, appTitle: appTitle, firstName: firstName, appRoute }
      generateExpansion(payload)
      setTimeout(() => {
        setExpansionModal(true)
      },500)
    } catch (error) {
      console.log(error)
    }
  }

  const Loader = ({type, id}) => {
    if(fetch?.type === type && !!fetch?.isLoading){
      return <IconSpinner strokeColor='orange'/>
    }
    else {
      return <FormattedMessage id={id} />
    }
  }

  const handleCopy = () => {
    const content = document.getElementById('editor').innerText;
    navigator.clipboard
      .writeText(content)
      .then(() => {
        // Content copied successfully
        setCopyButtonState('Copied ✔');
        setTimeout(() => {
          setCopyButtonState('Copy'); // Reset state after a short delay
        }, 2000); // Adjust delay as needed
      })
      .catch(error => {
        return;
      });
  };

  return (
    <div className={css.editor}>
      <Modal
        id="openDocModal"
        className={css.disableModalBorder}
        containerClassName={css.modalContainer}
        isOpen={initialText?.isOpen}
        onClose={() => {
          setInitialText({ isOpen: false })
          refreshUniqueId()
        }}
        usePortal={false}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.header}>
          <h1>
            <FormattedMessage id="TextEditor.title" />
          </h1>
          <div>
            {initialText?.isCompleted ? (
              <>
                <button onClick={() => handleExpansion()}>
                  <FormattedMessage id="TextEditor.expand"/>
                </button>
                <button onClick={() => handleDownloadContent('docx')}>
                  <Loader type='docx' id="TextEditor.exportDocButton"/>
                </button>
                <button onClick={() => handleDownloadContent('pdf')}>
                  <Loader type='pdf' id="TextEditor.exportPdfButton"/>
                </button>
                <button
                  onClick={handleCopy}
                  className={classNames(copyButtonState === 'Copied ✔' && css.copiedButton)}
                >
                  {copyButtonState}
                </button>
              </>
            ) : (
              <button onClick={() => setOpenAlertModal(true)}>
                <FormattedMessage id="TextEditor.emailResponseButton" />
              </button>
            )}
          </div>
        </div>
        <div ref={editorRef} id="editor" onCopy={e => e && updateOpenAIData({ id })}></div>
      </Modal>
      <Modal
        id="alertModal"
        isOpen={openAlertModal}
        onClose={() => setOpenAlertModal(false)}
        usePortal={false}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.alertModal}>
          <p>
            <FormattedMessage id="TextEditor.alertMessage" />
          </p>
          <div className={css.buttonWrapper}>
            <div onClick={() => setOpenAlertModal(false)}>
              <FormattedMessage id="TextEditor.cancelButton" />
            </div>
            <PrimaryButton onClick={sendResponseOnEmail}>
              <FormattedMessage id="TextEditor.confirmButton" />
            </PrimaryButton>
          </div>
        </div>
      </Modal>
      <Modal
        id="alertModal"
        isOpen={expansionModal}
        onClose={() => setExpansionModal(false)}
        usePortal={false}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.alertModal}>
          <p>
            <FormattedMessage id="OpenAiAppsPage.expansionContent" />
          </p>
          <div className={css.centeredButton}>
            <PrimaryButton onClick={()=> {
              handleRedirectToGigAppsPage()
              setExpansionModal(false)
            }}>
              <FormattedMessage id="OpenAiAppsPanel.openAlertModalCloseButton" />
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default QuillTextEditor;
