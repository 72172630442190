import React, { useEffect, useState, forwardRef } from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { Form, FieldTextInput, PrimaryButton, SecondaryButton, FieldSelect, FieldCheckbox, FieldRadioButton } from '../../components';
import css from './OpenAIJobDescriptionForm.module.css';
import { currencyFeeTemp } from '../../marketplace-custom-config';
import classNames from 'classnames';
import { FULLTIME_KEY, SECONDMENT_KEY } from '../../util/types';
import { required } from '../../util/validators';


export const OpenAIJobDescriptionForm = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          pristine,
          updated,
          formId,
          updateError,
          values,
          form,
          openaiRequest,
          initialText,
        } = formRenderProps;
        
        const validateField = (name,checkbox)=>{
          if((!values[checkbox] || values[checkbox].length===0) && !values[name]){
            return true
          }else{
            return false
          }
        }

        const {companyName,industry,role,requirement,location,contractType,employmentLength,minYear,maxYear,currency,ctc} = values || {};
        const submitDisabled = pristine || !companyName || !industry || !role || !requirement || validateField('location','remoteLocation') || !contractType || validateField('employmentLength','employementRequired') || validateField('minYear','experienceRequired') || validateField('maxYear','experienceRequired') || validateField('currency','budgetRequired') || validateField('ctc','budgetRequired');
        const isRemoteLocation = !!values.remoteLocation?.length && values.remoteLocation[0] === 'true';
        const isEmployementRequired = !!values.employementRequired?.length && values.employementRequired[0] === 'true';
        const isExperienceRequired = !!values.experienceRequired?.length && values.experienceRequired[0] === 'true';
        const isBudgetRequired = !!values.budgetRequired?.length && values.budgetRequired[0] === 'true';
        
        const locationCheckLabel = intl.formatMessage({ id: "OpenAIJobDescriptionForm.locationCheckLabel" });
        const experienceCheckLabel = intl.formatMessage({ id: "OpenAIJobDescriptionForm.experienceCheckLabel" });
        const companyRequiredMessage = intl.formatMessage({ id: "OpenAIJobDescriptionForm.companyRequiredMessage" });
        const roleRequiredMessage = intl.formatMessage({ id: "OpenAIJobDescriptionForm.roleRequiredMessage" });
        const ctcRequiredMessage = intl.formatMessage({ id: "OpenAIJobDescriptionForm.budgetRequiredMessage" });

        useEffect(() => {
          if (initialText) {
            form.reset();
          }
        }, [initialText])

        return (
          <Form className={css.jobDescriptionForm} onSubmit={handleSubmit} >
            <div className={css.container}>
              <div className={css.briefGenerator}>
                <span className={css.generatorTitle}>
                  <FormattedMessage id="OpenAIJobDescriptionForm.heading" />
                </span>
              </div>
              <div className={css.row}>
                <label><FormattedMessage id="OpenAIJobDescriptionForm.companyNameLabel" /></label>
                <FieldTextInput
                  className={css.inputBox}
                  id='companyName'
                  name="companyName"
                  type="textarea"
                  placeholder="Your company name"
                  validate={required(companyRequiredMessage)}
                />
              </div>
              <div className={css.row}>
                <label><FormattedMessage id="OpenAIJobDescriptionForm.industryLabel" /></label>
                <FieldTextInput
                  className={css.inputBox}
                  id='industry'
                  name="industry"
                  type="textarea"
                  placeholder="FMCG, B2B, Pharma, etc."
                />
              </div>
              <div className={css.row}>
                <label><FormattedMessage id="OpenAIJobDescriptionForm.roleLabel" /></label>
                <FieldTextInput
                  className={css.inputBox}
                  id='role'
                  name="role"
                  type="textarea"
                  validate={required(roleRequiredMessage)}
                />
              </div>
              <div className={css.row}>
                <label><FormattedMessage id="OpenAIJobDescriptionForm.requirementLabel" /></label>
                <FieldTextInput
                  className={css.inputBox}
                  id='requirement'
                  name="requirement"
                  type="textarea"
                  cols="100"
                  placeholder="Specific requirements/the profile of candidate you are looking for, including required skills, qualifications etc."
                />
              </div>
              <div className={css.row}>
                <label><FormattedMessage id="OpenAIJobDescriptionForm.locationLabel" /></label>
                <FieldTextInput
                  className={classNames(css.inputBox, isRemoteLocation && css.disabled)}
                  id='location'
                  name="location"
                  type="textarea"
                  disabled={isRemoteLocation}
                />
                <FieldCheckbox
                  className={css.checkbox}
                  id='remoteLocation'
                  name='remoteLocation'
                  label={locationCheckLabel}
                  value={"true"}
                />
              </div>
              <div className={css.row}>
                <label><FormattedMessage id="OpenAIJobDescriptionForm.contractTypeLabel" /></label>
                <FieldRadioButton
                className={css.radioSelect}
                  id="fulltime"
                  name="contractType"
                  label="Employment"
                  value={FULLTIME_KEY}
                />
                <FieldRadioButton
                className={css.radioSelect}
                  id="secondment"
                  name="contractType"
                  label="Secondment"
                  value={SECONDMENT_KEY}
                />
              </div>
              <div className={css.row}>
                <label>
                  <FormattedMessage id="OpenAIJobDescriptionForm.employementLabel" />
                  <span className={css.tooltip}>?</span>
                </label>
                <FieldTextInput
                  className={classNames(css.inputBox, isEmployementRequired && css.disabled)}
                  id='employmentLength'
                  name="employmentLength"
                  type="text"
                  placeholder="18 months"
                  disabled={isEmployementRequired}
                />
                <FieldCheckbox
                  className={css.checkbox}
                  id='employementRequired'
                  name='employementRequired'
                  label='N/A'
                  value={"true"}
                />
              </div>
              <div className={css.row}>
                <label><FormattedMessage id="OpenAIJobDescriptionForm.experienceLabel" /></label>
                <div className={css.experience}>
                  <FieldTextInput
                    className={classNames(css.budget, isExperienceRequired && css.disabled)}
                    id='minYear'
                    name="minYear"
                    type="number"
                    // label='years'
                    disabled={isExperienceRequired}
                  />
                  <span> - </span>
                  <FieldTextInput
                    className={classNames(css.budget, isExperienceRequired && css.disabled)}
                    id='maxYear'
                    name="maxYear"
                    type="number"
                    // label='years'
                    disabled={isExperienceRequired}
                  />
                  <span>years</span>
                </div>
                <FieldCheckbox
                  className={css.checkbox}
                  id='experienceRequired'
                  name='experienceRequired'
                  label={experienceCheckLabel}
                  value={"true"}
                />
              </div>
              <div className={css.row}>
                <label><FormattedMessage id="OpenAIJobDescriptionForm.budgetLabel" /></label>
                <div className={css.currencyWrapper}>
                  <FieldSelect
                    id='currency'
                    name='currency'
                    className={css.currencySelector}
                    defaultValue={currencyFeeTemp[0].key}
                    disabled={isBudgetRequired}
                  >
                    <option disabled></option>
                    {currencyFeeTemp.map(({ sym, key }) => (
                      <option key={key} value={key}>{sym}</option>
                    ))}
                  </FieldSelect>
                  <FieldTextInput
                    className={classNames(css.budgetInputBox, isBudgetRequired && css.disabled)}
                    type="number"
                    id="ctc"
                    name="ctc"
                    disabled={isBudgetRequired}
                  // validate={required(ctcRequiredMessage)}
                  />
                </div>
                <FieldCheckbox
                  className={css.checkbox}
                  id='budgetRequired'
                  name='budgetRequired'
                  label={ctcRequiredMessage}
                  value={"true"}
                />
              </div>

              <div className={css.bottomWrapper}>
                <SecondaryButton
                  type='button'
                  className={css.resetButton}
                  onClick={() => form.reset()}
                  disabled={openaiRequest}
                >
                  <FormattedMessage id="OpenAIContractForm.resetButtonText" />
                </SecondaryButton>
                <PrimaryButton
                  type='submit'
                  className={css.submitButton}
                  disabled={submitDisabled}
                  inProgress={openaiRequest}
                >
                  <FormattedMessage id="OpenAIContractForm.submitButtonText" />
                </PrimaryButton>
              </div>
            </div>
          </Form>
        );
      }}
    />
  )
}


OpenAIJobDescriptionForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  //   updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(OpenAIJobDescriptionForm);