import { Field, Form as FinalForm } from 'react-final-form';
import { Form, PrimaryButton, SecondaryButton, IconCard } from '../../components';
import css from './OpenAIReportIdeaGeneratorForm.module.css'
import { FormattedMessage } from 'react-intl';
import FileView from '../../components/FileView/FileView';
import { useEffect, useState } from 'react';
import { handleFileUpload } from '../../util/api';
import classNames from 'classnames';

const ACCEPT_FILE = 'application/pdf, .doc, .docx, .ppt, .pptx';
const SINGLE_FILE_SIZE_LIMIT = 25000000;

const OpenAIReportIdeaGeneratorForm = (props) => {

  const extractProposalText = async (file, form) => {
    const response = await handleFileUpload('get-parsed-document', {file});
    const text = response?.data;
    form.change('proposalDocument', file);
    form.change('proposalDocumentText', text);
  }

  const InputFileComponent = ({type, file, form, handleError, step}) => {
    return(
      <div>
        {file?.name ? (
          <span key={file.name}>
            <FileView file={file}>
              <span className={css.close} onClick={() => form.change(type, {})}>
                <IconCard brand='cross' />
              </span>
            </FileView>
          </span>
        ) : (
          <Field
            id="file"
            name="file"
            accept={step === 1 ? ACCEPT_FILE : '.xls, .xlsx'}
            type="file"
          >
            {fieldprops => {
              const { accept, input, disabled: fieldDisabled } = fieldprops;
              const { name, type } = input;
              const onChange = e => {
                const file = e.target.files[0]
  
                if (file.size > SINGLE_FILE_SIZE_LIMIT) {
                  handleError('File size can not be greater than 25mb!')
                  return
                }
                if(step === 1) extractProposalText(file, form)
                else form.change('transcription', file);
              };
  
              const inputProps = { accept, id: name, name, onChange, type };
              return (
                <>
                  {fieldDisabled ? null : (
                    <input {...inputProps} className={css.addImageInput} />
                  )}
                  <label type='button' htmlFor={name} className={css.attachmentLabel}>
                    <span className={css.dragDrop}><FormattedMessage id="ZohoSign.dragAndDropUpload" /></span>
                    <span className={css.docType}>
                      {step === 1 ? <FormattedMessage id="JobListing.documentAllowList" /> : 'XLS or XLSX' }
                    </span>
                  </label>
                </>
              );
            }}
          </Field>
        )}
      </div>
    )
  }

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          pristine,
          values,
          form,
          openaiRequest,
          isError,
        } = formRenderProps;

        const [step, setStep] = useState(1);
        const [error, setError] = useState('');
        const [toggle, setToggle] = useState(true);
        const submitDisabled = step === 1 
          ? toggle 
            ? !values?.proposalDocument : false 
          : !values?.transcription;

        function handleError(message) {
          setError(message)
          setTimeout(() => setError(false), 3000)
        }

        const handleNextStep = () => {
          if(step === 1){
            // form.change('proposalDocument', {});
            setStep(2)
          }
          else if(step === 2) handleSubmit()
        }

        function handleToggle() {
          if (toggle){
            setToggle(false)
            form.change('proposalDocumentText', null);
          }
          else setToggle(true)
        }

        return (
          <Form className={css.queryReportForm} onSubmit={() => {}}>
            <div className={css.container}>
              <div className={css.briefGenerator}>
                <span className={css.generatorTitle}>
                  <FormattedMessage id="OpenAIReportGeneratorForm.heading" />
                </span>
              </div>
              <div className={css.attachment}>
                <label>Step {step}</label>
                <div className={css.documentSwitcher}>
                  <label><FormattedMessage id={step === 1 ? "OpenAIReportGeneratorForm.uploadProposalLabel" : "OpenAIReportGeneratorForm.uploadContentLabel"} /></label>
                  {step === 1 && (
                    <div className={classNames(css.switcherWrapper, toggle && css.toggled)} onClick={handleToggle}>
                      <div className={css.ball}></div>
                    </div>
                  )}
                </div>
                {step !== 2 && !toggle ? null : (
                  <InputFileComponent 
                    type={step === 1 ? 'proposalDocument' : 'transcription'}
                    file={step === 1 ? values?.proposalDocument : values?.transcription} 
                    form={form} 
                    handleError={handleError} 
                    step={step}
                  />
                )}
              </div>
              {!!error && (
                <p className={css.error}>{error}</p>
              )}
              {isError}
              <div className={css.actionButtons}>
                <SecondaryButton
                  type='button'
                  className={css.resetButton}
                  onClick={() => form.reset()}
                  disabled={openaiRequest}
                >
                  <FormattedMessage id="OpenAIContractForm.resetButtonText" />
                </SecondaryButton>
                <PrimaryButton
                  type='button'
                  className={css.submitButton}
                  disabled={submitDisabled}
                  inProgress={openaiRequest}
                  onClick={handleNextStep}
                >
                  {step === 1 
                    ? 'Next' 
                    : <FormattedMessage id="OpenAIContractForm.submitButtonText" />
                  }
                </PrimaryButton>
              </div>
            </div>
          </Form>
        )
      }}
    />
  )
}

export default OpenAIReportIdeaGeneratorForm