import React, { useEffect, useState, forwardRef } from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
// These relative imports need to point to correct directories
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { Form, FieldTextInput, PrimaryButton, SecondaryButton, FieldSelect, IconCard, IconSpinner, FieldCheckbox } from '../../components';

import css from './OpenAIBriefGeneratorForm.module.css';
import { currencyFeeTemp } from '../../marketplace-custom-config';
import { handleFileUpload } from '../../util/api';
import FileView from '../../components/FileView/FileView';
import classNames from 'classnames';

const ACCEPT_FILE = '.doc,.docx,application/msword,application/pdf,.ppt,.pptx,.xls,.txt,.xlsx';
const FILE_SIZE_LIMIT = 25*1000*1000;

export const OpenAIBriefGeneratorForm = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          pristine,
          updated,
          formId,
          updateError,
          values,
          form,
          openaiRequest,
          initialText,
          isError,
        } = formRenderProps;

        const submitDisabled = !values.requirement;
        const [file, setFile] = useState();
        const [isLoading, setIsLoading] = useState(false);

        const timelineCheckboxLabel = intl.formatMessage({ id: 'OpenAIBriefGeneratorForm.timelineCheckbox'});
        const isTimelineRequired = !!values.isTimelineRequired?.length && values.isTimelineRequired[0] === 'true';

        const budgetCheckboxLabel = intl.formatMessage({ id: 'OpenAIBriefGeneratorForm.budgetCheckbox'});
        const isBudgetRequired = !!values.isBudgetRequired?.length && values.isBudgetRequired[0] === 'true';

        useEffect(() => {
          if (initialText) {
            form.reset();
            setFile(null);
          }
        }, [initialText])

        async function handleAttachment(file) {
          setIsLoading(true)
          try{
            const response = await handleFileUpload('get-parsed-document', {file})
    
            if(response?.data){
              setFile(file)
              if (response?.data) Object.assign(values, { attachmentText: response?.data })
              setIsLoading(false)
            }
          }
          catch(error){
            setIsLoading(false)
          }
        }

        return (
          <Form onSubmit={handleSubmit}>
            <div className={css.briefGeneratorForm}>
              <div className={css.container}>
                <div className={css.briefGenerator}>
                  <span className={css.generatorTitle}><FormattedMessage id='OpenAIBriefGeneratorForm.heading' /></span>
                </div>
                <div className={css.textareaBox}>
                  <label><FormattedMessage id='OpenAIBriefGeneratorForm.requirementLabel' /></label>
                  <FieldTextInput
                    className={css.inputBox}
                    type="textarea"
                    name="requirement"
                    autoComplete="given-name"
                  />
                </div>
                <div className={css.textareaBox}>
                  <label><FormattedMessage id='OpenAIBriefGeneratorForm.projectLabel' /></label>
                  <FieldTextInput
                    className={css.inputBox}
                    type="textarea"
                    name="specificDetails"
                    placeholder={'Specialized requests, project-specific deviations, etc...'}
                  />
                </div>
                <div className={css.attachmentRow}>
                  <label><FormattedMessage id="OpenAIBriefGeneratorForm.attachmentLabel" /></label>
                  <div className={css.attachment}>
                    {file ? (
                      <FileView file={file}>
                        <span className={css.close} onClick={() => setFile(null)}>
                          <IconCard brand='cross'/>
                        </span>
                      </FileView>
                    ) : (
                      <>
                        <Field
                          id="file"
                          name="file"
                          accept={ACCEPT_FILE}
                          type="file"
                        >
                          {fieldprops => {
                            const { accept, input, disabled: fieldDisabled } = fieldprops;
                            const { name, type } = input;
                            const onChange = e => {
                              const file = e.target.files[0];
                              if (file && file.name && file.size < FILE_SIZE_LIMIT) {
                                handleAttachment(file)
                                form.change("file",file)
                              }
                            };

                            const inputProps = { accept, id: name, name, onChange, type };
                            return <input {...inputProps} className={css.addImageInput} />
                          }}
                        </Field>
                        <label htmlFor="file" className={css.inputLabel}>
                          {isLoading ? <IconSpinner strokeColor='#0B96F5' /> : <IconCard brand="upload" />}
                          <span className={css.dragDrop}><FormattedMessage id="ZohoSign.dragAndDropUpload" /></span>
                          <span className={css.docType}>
                            <FormattedMessage id="JobListing.documentAllowList" />
                          </span>
                        </label>
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <div className={css.timelineRow}>
                    <label><FormattedMessage id='OpenAIBriefGeneratorForm.timelineLabel' />
                      <span className={css.tooltip}>?</span>
                    </label>
                    <FieldTextInput
                      className={classNames(css.inputBox, isTimelineRequired && css.disabled)}
                      type="text"
                      id='timeline'
                      name="timeline"
                      placeholder={'Example - 3 to 4 weeks"'}
                      disabled={isTimelineRequired}
                    />
                    <FieldCheckbox
                      className={css.checkbox} 
                      id='isTimelineRequired'
                      name='isTimelineRequired'
                      label={timelineCheckboxLabel} 
                      value={"true"} 
                    />
                  </div>
                    <label><FormattedMessage id='OpenAIBriefGeneratorForm.budgetLabel'/></label>
                  <div className={css.budgetBox}>
                    <FieldSelect
                      id={'currencyFee'}
                      name={'currencyFee'}
                      className={classNames(css.currencySelector, isBudgetRequired && css.disabled)}
                      disabled={isBudgetRequired}
                    >
                      <option disabled></option>
                      {currencyFeeTemp.map(({ sym, key }) => (
                        <option key={key} value={key}>
                          {sym}
                        </option>
                      ))}
                    </FieldSelect>
                    <FieldTextInput
                      className={classNames(css.budgetInputBox, isBudgetRequired && css.disabled)}
                      type="number"
                      id="budget"
                      name="budget"
                      placeholder={'10000...'}
                      disabled={isBudgetRequired}
                    />
                  </div>
                  <FieldCheckbox
                    className={css.checkbox} 
                    id='isBudgetRequired'
                    name='isBudgetRequired'
                    label={budgetCheckboxLabel} 
                    value={"true"} 
                  />
                </div>
                {isError}
                <div className={css.actionButtons}>
                  <SecondaryButton
                    type='button'
                    className={css.resetButton}
                    onClick={() => {
                      form.reset()
                      setFile(null)
                    }}
                    disabled={openaiRequest}
                  >
                    <FormattedMessage id='OpenAIContractForm.resetButtonText' />
                  </SecondaryButton>
                  <PrimaryButton
                    type='submit'
                    className={css.submitButton}
                    disabled={submitDisabled}
                    inProgress={openaiRequest}
                  >
                    <FormattedMessage id='OpenAIContractForm.submitButtonText' />
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  )
}


OpenAIBriefGeneratorForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  //   updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(OpenAIBriefGeneratorForm);