import { addMarketplaceEntities } from "../../ducks/marketplaceData.duck";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import { fetchCurrentUser } from "../../ducks/user.duck";
import { getFirmUserExperts, updateListingMetadata } from "../../util/api";
import { getFirmData } from "../../util/destructorHelpers";
import { storableError } from "../../util/errors";
import { types as sdkTypes } from '../../util/sdkLoader';
import { USER_ROLE_CLIENT } from "../../util/types";
import { showListing } from "../FirmPage/FirmPage.duck";
const { UUID } = sdkTypes;

export const RESULT_PAGE_SIZE = 5;
const resultIds = data => data.data.map(l => l.id);

//Action types

export const SHOW_FIRM_USER_EXPERT_REQUEST = 'app/FirmMemberExpertsPage/SHOW_FIRM_USER_EXPERT_REQUEST';
export const SHOW_FIRM_USER_EXPERT_SUCCESS = 'app/FirmMemberExpertsPage/SHOW_FIRM_USER_EXPERT_SUCCESS';
export const SHOW_FIRM_USER_EXPERT_ERROR = 'app/FirmMemberExpertsPage/SHOW_FIRM_USER_EXPERT_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/FirmMemberExpertsPage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/FirmMemberExpertsPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/FirmMemberExpertsPage/QUERY_LISTINGS_ERROR';



export const REMOVE_LISTING_IMAGE = 'app/FirmDashBoardPage/REMOVE_LISTING_IMAGE';
//InitialState
const initialState = {
  currentPageResultIds: [],
  showFirmUserExpertInProgress: false,
  showFirmUserExpertError: null,
  firmExperts: []
};


//reducer function
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {

    case SHOW_FIRM_USER_EXPERT_REQUEST:
      return {
        ...state,
        showFirmUserExpertInProgress: true,
        showFirmUserExpertError: null,
      };
    case SHOW_FIRM_USER_EXPERT_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        showFirmUserExpertInProgress: false,
        showFirmUserExpertError: null,
        firmExperts: payload
      };
    case SHOW_FIRM_USER_EXPERT_ERROR:
      return {
        ...state,
        showFirmUserExpertInProgress: false,
        showFirmUserExpertError: payload,
        firmExperts: []
      };

    default:
      return state;
  }
}


//Selectors

export const checkScrollingDisabled = state => isScrollingDisabled(state);

// ================ Action creators ================ //

export const showFirmUserExpertRequest = () => ({
  type: SHOW_FIRM_USER_EXPERT_REQUEST,
});
export const showFirmUserExpertSuccess = (response) => ({
  type: SHOW_FIRM_USER_EXPERT_SUCCESS,
  payload: {data:response.data},
});

export const showFirmUserExpertError = e => ({
  type: SHOW_FIRM_USER_EXPERT_ERROR,
  error: true,
  payload: e,
});


export const showFirmUserExpert = params => async (dispatch, getState, sdk) => {
  dispatch(showFirmUserExpertRequest());
  try {
    const response = await getFirmUserExperts({ userIds: params })
    dispatch(showFirmUserExpertSuccess(response))
    dispatch(addMarketplaceEntities(response))

  } catch (error) {
    dispatch(showFirmUserExpertError(storableError(e)));
  }
}

//LoadData
export const loadData = params => async (dispatch, getState, sdk) => {

  try {
    const listingId = new UUID(params.id);
    if (listingId) {

      const { data: { data = {} = {} } } = await dispatch(showListing(listingId))
      const firmData = data?.id && getFirmData(data);
      const userIds = firmData?.map((user) => user.userId);
      if (userIds.length > 0) {
        await dispatch(showFirmUserExpert(userIds))
      }
    }
    return Promise.all([dispatch(fetchCurrentUser())]);
  } catch (e) {
    return;
  }
};