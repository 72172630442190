import React, { useEffect, useRef, useState } from 'react'
import useDownloadContent from '../../hooks/useDownloadContent';
import { FormattedMessage } from 'react-intl';
import { IconSpinner, LoadingDots, Modal, PrimaryButton } from '../../components';
import { generatePdf, handleStopStreamResponse, handleThreadResponseFromOpenai } from '../../components/OpenAIAppsPanel/gigAppsHelperFunction';
import classNames from 'classnames';
import { deleteThreadMessages, fetchAnalyzeRawChatDetails, fetchQueryReportData } from '../../util/api';
import css from './OpenAIAnalyzeRawQuantitativeDataForm.module.css';
import moment from 'moment';
import { saveAs } from 'file-saver';
import FileView from '../../components/FileView/FileView';
import ContentLoader from 'react-content-loader';
import { handleThreadResponse } from './ThreadResponse';
import "./AnalyzeRawQuantitativeData.css"

export default function QueryAnalyzeRawQuantitativeData(props) {
  const { form, params, error, setError, appRoute, fileExtension, manageDisableScrolling, threadId, queryId } = props;

  const [fileDetails, setFileDetails] = useState([])
  const [question, setQuestion] = useState('');
  const [lastItem, setLastItem] = useState({});
  const [chatResponse, setChatResponse] = useState([]);
  const [showInput, setShowInput] = useState(!chatResponse?.length);
  const chatContainerRef = useRef(null);
  const viewerRef = useRef(null);
  const answerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fetch, downloadFetchContent, downloadPdf] = useDownloadContent();
  const [contentLoader, setContentLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false)

  const id = queryId ? queryId : params.id;
  const isTransaction = threadId ? true : false;

  async function fetchData() {
    try{
      setContentLoader(true)
      // Fetch files from MongoDB
      const queryData = await fetchQueryReportData({ id, isTransaction })
      
      const fileName = queryData?.data?.data?.xlsxFile?.name
      const fileExtension = fileName.split(".")[fileName.split(".").length - 1]
      form.change('fileExtension', fileExtension)
      let messages = queryData?.data?.data?.queryData || []
      if (messages.length > 0) {
        let firstQuestion = messages?.[0]?.question
        let toRemove = `The files are provided in the .${fileExtension} format. Read the sheets accordingly. `;
        firstQuestion = firstQuestion.replace(toRemove, "")
        messages.splice(0, 1, { question: firstQuestion, answer: messages?.[0]?.answer })
      }
      let filteredMessages = messages.map(item=>{
        return {
          question: item?.question,
          answer: item?.answer?.replace(/!\[.*?\]\(.*?\)/g, '')
        }
      })
      setChatResponse(filteredMessages);
      setContentLoader(false)
      let fileDetails = []
      fileDetails.push({filename: queryData.data.data.openAIfile.name, bytes: queryData.data.data.openAIfile.size, created_at: moment(queryData.data.createdAt).unix()})
      fileDetails.push({filename: queryData.data.data.xlsxFile.name, bytes: queryData.data.data.xlsxFile.size, created_at: moment(queryData.data.createdAt).unix()})
      setFileDetails(fileDetails)
    }catch(error){
      console.log(error)
    }
  }

  const Loader = ({ type, id }) => {
    if (fetch?.type === type && !!fetch?.isLoading) {
      return <IconSpinner strokeColor='orange' />
    }
    else {
      return <FormattedMessage id={id} />
    }
  }

  async function handleDownloadContent(type) {
    downloadPdf(fileDetails,chatResponse,lastItem)
  }

  async function handleDocDownload(type) {
    const filesNameMarkdown = `**Date:** ${moment().format('LLL')}\n\n**Uploaded Files:**\n\n- ${fileDetails[0].filename}\n\n- ${fileDetails[1].filename}\n\n`;

    const content = chatResponse.map(({ question, answer }) => (
      `___You___\n\n${question}\n\n___Response___\n\n${answer}`
    )).join('\n\n___\n\n');

    const result = filesNameMarkdown + content + (lastItem.question ? `\n\n___\n\n___You___\n\n${lastItem.question}\n\n___Response___\n\n${lastItem.answer}` : '');
    downloadFetchContent(type, result)
  }

  function handleError(message) {
    setError(message)
    setTimeout(() => setError(false), 3000)
  }

  const handleQueryQuestion = () => {
    if (isLoading) return;
    try {
      if(lastItem.question){
        setChatResponse([...chatResponse,lastItem])
      }
      setIsLoading(true);
      setQuestion('');

      const input = (chatResponse.length === 0 && !lastItem.question)
        ? (`The files are provided in the .${fileExtension} format. Read the sheets accordingly. ` + question)
        : question;

      setLastItem({ question: question,  answer: `<div class=${css.loader}></div>`});
      setShowInput(false);
  
      handleThreadResponse({
        threadId: threadId ? threadId : params.type,
        question: input,
        setIsLoading,
        handleError,
        type: appRoute,
        setLastItem,
        displayQuestion: question,
        id,
        isTransaction
      });
    } catch (err) {
      // console.log(err, 'error');
      handleError(err);
    }
  };

  useEffect(() => {
    try {
      const script = document.createElement('script');
      script.src = 'https://uicdn.toast.com/editor/latest/toastui-editor-viewer.min.js';
      script.async = true;
      script.addEventListener('load', () => {
        // Script has loaded, now you can use toastui
        viewerRef.current = new toastui.Editor({
          el: document.querySelector("#viewer"),
          height: "auto",
          viewer: true,
        });
        answerRef.current = new toastui.Editor({
          el: document.querySelector("#answer"),
          height: "auto",
          viewer: true,
        });
      });
      document.body.appendChild(script);

      // Fetch files data on load
      fetchData();

      return () => {
        // Cleanup: Remove the script when the component unmounts
        document.body.removeChild(script);
      };
    } catch (error) {
      // console.log(error);
    }
  }, []);

  useEffect(() => {
    try {
      const content = chatResponse.map(({ question, answer }) => {
        const sanitizedAnswer = answer.replace(/!\[.*?\]\(.*?\)/g, '');
        return `___You___\n${question}\n\n___Response___\n${sanitizedAnswer}`
      }).join('\n___\n');
      if (viewerRef.current) {
        viewerRef.current.setMarkdown(content, false);
      }
      //Scroll the chat container to bottom
      const lastItemIndex = chatResponse?.length - 1;
      const data = chatResponse[lastItemIndex].answer;
      if (chatContainerRef.current && ((data?.length > 0) || data.includes('<div'))) {
        chatContainerRef.current.scrollTo({
          top: chatContainerRef.current.scrollHeight,
          behavior: 'smooth'
        });
      }
    } catch (e) {
      // console.log(e)
    }
  }, [chatResponse])
  
  useEffect(() => {
    try {
      if(lastItem.question){
        const sanitizedAnswer = lastItem.answer.replace(/!\[.*?\]\(.*?\)/g, '');
        const content = `${chatResponse.length>0 ? '\n___\n' : ''}___You___\n${lastItem.question}\n\n___Response___\n${sanitizedAnswer}`
        answerRef.current.setMarkdown(content, false);
        if (lastItem?.answer?.length % 10 === 0 || lastItem?.answer.includes('<div')) {
          chatContainerRef.current.scrollTo({
            top: chatContainerRef.current.scrollHeight,
            behavior: 'smooth'
          });
        }
      }else{
        answerRef.current.setMarkdown("", false);
      }
    } catch (e) {
      // console.log(e);
    }
  }, [lastItem]);
    
  //Handle and show Conversation pills transition
  const stopStream = () => {
    handleStopStreamResponse(params.type)
    setIsLoading(false)
  }

  const deleteMessages = async(e) => {
    e.preventDefault()
    setDeleteModal('loading')
    const response = await deleteThreadMessages({threadId: threadId ? threadId : params.type, id, isTransaction })
    if(response.data){
      setDeleteModal(false)
      setChatResponse([])
      setLastItem({})
      setShowInput(true)
    }
  }

  return (
    <div className={css.queryContainer}>
      <div className={css.briefGenerator}>
        <span className={css.generatorTitle}>
          <FormattedMessage id={"OpenAIAnalyzeRawDataForm.heading"} />
        </span>
      </div>
      <div className={css.files}>
        <label><FormattedMessage id='OpenAIQueryReportPage.uploadedFilesLabel' /></label>
        {fileDetails.length > 0 ? (
          fileDetails.map((file, index) => (
            <React.Fragment key={index}>
              <FileView file={{ name: file?.filename, link: '', size: file.bytes, createdAt: file.created_at }} className={css.fileView} />
            </React.Fragment>
          ))
        ) : (
          <ContentLoader
            speed={2}
            width={400}
            height={100}
            viewBox="0 0 400 70"
            backgroundColor="#ddd"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="0" y="8" rx="3" ry="3" width="300" height="10" />
            <rect x="0" y="26" rx="3" ry="3" width="300" height="10" />
            <rect x="0" y="44" rx="3" ry="3" width="300" height="10" />
          </ContentLoader>
        )}
      </div>
      {(!!chatResponse?.length || (lastItem.answer)) && !isLoading && (
        <div className={css.exportButtons}>
          <button type='button' onClick={(e) => handleDocDownload('docx')}>
            <Loader type='docx' id="TextEditor.exportDocButton" />
          </button>
          <button type='button' onClick={(e) => handleDownloadContent('pdf')}>
            <Loader type='pdf' id="TextEditor.exportPdfButton" />
          </button>
          <button onClick={(e) => {
            e.preventDefault()
            setDeleteModal(true)
          }}>
            Delete messages
          </button>
        </div>
      )}
      <div className={css.container} ref={chatContainerRef}>
        <div className={classNames(css.fontStyling, !chatResponse?.length && css.chat)}>
          {contentLoader && <div className={css.contentLoader}><div className={css.loader} style={{height: "100%"}}></div></div>}
          <div ref={viewerRef} id="viewer"></div>
          <div ref={answerRef} id="answer"></div>
        </div>
      </div>
      {isLoading && (
        <div className={css.stopButton} onClick={stopStream}>
          <div><span></span></div>
        </div>
      )}
      {!showInput ? (chatResponse.length>0 || lastItem.answer) && (
        <div>
          {!isLoading && (
            <div className={css.chatButton}>
              <button
                type='button'
                className={css.clearButton}
                onClick={() => {
                  stopStream()
                  setChatResponse([])
                  setShowInput(true)
                }}
              >
                Clear
              </button>
              <PrimaryButton
                type='button'
                className={css.askQuestionButton}
                onClick={() => {
                  stopStream()
                  setShowInput(true)
                }}
                disabled={isLoading || showInput}
              >
                Chat with Assistant
              </PrimaryButton>
            </div>
          )}
        </div>
      ) : (
        <>
          {error && <p className={css.error}><FormattedMessage id='OpenAiAppsPanel.errorMessage' /></p>}
          <div className={css.questionInputWrapper}>
            <label><FormattedMessage id='OpenAIQueryReportPage.questionLabel' /></label>
            <div className={css.questionBar}>
              <textarea
                className={classNames(css.textarea, isLoading && css.disableInput)}
                id='question'
                name='question'
                disabled={isLoading}
                value={question}
                onChange={e => {
                  const textarea = document.getElementById('question');

                  textarea.addEventListener('input', function() {
                    if(question === '') 
                    this.style.minHeight = 'unset';
                    this.style.height = '100px'; 
                    if(this.scrollHeight<200){
                      this.style.overflowY = 'auto';
                      this.style.minHeight = this.scrollHeight + 'px';
                    }else{
                      this.style.minHeight = '200px';
                      this.style.overflowY = 'scroll';
                    }
                  });
                  setQuestion(e.target.value)
                }}
                onKeyPress={e => e.key === 'Enter' && handleQueryQuestion()}
                row={1}
              ></textarea>
              <PrimaryButton
                type='button'
                className={(css.submitButton,css.queryButtn)}
                disabled={!question || contentLoader}
                onClick={() => handleQueryQuestion()}
              >
                {!isLoading
                  ? <FormattedMessage id="OpenAIContractForm.submitButtonText" />
                  : <LoadingDots className={css.loadingDots} />
                }
              </PrimaryButton>
            </div>
            <div className={css.chatButton}>
            </div>
          </div>
        </>
      )}
      <Modal
        id={`analyseRawQuantitativeDataDeleteModal`}
        isOpen={deleteModal}
        onClose={() => {
          setDeleteModal(false)
        }}
        onManageDisableScrolling={manageDisableScrolling}
        removeCloseButton={true}
      >
        <div className={css.alertModal}>
          <h2>
            <FormattedMessage id="OpenAiAppsPanel.deleteMessages" />
          </h2>
          <div className={css.buttonWrapper}>
            <div className={css.cancelButton} onClick={(e) => {
              e.preventDefault()
              deleteModal!=='loading' && setDeleteModal(false)
            }}>
              Cancel
            </div>
            <PrimaryButton
            type='button'
            className={css.submitButton}
            onClick={deleteMessages}
            >
              {deleteModal==='loading' ? 
                <IconSpinner strokeColor='white' />
              :
                <FormattedMessage id='EditListingWorkExperienceFormComponent.deleteSchedule' />
              }
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    </div>
  )
}