import React, { Component, useEffect, useState } from 'react';
import { compose } from 'redux';
import { Form as FinalForm, Field, FormSpy } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import isEqual from 'lodash/isEqual';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import classNames from 'classnames';
import {
  autocompletePlaceSelected,
  autocompleteSearchRequired,
  composeValidators,
  required,
} from '../../util/validators';
import * as validators from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
  Button,
  Form,
  ValidationError,
  FieldTextInput,
  RangeSlider,
  FieldSelect,
  LocationAutocompleteInputField,
  FieldRadioButton,
  IconCard,
} from '../../components';

import css from './EditListingAdditionalForm.module.css';
import { currencyFeeTemp } from '../../marketplace-custom-config';
import moment from 'moment';
import { MAX_BRIEF_LIMIT } from '../../util/types';
import { withRouter } from 'react-router-dom';
import { checkIfUserSubscribed } from '../../util/destructorHelpers';
import pdfimage from '../../assets/pdfimage.png';
import ppticon from '../../assets/ppticon.png';
import docicon from '../../assets/docImage.png';
import exlicon from '../../assets/exlImage.png';
import axios from 'axios';
import { apiBaseUrl, removeFileFromAws } from '../../util/api';
const identity = v => v;
const MIN = 0;
const MAX_USD = {
  value: 50000,
  name: 'USD',
  placeholder: '50,000',
};
const MAX_INR = {
  value: 2000000,
  name: 'INR',
  placeholder: '2,000,000',
};

const parseMin = (min, currentMax) => value => {
  const parsedValue = Number.parseInt(value, 10);
  if (isNaN(parsedValue)) {
    return '';
  }
  return parsedValue < min ? min : parsedValue > currentMax ? currentMax : parsedValue;
};

// Helper function to parse value for max handle
// Value needs to be between slider's max value and current minimum value
const parseMax = (max, currentMin) => value => {
  const parsedValue = Number.parseInt(value, 10);
  if (isNaN(parsedValue)) {
    return '';
  }
  return parsedValue > max ? max : parsedValue;
  // < currentMin ? currentMin : parsedValue > max ? max : parsedValue;
};

const ACCEPT_FILE = 'application/pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.xml,.odp';
export class EditListingAdditionalFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadAttachmentToAwsRequested: false,
      attachmentDeleteRequested: false,
      uploadedAttachmentsUrls: [],
      stopLoop: false,
      files: ['nice.pdf', 'verycool.jpg'],
      imageUploadRequested: false,
    };
    this.submittedImages = [];
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        imageUploadRequested={this.state.imageUploadRequested}
        render={formRenderProps => {
          const {
            form,
            className,
            fetchErrors,
            handleSubmit,
            images,
            intl,
            invalid,
            disabled,
            form: {
              mutators: { push, pop },
            },
            ready,
            updated,
            updateInProgress,
            updateInProgressPrivate,
            values,
            uploadError,
            uploadInProgress,
            makePublicText,
            makePrivateText,
            publicData,
            pristine,
            listingType,
            isFreePlanUsed,
            location,
            currentUser,
            linkedToFirms,
            isCsm,
            isPublished,
          } = formRenderProps;

          const isUserSubscribed = checkIfUserSubscribed(currentUser);
          const briefsAndJobCount = currentUser?.id && currentUser?.subscriptionData?.briefsAndJobCount;
          const shouldEnable = (isUserSubscribed || linkedToFirms || (isCsm && !linkedToFirms)) ?? false;
          const currentPath = location && location.pathname;
          const hasCurrentMonthLimitReached = isUserSubscribed && briefsAndJobCount >= MAX_BRIEF_LIMIT;

          useEffect(() => {
            if (values.isLocationSelected === 'notSelected') {
              form.change('locations', null);
            }
          }, [values.isLocationSelected]);

          const { publishListingError, showListingsError, updateListingError, uploadImageError } =
            fetchErrors || {};
          const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

          let uploadImageFailed = null;

          if (uploadOverLimit) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingAdditionalForm.imageUploadFailed.uploadOverLimit" />
              </p>
            );
          } else if (uploadImageError) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingAdditionalForm.imageUploadFailed.uploadFailed" />
              </p>
            );
          }

          // NOTE: These error messages are here since Photos panel is the last visible panel
          // before creating a new listing. If that order is changed, these should be changed too.
          // Create and show listing errors are shown above submit button
          const publishListingFailed = publishListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingAdditionalForm.publishListingFailed" />
            </p>
          ) : null;
          const showListingFailed = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingAdditionalForm.showListingFailed" />
            </p>
          ) : null;

          const documentsLabel = intl.formatMessage({
            id: 'EditListingAdditionalForm.documentsLabel',
          });
          const documentsPlaceholder = intl.formatMessage({
            id: 'EditListingAdditionalForm.documentsPlaceholder',
          });

          const projectTimelinesLabel = intl.formatMessage({
            id: 'EditListingAdditionalForm.projectTimelinesLabel',
          });
          const projectTimelinesPlaceholder = intl.formatMessage({
            id: 'EditListingAdditionalForm.projectTimelinesPlaceholder',
          });

          const budgetRangeLabel = intl.formatMessage({
            id: 'EditListingAdditionalForm.budgetRangeLabel',
          });

          const proposalsFromLabel = intl.formatMessage({
            id: 'EditListingAdditionalForm.proposalsFromLabel',
          });

          const countries = [
            { key: 'northAmerica', label: 'North America' },
            { key: 'india', label: 'India' },
          ];

          const minRequiredMessage = intl.formatMessage({
            id: 'EditListingAdditionalForm.minRequiredMessage',
          });
          const maxRequiredMessage = intl.formatMessage({
            id: 'EditListingAdditionalForm.maxRequiredMessage',
          });

          const timerangeRequiredMessage = intl.formatMessage({
            id: 'EditListingAdditionalForm.timerangeRequiredMessage',
          });
          const locationPlaceholder = intl.formatMessage({
            id: 'EditListingAdditionalForm.locationPlaceHolder',
          });
          // const addressPlaceholderMessage = intl.formatMessage({
          //   id: 'EditListingLocationForm.addressPlaceholder',
          // });
          const addressRequiredMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressRequired',
          });
          // const addressNotRecognizedMessage = intl.formatMessage({
          //   id: 'EditListingLocationForm.addressNotRecognized',
          // });
          const locationRequiredMessage = validators.required(
            intl.formatMessage({ id: 'EditListingAdditionalForm.locationRequiredMessage' })
          );
          const titleRequiredMessage = intl.formatMessage({
            id: 'EditListingAdditionalForm.titleRequiredMessage',
          });

          const exceedingPlanLimitErrorMessage = intl.formatMessage({
            id: 'EditListingAdditionalForm.exceedingPlanLimitErrorMessage'
          })

          const isOfflineSubscriptionPaidErrorMessage = intl.formatMessage({
            id: 'EditListingAdditionalForm.isOfflineSubscriptionPaid'
          })
          const removeLocationFieldButtonText = intl.formatMessage({
            id: 'EditListingAdditionalForm.removeLocationFieldButtonText'
          })

          const submittedOnce = this.submittedImages.length > 0;
          // imgs can contain added images (with temp ids) and submitted images with uniq ids.
          const arrayOfImgIds = imgs =>
            imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
          const imageIdsFromProps = arrayOfImgIds(images);
          const imageIdsFromPreviousSubmit = arrayOfImgIds(this.submittedImages);
          const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
          const pristineSinceLastSubmit = submittedOnce && imageArrayHasSameImages;

          const submitReady = (updated && pristineSinceLastSubmit) || ready;
          const submitInProgress = updateInProgress;

          const classes = classNames(css.root, className);

          const [maxValueBudget, setMaxValueBudget] = useState(MAX_USD.value);
          const [maxPlaceholderBudget, setMaxPlaceholderBudget] = useState(MAX_USD.placeholder);
          const [currentFeeBudget, setCurrentFeeBudget] = useState(
            values.currencyFee || MAX_USD.name
          );

          const { minPrice: minPriceRaw, maxPrice: maxPriceRaw } = values;
          const minPrice = minPriceRaw ? minPriceRaw : MIN;
          const maxPrice = maxPriceRaw !== undefined && maxPriceRaw ? maxPriceRaw : maxValueBudget;

          const submitDisabled = invalid || uploadInProgress || maxPrice <= 0 || pristine || !isUserSubscribed;
          const handleMaxPrice = e => {
            if (values.maxPrice < values.minPrice) {
              form.change('maxPrice', values.minPrice);
            }
          };

          const handleChangeMaxPrice = ({ values, modified }) => {
            const { currencyFee } = values;

            if (currencyFee && currencyFee === MAX_INR.name) {
              setMaxValueBudget(MAX_INR.value);
              setMaxPlaceholderBudget(MAX_INR.placeholder);
            }
            if (currencyFee && currencyFee === MAX_USD.name) {
              setMaxValueBudget(MAX_USD.value);
              setMaxPlaceholderBudget(MAX_USD.placeholder);
            }

            if (currencyFee && currencyFee !== currentFeeBudget) {
              setCurrentFeeBudget(currencyFee);
              if (modified && modified.currencyFee) {
                form.change('minPrice', null);
                form.change('maxPrice', null);
              }
            }
          };
          const onAttachmentUpload = async (file) => {
            // this.props.setClearForm(false);
            if (file && file.name) {
              const formData = new FormData();
              formData.append('file', file);
              const { data = {} } = await axios.post(`${apiBaseUrl()}/api/upload-file-aws`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });

              const updateduploadedAttachmentsUrls = [...this.state.uploadedAttachmentsUrls];


              const { location, key } = data;
              const currentDate = moment().format('MM-DD-YYYY hh:mm:ss');
              updateduploadedAttachmentsUrls.push({
                link: location,
                date: currentDate,
                name: file.name,
                size: file.size,
                key
              });
              this.setState({
                uploadedAttachmentsUrls: updateduploadedAttachmentsUrls,
                attachmentDeleteRequested: false,
                uploadAttachmentToAwsRequested: false,
              });
            }
          }
          const onRemoveFileLinks = async (index, item) => {
            if (index > -1) {
              await removeFileFromAws({ key: item.key })
              this.state.uploadedAttachmentsUrls.splice(index, 1);
              this.setState({ uploadedAttachmentsUrls: this.state.uploadedAttachmentsUrls });
              const { attachmentsUrls = [] } = publicData;

              const updateValues = {
                publicData: { attachmentsUrls: attachmentsUrls.splice(index, 1) },
              };
              return updateValues;
            }
          };

          if (this.state.uploadedAttachmentsUrls && this.state.uploadedAttachmentsUrls.length) {
            values['attachmentsUrls'] = this.state.uploadedAttachmentsUrls;
          } else if (this.state.attachmentDeleteRequested) {
            values['attachmentsUrls'] = this.state.uploadedAttachmentsUrls;
          } else if (values['attachmentsUrls'] && values['attachmentsUrls'].length) {
            this.setState({ uploadedAttachmentsUrls: values['attachmentsUrls'] });
          }
          const { uploadedAttachmentsUrls } = this.state;

          const fileType = uploadedAttachmentsUrls?.map((e) => e?.name?.split('.')[1]);

          const fileUploadLimitMessage = intl.formatMessage({
            id: 'AttachmentForm.uploadLimitMessage',
          });

          function formatFileSize(sizeInBytes) {
            const units = ['B', 'KB', 'MB', 'GB', 'TB'];
            const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
            const size = (sizeInBytes / Math.pow(1024, i)).toFixed(1);
            return `${size} ${units[i]}`;
          }

          function handleMoneyFormat(value, key) {
            const modifiedValue = value.toLocaleString("en-US")
            if (Number.isInteger(value)) form.change(key, modifiedValue)
            if (value === 0) form.change(key, '')
          }

          const renderArray = ({ fields }) => (
            <div className={css.inputLocation}>
              {fields.map(name => (
                <div className={css.locationBoxWraper}  key={`${name}.Location`}>
                  <LocationAutocompleteInputField
                    className={css.currencySelector}
                    inputClassName={css.locationAutocompleteInput}
                    iconClassName={css.locationAutocompleteInputIcon}
                    predictionsClassName={css.predictionsRoot}
                    validClassName={css.validLocation}
                    // autoFocus
                    name={`${name}.Location`}
                    label={titleRequiredMessage}
                    placeholder={locationPlaceholder}
                    useDefaultPredictions={false}
                    format={identity}
                    validate={composeValidators(
                      autocompleteSearchRequired(addressRequiredMessage)
                      // autocompletePlaceSelected(addressNotRecognizedMessage)
                    )}
                  />
                  <span className={css.deleteLink} onClick={() => pop('locations')}>{removeLocationFieldButtonText}</span>
                </div>
              ))}
            </div>
          );

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedImages = images;
                handleSubmit(e);
              }}
            >
              <FormSpy
                onChange={handleChangeMaxPrice}
                subscription={{ modified: true, values: true }}
              />
              {(!isPublished && hasCurrentMonthLimitReached) && (
                <div className={css.error}>{exceedingPlanLimitErrorMessage}</div>
              )}
              {!isCsm && !isUserSubscribed && !linkedToFirms && (
                <div className={css.error}>{isOfflineSubscriptionPaidErrorMessage}</div>
              )}
              {updateListingError || uploadError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingAdditionalForm.updateFailed" />
                </p>
              ) : null}
              <div className={css.imagesField}>
                <label className={css.imageLabel}>{documentsLabel}</label>
                {uploadedAttachmentsUrls.length >= 5 && (
                  <h3 className={css.fileUploadLimitMessage}>{fileUploadLimitMessage}</h3>
                )}
                {uploadedAttachmentsUrls && uploadedAttachmentsUrls.length >= 5 ? null : (
                  <Field
                    label={documentsPlaceholder}
                    id="fileName"
                    name="fileName"
                    accept={ACCEPT_FILE}
                    form={null}
                    type="file"
                  >
                    {fieldprops => {
                      const { accept, input, label, meta, disabled: fieldDisabled } = fieldprops;
                      const { name, type } = input;

                      const onChange = e => {
                        const file = e.target.files[0];

                        if (file && file.name && file.size < 2754021) {
                          this.setState({ uploadAttachmentToAwsRequested: true, stopLoop: false });
                          onAttachmentUpload(file);
                          e.target.value = null;
                        }
                      };

                      const inputProps = { accept, id: name, name, onChange, type };
                      return (
                        <div className={css.addImageWrapper}>
                          <div className={css.aspectRatioWrapper}>
                            {fieldDisabled ? null : (
                              <input {...inputProps} className={css.addImageInput} />
                            )}
                            <label htmlFor={name} className={css.addImage}>
                              {label}
                            </label>
                          </div>
                        </div>
                      );
                    }}
                  </Field>
                )}

                <Field
                  component={props => {
                    const { input, meta } = props;
                    return (
                      <div className={css.imageRequiredWrapper}>
                        <input {...input} />
                        <ValidationError fieldMeta={meta} />
                      </div>
                    );
                  }}
                  name="images"
                  type="hidden"
                // validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
                />
              </div>
              {uploadImageFailed}
              <ul className={css.uploadPdfList}>
                {uploadedAttachmentsUrls &&
                  uploadedAttachmentsUrls.map((item, index) => {

                    const fileTypeExt = item.name.split('.')[1];
                    if (item !== null) {
                      return <>
                        <li key={item.name} className={css.uploadPdfBox}>
                          <div className={css.pdfBox}>
                            <span className={css.pdfImage}>
                              {fileTypeExt === "pdf" ? (
                                <img src={pdfimage} />
                              ) : ['doc', 'docs', 'docx'].includes(fileTypeExt) ? (
                                <img src={docicon} />
                              ) : ['xls', 'xlsx'].includes(fileTypeExt) ? (
                                <img src={exlicon} />
                              ) : ['ppt', 'pptx'].includes(fileTypeExt) ? (
                                <img src={ppticon} />
                              ) : ['csv'].includes(fileTypeExt) ? (
                                <IconCard brand="csv" />
                              ) : ['zip'].includes(fileTypeExt) ? (
                                <IconCard brand="zip" />
                              ) : null}
                            </span>
                            <div className={css.pdfRight}>
                              <div className={css.pdfName}>{item && item.name}</div>
                              <div className={css.pdfDate}>
                                {moment().format("Do MMM YYYY")} at {moment().format("LT")}
                                <span className={css.fileSize}>
                                  {item?.size ? formatFileSize(item?.size) : null}
                                </span>
                              </div>
                            </div>
                          </div>
                          <span className={css.trashButton} onClick={() => onRemoveFileLinks(index, item)}>
                            Delete
                            {' '}
                          </span>
                        </li>
                      </>
                    }
                  }
                  )}
              </ul>
              <FieldTextInput
                id="projectTimelines"
                name="projectTimelines"
                className={css.projectTimelines}
                type="text"
                label={projectTimelinesLabel}
                placeholder={projectTimelinesPlaceholder}
                validate={required(timerangeRequiredMessage)}
              />

              <div className={css.contentWrapper}>
                <span className={css.label}>{budgetRangeLabel} </span>
                <div className={css.fieldWrapper}>
                  <FieldSelect
                    id="currencyFee"
                    name="currencyFee"
                    className={css.currencySelector}
                    defaultValue={currencyFeeTemp[0].key}
                  >
                    {currencyFeeTemp.map(({ symbol, key }) => (
                      <option key={key} value={key}>
                        {symbol} - {key}
                      </option>
                    ))}
                  </FieldSelect>
                  <div className={css.inputsWrapper}>
                    <Field
                      className={classNames(css.minPrice, {
                        [css.customerMaxValue]: maxPlaceholderBudget === MAX_INR.placeholder,
                      })}
                      id="minPrice"
                      name="minPrice"
                      component="input"
                      type="text"
                      placeholder={MIN}
                      min={MIN}
                      max={maxValueBudget}
                      step={1}
                      parse={parseMin(MIN, maxPrice)}
                      validate={required(minRequiredMessage)}
                      onChange={(e)=>{
                        const value = Number(e.target.value.replace(/\,/g, ''))
                        handleMoneyFormat(value<maxValueBudget ? value : maxValueBudget, 'minPrice')
                      }}
                    />
                    <span className={css.priceSeparator}>-</span>
                    <Field
                      onBlur={handleMaxPrice}
                      className={classNames(css.maxPrice, {
                        [css.customerMaxValue]: maxPlaceholderBudget === MAX_INR.placeholder,
                      })}
                      id="maxPrice"
                      name="maxPrice"
                      component="input"
                      type="text"
                      placeholder={maxPlaceholderBudget}
                      min={MIN}
                      max={maxValueBudget}
                      step={1}
                      parse={parseMax(maxValueBudget, minPrice)}
                      validate={required(maxRequiredMessage)}
                      onChange={(e)=>{
                        const value = Number(e.target.value.replace(/\,/g, ''))
                        handleMoneyFormat(value<maxValueBudget ? value : maxValueBudget, 'maxPrice')
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className={css.sliderWrapper}>
                <RangeSlider
                  min={MIN}
                  max={maxValueBudget}
                  step={1}
                  handles={[Number(`${minPrice}`.replace(/\,/g, '')), Number(`${maxPrice}`.replace(/\,/g, ''))]}
                  onChange={handles => {
                    handleMoneyFormat(handles[0], 'minPrice')
                    handleMoneyFormat(handles[1], 'maxPrice')
                  }}
                  trackColorBlue="#0B96F5"
                />
              </div>
              <div className={css.locationBox}>
                <p>
                  <FormattedMessage id="EditListingAdditionalForm.expertLocation" />
                </p>
                <FieldRadioButton
                  id="yes"
                  name="isLocationSelected"
                  label={'Yes'}
                  value="selected"
                  showAsRequired={locationRequiredMessage}
                />
                <FieldRadioButton
                  id="no"
                  name="isLocationSelected"
                  label={'No'}
                  value="notSelected"
                  showAsRequired={locationRequiredMessage}
                />
              </div>
              <div className={css.locationInputWrap}>
                <FieldArray name="locations">{renderArray}</FieldArray>
              </div>
              <div className={css.countryButtons}>
                <Button
                  className={css.addCountryButton}
                  type="button"
                  onClick={() => push('locations', undefined)}
                  disabled={
                    values.isLocationSelected === 'notSelected' || !values.isLocationSelected
                  }
                >
                  <FormattedMessage id="EditListingAdditionalForm.addLocation" />
                  <span className={css.icon}>
                    <IconCard brand="plus" />
                  </span>
                </Button>
                {/* <Button
                  className={css.removeCountryButton}
                  type="button"
                  onClick={() => pop('locations')}
                >
                  <FormattedMessage id="EditListingAdditionalForm.removeLocation" />
                  <span className={css.icon}> -</span>
                </Button> */}
              </div>

              {publishListingFailed}
              {showListingFailed}

              <div className={css.fieldButtons}>
                <div className={css.buttonBox}>
                  <Button
                    className={css.submitButton}
                    style={{ marginRight: 10 }}
                    type="button"
                    onClick={() => {
                      form.change('type', 'public');
                      handleSubmit(values);
                    }}
                    inProgress={submitInProgress}
                    disabled={submitDisabled || (!isPublished && hasCurrentMonthLimitReached) || !shouldEnable}
                    ready={submitReady}
                  >
                    {makePublicText}
                  </Button>
                  <span className={css.toolTipText}>
                    <FormattedMessage id="EditListingAdditionalForm.toolTipTextPublic" />
                  </span>
                </div>
                <div className={classNames(css.buttonBox, css.privateButton)}>
                  <Button
                    className={css.submitButton}
                    type="button"
                    onClick={() => {
                      form.change('type', 'private');
                      handleSubmit(values);
                    }}
                    inProgress={updateInProgressPrivate}
                    disabled={submitDisabled || (!isPublished && hasCurrentMonthLimitReached) || !shouldEnable}
                    ready={submitReady}
                  >
                    {makePrivateText}
                  </Button>
                  <span className={css.toolTipText}>
                    <FormattedMessage id="EditListingAdditionalForm.toolTipTextPrivate" />
                  </span>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingAdditionalFormComponent.defaultProps = { fetchErrors: null, images: [] };

export default compose(injectIntl, withRouter)(EditListingAdditionalFormComponent);
